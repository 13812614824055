import styled from "styled-components";

export const PopupWrap = styled.div`
  position: relative;
  top: auto;
  background: #320703;
  left: 0;
  padding: 0 15px;
  right: 0;
  border-radius: 18px;
  -webkit-border-radius: 18px;
  width: 314px;

  @media (min-width: ${2800}px) {
    width: 680px;
  }

  @media (min-width: ${1920}px) {
    padding: 0 32px;
  }
`;

export const Head = styled.div`
  text-align: center;
  font-size: 12px;
  font-feature-settings: "case" off;
  padding: 15px 20px 8px 15px;
  position: relative;
  min-height: 24px;

  // .popup-raund-head-ic {
  //   fill: #ffffff;
  //   width: 24px;
  //   height: 24px;
  //   margin-right: 4px;
  //   display: inline-block;
  //   vertical-align: middle;
  // }

  @media (min-width: ${1366}px) {
    font-size: 22px;
    line-height: 26px;
    padding: 31px 20px 30px 15px;

    .popup-raund-head-ic {
      display: none;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;s
  color: ${(props) => props.theme.colors.frogGreen};
`;

// export const CloseWrapper = styled.div`
//   position: absolute;
//   right: -4px;
//   top: 10px;
//   display: block;
//   padding: 10px;

//   .popup-close-ic {
//     width: 12px;
//     height: 12px;
//     fill: #ffffff;
//     vertical-align: top;
//     -moz-transition: all 0.3s;
//     -o-transition: all 0.3s;
//     -webkit-transition: all 0.3s;
//     transition: all 0.3s;

//     &:hover {
//       cursor: pointer;
//       fill: #ca0505;
//     }
//   }

//   @media (min-width: ${1366}px) {
//     display: none;
//     right: 10px;
//     top: 27px;

//     .popup-close-ic {
//       width: 24px;
//       height: 24px;
//     }
//   }

//   @media (min-width: ${1920}px) {
//     right: -6px;
//     top: 25px;

//     .popup-close-ic {
//       width: 24px;
//       height: 24px;
//     }
//   }
// `;

export const Content = styled.div`
  padding-top: 13px;
`;

export const Scroll = styled.div`
  padding-bottom: 15px;
  padding-right: 0;
  max-height: calc(88vh - 218px);
  overflow-y: auto;
  // overflow-x: hidden;

  @media (min-width: ${1366}px) {
    padding-right: 0;
    padding-bottom: 31px;
  }
`;

export const Item = styled.div`
  margin-top: 28px;
  text-align: start;

  &.first {
    margin: 0;
  }

  @media (min-width: ${1366}px) {
    margin-top: 46px;

    &.first {
      margin: 0;
    }
  }
`;

export const Label = styled.div`
  color: #ffffff;
  margin-bottom: 13px;
  display: block;
  font-size: 12px;

  @media (min-width: ${1366}px) {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 24px;
  }
`;

export const Code = styled.div`
  position: relative;
  padding-left: 30px;

  .lock {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .info-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .info-icon path {
    stroke: #52b05a;
    strokeWidth: 2px;
    strokeLinecap: round;
    strokeLinejoin: round;
  }

  @media (min-width: ${1366}px) {
    padding-left: 50px;
  }
`;

export const InfoText = styled.div`
  font-feature-settings: "case" off;
  font-size: 12px;
  line-height: 24px;
  background: transparent;
  border: 0;
  outline: 0;
  color: rgba(255, 255, 255, 0.3);
  width: 100%;

  @media (min-width: ${1366}px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  &.bordered {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 12px;
    margin-top: 12px;
  }
`;

export const RoundLab = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
  line-height: 12px;

  @media (min-width: ${1366}px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const Key = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.3);

  &.coeff {
    color: #52b05a;
  }

  @media (min-width: ${1366}px) {
    font-size: 16px;
    line-height: 19px;
  }
`;
