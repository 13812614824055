import React, { FC } from "react";
import ArrowRight from "../../../../components/Icons/ArrowRight";
import List from "../../../../components/Icons/List";
import Modal from "../../../../components/Modal";
import useTranslate from "../../../../config/language";
import { KEYWORDS } from "../../../../config/language/keywords";
import {
  Container,
  LinkTo,
  Link,
  MainTitle,
  ContentWrapper,
  Questions,
  Item,
  Title,
  Desc,
} from "./ProvablyFairModal.styles";
import InfoIcon from '../../../../assets/images/general/information-circle-contained.svg'

const ProvablyFairModal: FC<{ openData?: () => void; close: () => void }> = ({
  openData,
  close,
}) => {
  const translate = useTranslate();

  return (
    <Modal closeModal={close}>
      <Container>
        <MainTitle>
          <img src={InfoIcon} alt="drop" />
          PROVABLY FAIR
        </MainTitle>

        <LinkTo onClick={openData}>
          <Link>
            <List className="popup-link-ic" />
            {translate(KEYWORDS.ViewTable)}
            <ArrowRight className="popup-link-arrow" />
          </Link>
        </LinkTo>

        <ContentWrapper>
          <Questions>
            <Item className="questions-item-first">
              <Title>{translate(KEYWORDS.CheckFair)}</Title>
              <Desc>{translate(KEYWORDS.HowToCheckFair)}</Desc>
            </Item>

            <Item>
              <Title>{translate(KEYWORDS.GameKey)}</Title>
              <Desc>{translate(KEYWORDS.HowToCheckGameKey)}</Desc>
            </Item>

            <Item>
              <Title>{translate(KEYWORDS.Examples)}</Title>
              <Desc>{translate(KEYWORDS.ExampleForFair)}</Desc>
            </Item>

            <Item>
              <Title>{translate(KEYWORDS.CheckHash)}</Title>
              <Desc>{translate(KEYWORDS.HowToCheckHash)}</Desc>
            </Item>
          </Questions>
        </ContentWrapper>
      </Container>
    </Modal>
  );
};

export default ProvablyFairModal;
