import styled from "styled-components";

export const Container = styled.div<{ landscapeHeight?: number }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  position: absolute;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background: #0f1523;

  @media screen and (min-device-width: 320px) and (max-device-swidth: 992px) and (orientation: landscape) {
    height: 100vw;
    width: ${(props) => `${props.landscapeHeight}px`};
  }
`;

export const LoadingContainer = styled.div`
  z-index: 1;
`;

export const AppLogoContainer = styled.div`
  width: 50%;
  max-width: 404px;
  display: flex;
  max-height: 80px;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  transition: all 0.5s;
`;

export const AppLogo = styled.img`
  width: 100%;
  height: 100%;
  margin: auto;
  object-fit: contain;
  margin-top: -10vh;
`;

export const ProgressBarContainer = styled.div`
  background-color: #234036;
  border-radius: 18px;
  width: 80%;
  max-width: 488px;
  height: 16px;
  margin: 0px auto;
  overflow: hidden;
  transition: all 0.5s;
`;

export const ProgressBar = styled.div<{ width: number }>`
  background-color: #70ff00;
  width: ${({ width }) => width}%;
  height: 100%;
  border-radius: 18px;
  transition: all 1s;
`;
