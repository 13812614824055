import styled from "styled-components";

export const Container = styled.div``;

export const TableOverflow = styled.div`
  // // max-height: calc(88vh - 242px);
  // // overflow-y: auto;
  // // position: relative;
  // // padding: 0 8px;

  // // &::-webkit-scrollbar {
  // //   width: 2.79px;
  // //   height: 13px;
  // // }

  // // &::-webkit-scrollbar-thumb {
  // //   background-clip: content-box;
  // //   background-color: #3b3c41;

  // //   &:hover {
  // //     background-color: #3b3c41;
  // //   }
  // // }

  // .table-body {
  //   border-top: 1px solid rgba(255, 255, 255, 0.12);
  // }

  // .table-bets {
  //   font-size: 12px;
  //   line-height: 15px;
  //   width: 100%;
  //   border-spacing: 0;
  //   border: 0;
  //   border-collapse: collapse;
  //   text-align: center;
  //   margin-bottom: 34px;
  //   font-feature-settings: "case" off;
  // }

  // @media (min-width: ${2800}px) {
  //   // height: calc(100vh - 200px);
  //   // max-height: none;

  //   .table-bets {
  //     font-size: 8px;
  //     line-height: 10px;
  //   }
  // }

  // @media (min-width: ${1366}px) {
  //   // height: calc(100vh - 290px);
  //   padding: 0 10px;

  //   .table-bets {
  //     font-size: 14px;
  //     line-height: 16px;
  //   }
  // }

  // // @media (min-width: ${1920}px) {
  // //   height: calc(100vh - 296px);
  // // }
`;

export const TableHeaderCell = styled.th`
  // font-weight: normal;
  // padding: 10px 5px 3px;

  // &:nth-child(1) {
  //   text-align: left;
  // }

  // &:nth-child(3) {
  //   text-align: right;
  // }

  &.rating-date {
    width: 42%;
  }

  &.coeffc-grren {
    color: #52b05a;
    width: 20%;
  }

  &.rating-pf {
    width: 37%;
  }

  @media (min-width: ${2800}px) {
    &.rating-date {
      width: 80%;
    }

    &.coeffc-grren {
      width: 6%;
    }

    &.rating-pf {
      padding-right: 3%;
    }
  }

  // @media (min-width: ${1366}px) {
  //   padding: 10px 13px 11px;

    &.rating-date {
      width: 60%;
    }

    &.coeffc-grren {
      width: 25%;
    }

    &.rating-pf {
      width: 15%;
    }
  }
`;
