import styled from "styled-components";

export const Container = styled.div`
  background: #24233d;
  border-radius: 12px;
  box-shadow: none;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: ${2800}px) {
    background: #320703;
    border-radius: 18px;
    max-width: 956px;
  }
`;

export const MainTitle = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  gap: 12px;
  padding: 30px 12px;
  text-transform: uppercase;

  
  img {
    height: 38px;
    position: relative;
    top: 2px;
  }

  svg {
    height: 22px;
    width: 18px;
    margin-right: 10px;
  }

  @media (min-width: ${2800}px) {
    font-size: 24px;
    line-height: 28px;
    padding: 41px 0 20px;

    svg {
      height: 36px;
      width: 31px;
      margin-right: 21px;
    }
  }
`;

export const LinkTo = styled.div`
  text-align: center;
  margin-bottom: 19px;
  font-feature-settings: "case" on;

  .popup-link-ic {
    width: 16px;
    height: 16px;
    fill: #fff;
  }

  .popup-link-arrow {
    fill: #fff;
    width: 8px;
    height: 14px;
    right: 7px;
  }
`;

export const Link = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 6px;
  -webkit-border-radius: 6px;
  width: 192px;
  font-size: 16px;
  line-height: 18px;
  padding: 10px 17px 10px 16px;
  position: relative;
  display: inline-block;
  color: #ffffff;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;
    border: 1px solid #17ae43;
  }

  @media (min-width: ${1366}px) {
    width: 242px;
    font-size: 16px;
    line-height: 19px;
    padding-left: 35px;
  }
`;

export const ContentWrapper = styled.ol`
  overflow-y: auto;
  height: calc(100vh - 125px);
  padding-bottom: 30px;
  padding-right: 20px;

  &::-webkit-scrollbar {
    width: 2.79px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: #3b3c41;

    &:hover {
      background-color: #3b3c41;
    }
  }

  @media (min-width: ${1366}px) {
    max-height: calc(100vh - 353px);
  }

  @media (min-width: ${1920}px) {
    max-height: calc(100vh - 397px);
  }
`;

export const Questions = styled.ol`
  margin: 0 0 0 16px;
  font-feature-settings: "case" on;

  @media (min-width: ${1366}px) {
    margin: 0 14px 0 29px;
    font-feature-settings: "case" off;
    font-size: 20px;
  }

  @media (min-width: ${1920}px) {
    font-size: 24px;
  }
`;

export const Item = styled.li`
  margin-top: 26px;
  list-style-type: none;

  &.questions-item-first {
    margin-top: 0px;
  }

  @media (min-width: ${1366}px) {
    margin-top: 31px;
  }
`;

export const Title = styled.span`
  font-size: 20px;
  line-height: 24px;
  display: block;
  font-weight: 700;

  @media (min-width: ${1366}px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media (min-width: ${1920}px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const Desc = styled.span`
  display: block;
  font-size: 15px;
  line-height: 18px;
  color: #fff;
  letter-spacing: 0.005em;
  margin-top: 8px;

  @media (min-width: ${1366}px) {
    font-size: 15px;
    line-height: 25px;
    margin-top: 13px;
    // margin-left: -20px;
    letter-spacing: normal;
  }

  @media (min-width: ${1920}px) {
    font-size: 18px;
    line-height: 22px;
    margin-top: 13px;
    // margin-left: -25px;
    letter-spacing: normal;
  }
`;
