import React, { FC } from "react";
import { IconProps } from "./types";

export const Plus: FC<IconProps> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      height="24"
      width="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6 12H18"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 18L12 6"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Plus;
