import { useEffect, MutableRefObject } from "react";

type Event = MouseEvent | TouchEvent;

const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: MutableRefObject<T>,
  handler: (e: Event) => void
) => {
  useEffect(() => {
    const listener = (e: Event) => {
      const node = ref?.current;

      if (!node || node.contains((e?.target as Node) || null)) {
        return;
      }

      handler(e);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
