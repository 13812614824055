import React, { FC, useEffect, useState } from "react";
import ToggleInput from "../../../components/ToggleInput/ToggleInput";
import ArrowRight from "../../../components/Icons/ArrowRight";
import { useGameConfigs } from "../../../config/store/state/app.state";
import {
  useToggleConfigAnimationService,
  useToggleConfigBackgroundMusicService,
  useToggleConfigVoiceService,
} from "../configs/store/services";
import useTranslate from "../../../config/language";
import { KEYWORDS } from "../../../config/language/keywords";
import { useSaveSettingsService } from "../../../config/store/services";
import { useProfile } from "../configs/store/state";
import GameRulesModal from "./GameRulesModal/GameRulesModal";
import ProvablyFairModal from "./ProvablyFairModal/ProvablyFairModal";
import ProvablyFairDataModal from "./ProvablyFairTable/ProvablyFairDataModal";
import {
  StyledContainer,
  ConfigurationItem,
  Label,
  Actions,
  InvitationContainer,
} from "./GameConfigurations.styles";
import MenuIcon1 from "../../../assets/images/menu1.svg";
import MenuIcon2 from "../../../assets/images/menu2.svg";
import MenuIcon3 from "../../../assets/images/menu3.svg";
import MenuIcon4 from "../../../assets/images/menu4.svg";
import MenuIcon5 from "../../../assets/images/menu5.svg";
import { StyledButton } from "../../../components/Button/Button.styles";
import axios from "axios";
import { getQuery } from "../../../helpers/hooks/useQuery";
import { SESSION_KEY } from "../../../constants/constants";
import toast, { Toaster } from "react-hot-toast";

const GameConfigurations: FC = () => {
  const configs = useGameConfigs();
  const translate = useTranslate();
  const handleAnimate = useToggleConfigAnimationService();
  const handleBackgroundMusic = useToggleConfigBackgroundMusicService();
  const handleVoice = useToggleConfigVoiceService();
  const saveGameSettings = useSaveSettingsService();
  const profile = useProfile().Player;
  const [modalOpen, setModalOpen] = useState({
    ProvablyFair: false,
    ProvablyFairData: false,
    GameRules: false,
    BetHistory: false,
  });

  const [inviteEmail, setInvitieEmail] = useState("");

  // hide scroll while modal open
  useEffect(() => {
    const element = document.getElementById("root") as HTMLDivElement;
    const mainElement = element.children[0] as HTMLDivElement;

    if (Object.values(modalOpen).some((item) => item === true)) {
      mainElement.style.overflow = "hidden";
    } else {
      mainElement.style.overflow = "auto";
    }
  }, [modalOpen]);

  const handleChangeBackgroundMusic = (on: boolean) => {
    handleBackgroundMusic(on);
    saveGameSettings({
      Avatar: profile?.A || 0,
      Sound: configs.voiceEffects,
      Music: on,
      Animate: configs.animate,
    });
  };

  const handleChangeVoiceEffects = (on: boolean) => {
    handleVoice(on);
    saveGameSettings({
      Avatar: profile?.A || 0,
      Sound: on,
      Music: configs.backgroundMusic,
      Animate: configs.animate,
    });
  };

  const handleChangeAnimations = (on: boolean) => {
    handleAnimate(on);
    saveGameSettings({
      Avatar: profile?.A || 0,
      Sound: configs.voiceEffects,
      Music: configs.backgroundMusic,
      Animate: on,
    });
  };

  // const handleRedirectionToMain = () => {
  //   window.location.href = HOME_WEBSITE;
  // };

  // const isMobile = useIsMobile();

  const sendInvitation = () => {
    const sessionKey = getQuery(window.location.search, SESSION_KEY);
    if (inviteEmail) {
      axios.post('https://api-by.devcom.ge/api/user/invite', {
        token: sessionKey,
        email: inviteEmail
      }).then(() => {
        toast.success('Invitation sent');
        setInvitieEmail('')
      }).catch(() => {
        toast.success('Opps... Something happend');
      })
    }
  }

  return (
    <StyledContainer>
       <Toaster />
      {modalOpen.GameRules && (
        <GameRulesModal
          close={() =>
            setModalOpen((state) => ({ ...state, GameRules: false }))
          }
        />
      )}

      {modalOpen.ProvablyFair && (
        <ProvablyFairModal
          openData={() =>
            setModalOpen((state) => ({
              ...state,
              ProvablyFair: false,
              ProvablyFairData: true,
            }))
          }
          close={() =>
            setModalOpen((state) => ({
              ...state,
              ProvablyFair: false,
            }))
          }
        />
      )}

      {modalOpen.ProvablyFairData && (
        <ProvablyFairDataModal
          back={() =>
            setModalOpen(() => ({
              ProvablyFair: true,
              GameRules: false,
              ProvablyFairData: false,
              BetHistory: false,
            }))
          }
          close={() =>
            setModalOpen((state) => ({
              ...state,
              ProvablyFairData: false,
            }))
          }
        />
      )}

      <ConfigurationItem>
        <Label>
          <img src={MenuIcon1} alt="" /> {translate(KEYWORDS.Sound)}
        </Label>
        <Actions>
          <ToggleInput
            className="nav"
            active={configs.voiceEffects}
            onChange={() => handleChangeVoiceEffects(!configs.voiceEffects)}
          />
        </Actions>
      </ConfigurationItem>
      <ConfigurationItem>
        <Label>
          <img src={MenuIcon2} alt="" /> {translate(KEYWORDS.Music)}
        </Label>
        <Actions>
          <ToggleInput
            className="nav"
            active={configs.backgroundMusic}
            onChange={() =>
              handleChangeBackgroundMusic(!configs.backgroundMusic)
            }
          />
        </Actions>
      </ConfigurationItem>

      <ConfigurationItem>
        <Label>
          <img src={MenuIcon3} alt="" /> {translate(KEYWORDS.Animation)}
        </Label>
        <Actions>
          <ToggleInput
            className="nav"
            active={configs.animate}
            onChange={() => handleChangeAnimations(!configs.animate)}
          />
        </Actions>
      </ConfigurationItem>

      <ConfigurationItem
        onClick={() =>
          setModalOpen(() => ({
            ProvablyFair: false,
            ProvablyFairData: false,
            GameRules: true,
            BetHistory: false,
          }))
        }
        className="cursor"
      >
        <Label>
          <img src={MenuIcon5} alt="" />
          {translate(KEYWORDS.GameRules)}
        </Label>

        <ArrowRight />
      </ConfigurationItem>

      <ConfigurationItem
        onClick={() =>
          setModalOpen(() => ({
            ProvablyFair: true,
            ProvablyFairData: false,
            GameRules: false,
            BetHistory: false,
          }))
        }
        className="cursor"
      >
        <Label>
          <img src={MenuIcon4} alt="" />
          Provably Fair
        </Label>

        <ArrowRight />
      </ConfigurationItem>

      <ConfigurationItem className="cursor">
        <InvitationContainer>
          <input
            value={inviteEmail}
            type="email"
            onChange={(e) => setInvitieEmail(e.target.value)}
            placeholder="Friend E-mail"
          />
          <StyledButton onClick={() => sendInvitation()}>Send Invitation</StyledButton>
        </InvitationContainer>
      </ConfigurationItem>
    </StyledContainer>
  );
};

export default GameConfigurations;
