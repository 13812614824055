import React, { useEffect } from "react";
import { RegisterState, useBoards } from "../../config/store/state/app.state";
import { useBalance } from "../../features/profile/configs/store/state";
import { useToggleEnableFreeBetsService } from "../../config/store/services";
import Header from "./Header";

const HeaderSection = () => {
  const freeBetsBalance = useBalance().FreeSpinBalance || [];
  const freeSpinTotalCount = freeBetsBalance
    .map((freeSpin) => freeSpin.Count)
    .reduce((a, b) => a + b, 0);
  const isFreeBetsBalanceEmpty =
    freeBetsBalance.length < 1 || freeSpinTotalCount < 1;
  const handleToggleFreeBets = useToggleEnableFreeBetsService();

  const boards = useBoards();

  useEffect(() => {
    if (isFreeBetsBalanceEmpty) {
      const boardRegisterState = boards.filter(
        (board) => board.PanelIndex === 3 || board.PanelIndex === 4
      );

      if (
        boardRegisterState.every(
          (el) => el.registerState === RegisterState.UNREGISTERED
        )
      ) {
        handleToggleFreeBets(false);
      }
    }
  }, [boards, handleToggleFreeBets, isFreeBetsBalanceEmpty]);

  useEffect(() => {
    if (!isFreeBetsBalanceEmpty) {
      handleToggleFreeBets(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <Header />;
};

export default HeaderSection;
