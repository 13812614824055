import React, { FC } from "react";
import { IconProps } from "./types";

export const Back: FC<IconProps> = ({ className }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.5527 6.08337V10.5573C10.5527 10.6412 10.5693 10.7242 10.6014 10.8017C10.6336 10.8792 10.6807 10.9496 10.7401 11.0089L13.9361 14.2049L14.8393 13.3017L11.8306 10.293V6.08896L10.5527 6.08337Z"
        fill="#52b05a"
      />
      <path
        d="M11.7934 0.82097C9.5995 0.816561 7.47703 1.60059 5.81266 3.03022C4.1483 4.45985 3.0528 6.43993 2.72574 8.60973H0L3.27648 11.8547L6.55297 8.60973H4.05367C4.35141 6.84541 5.24678 5.23719 6.58955 4.0549C7.93231 2.87262 9.64078 2.18822 11.4283 2.11654C13.2158 2.04486 14.9735 2.59026 16.4066 3.66123C17.8397 4.7322 18.8609 6.26357 19.2989 7.99839C19.7369 9.73321 19.5651 11.5659 18.8121 13.189C18.0592 14.8121 16.7711 16.1268 15.1639 16.9126C13.5567 17.6983 11.7282 17.9073 9.9852 17.5044C8.24222 17.1015 6.6908 16.1112 5.59127 14.6998L4.55828 15.4981C5.50156 16.7358 6.74518 17.712 8.1713 18.3343C9.59741 18.9566 11.1588 19.2043 12.7074 19.0541C14.2561 18.9038 15.7407 18.3605 17.0207 17.4756C18.3006 16.5908 19.3334 15.3937 20.0213 13.9978C20.7091 12.6019 21.0292 11.0534 20.9512 9.49912C20.8731 7.94485 20.3996 6.43625 19.5754 5.11628C18.7512 3.79632 17.6037 2.70871 16.2416 1.95654C14.8795 1.20436 13.3479 0.812529 11.792 0.818175L11.7934 0.82097Z"
        fill="#52b05a"
      />
    </svg>
  );
};

export default Back;
