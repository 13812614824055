import { useCallback } from 'react';
import Axios, { AxiosResponse } from 'axios';

const GIPHY_TRENDING =
    'https://api.giphy.com/v1/gifs/trending?api_key=fOA24RSCtDRvTCeCVlrvNPiySMqBsVwn&limit=10';

const GIPHY_SEARCH =
    'https://api.giphy.com/v1/gifs/search?api_key=fOA24RSCtDRvTCeCVlrvNPiySMqBsVwn&rating=PG&limit=10&q=';

export function useGetTrendingGifs() {
    return useCallback(
        (): Promise<any> =>
            Axios({
                method: 'get',
                url: GIPHY_TRENDING,
            }).then((response: AxiosResponse) => {
                if (response.data.meta.status === 200) {
                    return response.data.data;
                }
            }),
        []
    );
}

export function useSearchGifs() {
    return useCallback(
        (query: string): Promise<any> =>
            Axios({
                method: 'get',
                url: GIPHY_SEARCH + query,
            }).then((response: AxiosResponse) => {
                if (response.data.meta.status === 200) {
                    return response.data.data;
                }
            }),
        []
    );
}
