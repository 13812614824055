import React, { useState } from "react";
import {
  AuthLayout,
  AuthSubmit,
  AuthTitle,
  RegistrationContainer,
} from "./styled";
import Button from "../../components/Button/Button";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const Login = () => {
  const [user, setUser] = useState({
    nickiname: "",
    password: "",
  });

  const setData = (key: keyof typeof user, value: string) => {
    const copyOfUser = { ...user };
    copyOfUser[key] = value;
    setUser(copyOfUser);
  };

  const login = () => {
    if (!user.nickiname || !user.password) {
      toast.error("Please fill all the fields");
      return false;
    }

    axios
      .post("https://api-by.devcom.ge/api/user/auth", {
        username: user.nickiname,
        password: user.password,
      })
      .then((res) => {
        console.log(res);
        // if (res.data) {
        //   window.location.href = `/?sessionKey=${res.data}`;
        // }
        toast.success("Successfully logged in");
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Incorect credentials");
      });
  };

  return (
    <AuthLayout>
      <AuthTitle>Login</AuthTitle>
      <Toaster />
      <RegistrationContainer>
        <div className="input">
          <label>Nickname</label>
          <input
            value={user.nickiname}
            onChange={(e) => setData("nickiname", e.target.value)}
            type="text"
            placeholder="Type your nickname"
          />
        </div>
        <div className="input">
          <label>Password</label>
          <input
            onChange={(e) => setData("password", e.target.value)}
            type="password"
            placeholder="Type your password"
          />
        </div>
      </RegistrationContainer>
      <AuthSubmit>
        <Button onClick={() => login()} text="Log in"></Button>
      </AuthSubmit>
    </AuthLayout>
  );
};

export default Login;
