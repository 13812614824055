import styled from "styled-components";

export const StyledCurrentList = styled.div``;

export const TableOverflow = styled.div`
  height: calc(100vh - 260px);
  overflow-y: auto;
  position: relative;
  flex: 1 1 0%;

  &::-webkit-scrollbar {
    width: 8px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-clip: content-box;
    background-color: #3b3c41;

    &:hover {
      background-color: #3b3c41;
    }
  }

  @media (min-width: ${1920}px) {
    height: calc(100vh - 269px);
  }
`;

export const TableWrap = styled.div`
  font-size: 12px;
  line-height: 15px;
  width: 100%;
  border-spacing: 0;
  border: 0;
  border-collapse: collapse;
  text-align: center;

  @media (min-width: ${2800}px) {
    font-size: 8px;
    line-height: 10px;
  }

  @media (min-width: ${1366}px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const TableHeaderCell = styled.div`
  border: 0;
  border-collapse: collapse;
  color: #ffffff;
  padding-bottom: 4px;
  font-weight: normal;
  font-feature-settings: "case" off;

  &.bets-user {
    width: 25%;
  }

  &.bets-bet {
    width: 25%;
  }

  &.bets-coeffc {
    width: 25%;
  }

  &.bets-profit {
    width: 25%;
  }

  // @media (min-width: ${2800}px) {
  //   padding-bottom: 5px;

  //   &:nth-child(1) {
  //     &:after {
  //       display: inline-block;
  //       content: ".";
  //       vertical-align: top;
  //     }
  //   }

  //   &.bets-user {
  //     width: 26%;
  //   }

  //   &.bets-bet {
  //     width: 22%;
  //   }

  //   &.bets-coeffc {
  //     width: 27%;
  //   }

  //   &.bets-profit {
  //     width: 20%;
  //   }
  // }

  // @media (min-width: ${1366}px) {
  //   &:nth-child(1) {
  //     &:after {
  //       display: none;
  //     }
  //   }

  //   &.bets-user {
  //     width: 33%;
  //   }

  //   &.bets-coeffc {
  //     width: 25%;
  //   }
  // }
`;

export const InnerCell = styled.span`
  display: inline-block;
  border-bottom: 1.4px solid rgba(255, 255, 255, 0.12);
  padding: 10px 5px 6px;
  width: 100%;

  &.first {
    padding-left: 0;
  }

  &.last {
    padding-right: 0;
  }

  @media (min-width: ${2800}px) {
    display: inline-block;
    padding: 0px 3px 0px;

    &.first {
      width: 23px;
      overflow: hidden;
    }
  }

  @media (min-width: ${1366}px) {
    display: block;
    padding: 0px 3px 12px;
    margin-bottom: 14px;

    &.first {
      width: auto;
      overflow: visible;
    }
  }
`;
