import React, { FC, memo, useState } from "react";
import { useProfile } from "./configs/store/state";
import useTranslate from "../../config/language";
import { KEYWORDS } from "../../config/language/keywords";
import { CURRENCY_SYMBOL } from "../../constants/constants";
import {
  Info,
  Text,
  Balance,
  AvatarContainer,
  IconHandler,
  IconContainer,
  Burger,
} from "./Profile.styles";
import ArrowUp from "../../components/Icons/ArrowUp";
import ArrowDown from "../../components/Icons/ArrowDown";
import AvatarsModal from "./components/AvatarsModal/AvatarsModal";
import UserAvatar from "../../components/UserAvatar";
import ClickAwayListener from "../../helpers/hooks/clickawayListener";
import ProfileDropdown from "./components/ProfileDropdown";
import SettingsIcon from '../../assets/images/Settings.svg'
import DashboardIcon from '../../assets/images/dashboard.svg'
import MessagesIcon from '../../assets/images/messages.svg'

const WebProfileSection: FC = () => {
  const profile = useProfile();
  const translate = useTranslate();
  const numberFormatter = Intl.NumberFormat("en-US");

  const [open, setOpen] = useState(false);
  const [changeAvatarOpen, setChangeAvatarOpen] = useState(false);

  const handleOpenChangeAvatarModal = () => {
    setChangeAvatarOpen(true);
  };

  return (
    <Info>
      <AvatarContainer onClick={handleOpenChangeAvatarModal}>
        <UserAvatar avatarId={profile?.Player?.A || 0} />
      </AvatarContainer>

      <Text>
        <Balance>{translate(KEYWORDS.Balance)}</Balance>

        {profile.B && profile.B
          ? numberFormatter
              .format(profile.B.WalletBalance)
              .toString()
              .replace(",", "‘")
          : 0}
        {CURRENCY_SYMBOL}
      </Text>

      <IconHandler
        onMouseDown={() => {
          setOpen(!open);
        }}
      >
        <IconContainer>{open ? <ArrowUp /> : <ArrowDown />}</IconContainer>
      </IconHandler>

      <Burger
        onMouseDown={() => {
          setOpen(!open);
        }}
        className={open ? "active" : ""}
      >
        <img src={DashboardIcon} alt=""/>
      </Burger>

      <Burger
        onMouseDown={() => {
          setOpen(!open);
        }}
        className={open ? "active" : ""}
      >
        <img src={MessagesIcon} alt=""/>
      </Burger>

      <Burger
        onMouseDown={() => {
          setOpen(!open);
        }}
        className={open ? "active" : ""}
      >
        <img src={SettingsIcon} alt=""/>
      </Burger>

      {open && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <ProfileDropdown
            profile={profile}
            handleClose={() => setOpen(false)}
          />
        </ClickAwayListener>
      )}

      {changeAvatarOpen && (
        <AvatarsModal closeModal={() => setChangeAvatarOpen(false)} />
      )}
    </Info>
  );
};

export default memo(WebProfileSection);
