import styled from "styled-components";

export const Info = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const Text = styled.div`
  display: none;
  font-size: 10px;
  line-height: 12px;
  color: #52b05a;
  margin-left: 12px;

  @media (min-width: ${2800}px) {
    display: block;
    font-size: 16px;
    line-height: 19px;
  }
`;

export const Balance = styled.div`
  color: #fff;
  font-feature-settings: "case" on;
`;

export const AvatarContainer = styled.div`
  display: none;
  cursor: pointer;
  background-color: #212235;
  border-radius: 50%;
  padding: 5px;

  @media (min-width: ${2800}px) {
    display: flex;
  }
`;

export const IconHandler = styled.div`
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 20px;
  margin-left: 8px;

  @media (min-width: ${2800}px) {
    display: flex;
  }
`;

export const IconContainer = styled.div`
  width: 11px;
  height: 100%;
  color: ${(props) => props.theme.colors.white};
`;

export const Burger = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 8px;

  @media (min-width: ${2800}px) {
    display: none;
  }
`;
