import React, { FC } from "react";
import Button from "../../../../../components/Button/Button";
import styled from "styled-components";
import useTranslate from "../../../../../config/language";
import { KEYWORDS } from "../../../../../config/language/keywords";
import { roundNumber } from "../../../../../helpers/functions/round-number";

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  @media (max-height: ${550}px), (max-width: ${600}px) {
    font-size: 13px;
  }
`;

const UnregisterPanel: FC<{
  amount: number;
  handleUnregister(): void;
  disabled?: boolean;
}> = ({ amount, handleUnregister, disabled }) => {
  const translate = useTranslate();

  return (
    <StyledContainer>
      <StyledButton
        text={`${translate(KEYWORDS.Unregister)} (${roundNumber(
          amount
        )})`}
        className="red"
        onClick={handleUnregister}
        disabled={disabled}
      />
    </StyledContainer>
  );
};

export default UnregisterPanel;
