/* eslint-disable react-hooks/exhaustive-deps */
import styled from "styled-components";
import React, { FC, memo } from "react";
import { motion } from "framer-motion";
import StaticRockit from "../../../assets/images/general/car.gif";
import CrushedImg from "../../../assets/images/Crash.svg";
import Flame from "../../../assets/images/Flame.gif";

const ExplosionContainer = styled(motion.div)`
  height: 100%;
  width: 100%;
  // position: absoulte;
  z-index: 3;
  display: flex;
  align-items: end;
  justify-content: center;
  overflow: hidden;
  position: relative;

  img {
    height: 70px;
    -drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    margin-bottom: 80px;

    @media (min-width: ${2800}px) {
      margin-bottom: -5%;
      height: 65%;
    }

    &.flame {
      height: auto;
      width: 10px;
      position: absolute;
      left: 50%;
      bottom: 0%;
      margin-left: -5px;
      margin-bottom: 38px;
    }

    @media (max-width: ${2074}px) and (min-height: ${900}px) {
      margin-bottom: 145px;
      height: 120px;

      &.flame {
        margin-bottom: 80px;
        width: 14px;
        margin-left: -7px;
      }
    }
  }
`;

const Img = styled(motion.img)``;

const ShipAnimation: FC<{ width?: number; crushed?: boolean }> = ({
  crushed,
}) => {
  return (
    <ExplosionContainer>
      {crushed ? (
        <Img
          initial={{ y: 0 }}
          animate={{ y: -700 }}
          transition={{
            duration: 4,
            ease: "linear",
          }}
          src={CrushedImg}
          alt="static rckit"
        />
      ) : (
        <>
          <img src={StaticRockit} alt="static rckit" />
          <img className="flame" src={Flame} alt="static rckit" />
        </>
      )}
    </ExplosionContainer>
  );
};

export default memo(ShipAnimation);
