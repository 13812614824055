import React, { FC, useState } from "react";
import styled from "styled-components";
import UserAvatar from "../../../components/UserAvatar";
import { Profile } from "../../../constants/interfaces/Profile";
import GameConfigurations from "./GameConfigurations";
import useTranslate from "../../../config/language";
import { KEYWORDS } from "../../../config/language/keywords";
import AvatarsModal from "./AvatarsModal/AvatarsModal";

const StyledContainer = styled.div`
  position: absolute;
  min-height: 465px;
  width: 294px;
  background: #151e32;
  border-radius: 18px;
  top: 58px;
  right: 0px;
  z-index: 100;
  padding: 26px;
  height: calc(100dvh - 58px);
  width: 100%;
  overflow: auto;

  @media (min-width: ${2800}px) {
    top: 95px;
    right: 36px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
`;

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div:first-child {
    background-color: #532fb6;
    width: 110px;
    height: 110px;
    background-size: 70px;
  }
`;

const Username = styled.div`
  color: #fff;
  margin: auto;
  text-transform: uppercase;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: 700;
  color: #4b6093;
`;

const ChangeAvatarButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #233050;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  outline: none;
  border: none;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
  width: 156px;
  height: 35px;
  color: #fff;
  margin-bottom: 24px;
`;

const Body = styled.div``;

const ProfileDropdown: FC<{ profile: Profile; handleClose: () => void }> = ({
  profile,
}) => {
  const translate = useTranslate();
  const [changeAvatarOpen, setChangeAvatarOpen] = useState(false);
  const avatarId = profile?.Player?.A || 0;

  const handleOpenChangeAvatarModal = () => {
    setChangeAvatarOpen(true);
    // handleClose();
  };

  return (
    <StyledContainer>
      <Header>
        <AvatarContainer>
          <UserAvatar size={84} avatarId={avatarId} />
          <Username>{profile.Player && profile.Player.N}</Username>
        </AvatarContainer>

        <ChangeAvatarButton onClick={handleOpenChangeAvatarModal}>
          {translate(KEYWORDS.EditProfile)}
        </ChangeAvatarButton>
      </Header>

      <Body>
        <GameConfigurations />
      </Body>

      {changeAvatarOpen && (
        <AvatarsModal closeModal={() => setChangeAvatarOpen(false)} />
      )}
    </StyledContainer>
  );
};

export default ProfileDropdown;
