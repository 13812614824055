import React, { FC } from "react";
import { TableHead } from "../../../../components/Table/TableHead";
import { TableBody } from "../../../../components/Table/TableBody";
import styled from "styled-components";
import {
  NeutralFirstTableCell,
  NeutralFourthTableCell,
  NeutralSecondTableCell,
  NeutralThirdTableCell,
} from "./NeutralTableCell";
import { GameHistory } from "../../../../constants/interfaces/GameHistory";
import { StyledTableHeaderCell } from "./TableHeaderCell.";
import { KEYWORDS } from "../../../../config/language/keywords";
import useTranslate from "../../../../config/language";
import { useIsMobile } from "../../../../helpers/hooks/useIsMobile";

const Wrapper = styled.div`
  overflow-y: auto;
  height: calc(100vh - 86px);
  width: 100%;
  padding-bottom: 30px;

  &::-webkit-scrollbar {
    width: 2.79px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: #3b3c41;

    &:hover {
      background-color: #3b3c41;
    }
  }

  @media (min-width: ${1366}px) {
    max-height: calc(100vh - 287px);
  }

  @media (min-width: ${1920}px) {
    max-height: calc(100vh - 350px);
  }
`;

const Table = styled.div`
  font-size: 12px;
  line-height: 15px;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  text-align: center;
  border: 0;
  
  @media (max-width: ${576}px) {
    padding: 0 7px;
  }

  .table-body {
    width: 100%;
  }
`;

const StyledTableRow = styled.div`
  width: 100%;
  display: flex;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  &.first {
    border-top: 0;

    @media (min-width: ${1366}px) {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
`;

const ProvablyFairTable: FC<{
  items: Partial<GameHistory>[];
}> = ({ items }) => {
  const translate = useTranslate();
  const isMobile = useIsMobile();

  return (
    <Wrapper>
      <Table>
        <TableHead>
          <div style={{ display: "flex", width: "100%" }}>
            <StyledTableHeaderCell className="data-id first">
              ID
            </StyledTableHeaderCell>
            <StyledTableHeaderCell className="data-coeffc">
              X
            </StyledTableHeaderCell>
            <StyledTableHeaderCell className="data-key">
              {translate(KEYWORDS.Key)}
            </StyledTableHeaderCell>
            <StyledTableHeaderCell className="data-hcode last">
              {translate(KEYWORDS.Hash)}
            </StyledTableHeaderCell>
          </div>
        </TableHead>

        <TableBody className="table-body">
          {items.map((item: Partial<GameHistory>, index) => {
            return (
              <StyledTableRow key={index}>
                <NeutralFirstTableCell>{item.ID}</NeutralFirstTableCell>
                <NeutralSecondTableCell>
                  {item.BustedAt || "-"}
                </NeutralSecondTableCell>
                <NeutralThirdTableCell>
                  {isMobile
                    ? item.CrashSource?.slice(0, 10)
                    : item.CrashSource?.slice(0, 20) || "-"}
                </NeutralThirdTableCell>
                <NeutralFourthTableCell>
                  {isMobile
                    ? item.CrashHash?.slice(0, 10)
                    : item.CrashHash?.slice(0, 20) || "-"}
                </NeutralFourthTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </Wrapper>
  );
};

export default ProvablyFairTable;
