import React, { useEffect, useState, FC } from "react";
import styled from "styled-components";
import Modal from "../Modal";
import useTranslate from "../../config/language";
import { KEYWORDS } from "../../config/language/keywords";

const Container = styled.div`
  position: relative;
  outline: 0;
  border-radius: 12px;
  border: 1px solid #ff6816;
  background: transparent;
  box-shadow: none;
  width: 96vw;
  box-sizing: border-box;
  max-width: 100%;
  height: 140px;
  margin-bottom: 30px;
  background: linear-gradient(
    2deg,
    rgba(255, 102, 0, 0.18) 0%,
    rgba(35, 26, 88, 0.18) 100%
  );

  .loading {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    margin-top: 8px;
    margin-left: 2px;
  }

  .loading__dot {
    animation: dot ease-in-out 1s infinite;
    background-color: #ffa778;
    display: inline-block;
    height: 5px;
    margin: 0 3px;
    width: 5px;
    border-radius: 50%;
  }

  .loading__dot:nth-of-type(2) {
    animation-delay: 0.2s;
  }

  .loading__dot:nth-of-type(3) {
    animation-delay: 0.3s;
  }

  @keyframes dot {
    0% {
      background-color: #ffa778;
      transform: scale(1);
    }
    50% {
      background-color: #fff;
      transform: scale(1.3);
    }
    100% {
      background-color: #ffa778;
      transform: scale(1);
    }
  }

  .warning-icon {
    margin-right: 4px;
  }

  @media (min-width: ${2800}px) {
    width: 715px;
    padding: 54px 20px;
    border-radius: 8px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 10px 15px 10px;

  .close-icon {
    width: 20px;
    height: 20px;
  }
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #ffa778;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 19px;
  margin-top: 34px;

  @media (min-width: ${2800}px) {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 40px;
  }
`;

const Button = styled.button`
  border: 0;
  outline: none;
  border-radius: 8px;
  background: #ff6310;
  color: #fff;
  position: absolute;
  left: 50%;
  bottom: -20px;
  width: 110px;
  font-size: 20px;
  height: 40px;
  margin-left: -55px;
`;

const ConnectionModal: FC<{ close?: () => void }> = ({ close }) => {
  const translate = useTranslate();
  const [second, setSecond] = useState(10);

  useEffect(() => {
    if (second === 0) {
      window.location.reload();
      return;
    }

    const interval = setInterval(() => setSecond(second - 1), 1000);

    return () => {
      clearInterval(interval);
    };
  }, [second]);

  return (
    <Modal>
      <Container>
        <Header></Header>
        <Text>
          {translate(KEYWORDS.WeakInternetAlert)}
          <div className="loading">
            <span className="loading__dot"></span>
            <span className="loading__dot"></span>
            <span className="loading__dot"></span>
          </div>
        </Text>
        <Button
          onClick={() => {
            window.location.reload();
          }}
        >
          Retry
        </Button>
      </Container>
    </Modal>
  );
};

export default ConnectionModal;
