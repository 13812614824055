import styled from "styled-components";
import Button from "../../components/Button/Button";

export const StyledActionPanel = styled.div`
  position: relative;
`;

export const GameBoardWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 8px;

  @media (min-width: ${2800}px) {
    flex-direction: row;
    padding-top: 20px;
  }
`;

export const GameBoard = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-border-radius: 11px;
  width: 100%;
  height: 174px;
  padding: 6px;
  opacity: 1;

  &:last-child {
    margin-top: 8px;
  }

  &.opacity {
    opacity: 0.7;
  }

  @media (min-width: ${2800}px) {
    width: 50%;
    border-radius: 8px;
    -webkit-border-radius: 8px;

    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-top: 0;
      margin-left: 10px;
    }
  }

  @media (min-width: ${1366}px) {
    height: 190px;
  }

  @media (min-width: ${1920}px) {
    height: 280px;
    padding: 12px;
    border: none;
  }
`;

export const GameTabsNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Nav = styled.ul`
  display: flex;
  align-items: center;
  border: 1px solid rgba(56, 46, 112, 0.2);
  border-radius: 6px;
  -webkit-border-radius: 6px;
  color: rgba(255, 255, 255, 0.3);
  font-feature-settings: "case" on;

  @media (min-width: ${1366}px) {
    border: 1px solid rgba(56, 46, 112, 0.3);
  }
`;

export const NavItem = styled.li`
  font-weight: 500;
  font-size: 8px;
  line-height: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20px;
  padding: 5px 21px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  font-feature-settings: "case" on;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    color: #ffffff;
  }

  &.active {
    color: #ffffff;
    background: #160f37;
  }

  @media (min-width: ${2800}px) {
    min-width: 43px;

    &:hover {
      color: #6d5cce;
    }

    &.active:hover {
      color: #ffffff;
    }
  }

  @media (min-width: ${1366}px) {
    font-size: 13px;
    line-height: 14px;
    min-width: 81px;
    min-height: 25px;
    padding: 6px 8px 5px 8px;
  }

  @media (min-width: ${1920}px) {
    font-size: 16px;
    line-height: 19px;
    min-width: 105px;
    min-height: 34px;
    // padding: 6px 8px 5px 8px;
  }
`;

// export const Inner = styled.span`
//   color: rgba(255, 255, 255, 0.3);
//   display: block;

//   padding: 5px 10px;
//   border-radius: 6px;
//   -webkit-border-radius: 6px;
//   min-height: 22px;

//   &:hover {
//     cursor: pointer;
//     color: #ffffff;
//   }

//   &.active {
//     color: #ffffff;
//     background: #160f37;
//   }

//   @media (min-width: ${2800}px) {
//     min-width: 43px;
//     padding: 2px 2px;

//     &:hover {
//       color: #6d5cce;
//     }

//     &.active:hover {
//       color: #ffffff;
//     }
//   }

//   @media (min-width: ${1366}px) {
//     min-width: 105px;
//     min-height: 36px;
//     padding: 6px 8px 5px 8px;
//   }

//   @media (min-width: ${1920}px) {
//     padding: 8px 8px 7px 8px;
//     min-width: 104px;
//   }
// `;

export const Tab = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 12px;

  &.game-nums-twocolumcn-content {
    padding-top: 7px;
  }

  @media (min-width: ${1366}px) {
    padding-top: 10px;

    &.game-nums-twocolumcn-content {
      padding-top: 5px;
    }
  }

  @media (min-width: ${1920}px) {
    padding-top: 20px;

    &.game-nums-twocolumcn-content {
      padding-top: 12px;
    }
  }
`;

export const Columns = styled.div`
  font-size: 0;
`;

export const Column = styled.div`
  display: inline-block;
  width: 50%;
  padding-left: 6px;
  position: relative;

  &:nth-child(1) {
    padding-left: 0px;
    padding-right: 6px;
  }
`;

export const AutoLabel = styled.label`
  display: block;
  font-size: 8px;
  line-height: 9px;
  font-weight: 400;
  margin-top: 6px;
  font-feature-settings: "case" on;

  @media (min-width: ${1366}px) {
    margin-top: 10px;
    font-size: 10px;
    line-height: 12px;
  }
`;

export const SliderHandle = styled.input`
  -webkit-appearance: none;
  width: 80%;
  height: 6px;
  background: linear-gradient(
    270deg,
    #45397c 21.15%,
    #ddd3f8 48.23%,
    #44377b 83.65%
  );
  outline: none;
  border-radius: 100px;

  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  margin: 20px auto 21px;
  background: linear-gradient(
    270deg,
    #45397c 21.15%,
    #ddd3f8 48.23%,
    #44377b 83.65%
  );
  position: relative;
  height: 3px;
  border-radius: 4px;
  -webkit-border-radius: 4px;

  &.game-numslider-twocolumn {
    display: flex;
    margin: 15px auto 16px;
  }

  @media (min-width: ${2800}px) {
    width: 100%;
    margin: 13px 0 13px;
    height: 2px;
  }

  @media (min-width: ${1366}px) {
    margin: 22px 0 21px;
    height: 6px;

    &.game-numslider-twocolumn {
      margin: 13px 0 13px;
    }
  }

  @media (min-width: ${1920}px) {
    margin: 31px 0 31px;

    &.game-numslider-twocolumn {
      margin: 24px 0 24px;
    }
  }

  // @media (min-width: ${1920}px) {
  //   margin: 31px 19px 31px;

  //   &.game-numslider-twocolumn {
  //     margin: 24px 29px 24px 0;
  //   }
  // }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    border: 1px solid #214834;
    box-shadow: 0px 0px 6px 0px rgba(23, 174, 67, 0.8);
    background: #fff;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 0px 10px 0px rgba(23, 174, 67, 0.9);
    }

    @media (min-width: ${2800}px) {
      width: 16px;
      height: 16px;
    }

    @media (min-width: ${1366}px) {
      width: 26px;
      height: 26px;
    }
  }

  &::-moz-range-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid #214834;
    box-shadow: 0px 0px 6px 0px rgba(23, 174, 67, 0.8);
    background: #fff;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 0px 10px 0px rgba(23, 174, 67, 0.9);
    }

    @media (min-width: ${2800}px) {
      width: 16px;
      height: 16px;
    }

    @media (min-width: ${1366}px) {
      width: 26px;
      height: 26px;
    }
  }
`;

export const ButtonInput = styled(Button)`
  font-size: 18px;

  border: 0;
  width: 100%;
  display: block;

  border-radius: 6px;
  -webkit-border-radius: 6px;
  color: #ffffff;
  background: #17ae43;
  font-feature-settings: "case" on;
  cursor: pointer;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-transform: uppercase;

  &.btn-cancel {
    background: #a62828;
  }

  // &:hover {
  //   background: #6d5cce;
  // }

  @media (min-width: ${1366}px) {
    font-size: 16px;
  }

  @media (min-width: ${1920}px) {
    font-size: 28px;
  }
`;
