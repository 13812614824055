import React, { FC } from "react";
import NumberInput from "../../../../../components/NumberInput/NumberInput";
import Button from "../../../../../components/Button/Button";
import styled from "styled-components";
import { motion } from "framer-motion";
import useTranslate from "../../../../../config/language";
import { KEYWORDS } from "../../../../../config/language/keywords";
// import { roundNumber } from "../../../../../helpers/functions/round-number";
// import { CURRENCY_SYMBOL } from "../../../../../constants/constants";

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const InputContainer = styled.div`
  display: flex;
  flex: 40;
  margin-right: 20px;

  .input {
    border-radius: 18px 0px 0px 18px;
  }

  .arrows {
    right: 10px;
  }
`;
const BetButtonContainer = styled(motion.div)`
  display: flex;
  flex: 48;
  margin-right: 0px;

  @media (min-width: ${2800}px) {
    margin-right: 22px;
  }
`;

const StyledButton = styled(Button)`
  font-size: 21px;
  @media (max-height: ${550}px), (max-width: ${1100}px) {
    font-size: 26px;
  }
`;

const RegisterPanel: FC<{
  amount: number;
  setAmount(amount: number | null): void;
  handleRegister(): void;
  toggleBoard(): void;
  icon: React.ElementType;
  minValue?: number;
  maxValue?: number;
}> = ({
  amount,
  setAmount,
  handleRegister,
  toggleBoard,
  icon: Icon,
  minValue,
  maxValue,
}) => {
  const translate = useTranslate();

  return (
    <StyledContainer>
      <InputContainer>
        <NumberInput
          value={amount}
          onChange={setAmount}
          minValue={minValue}
          maxValue={maxValue}
          step={1}
          className="input"
          arrowClass="arrows"
        />
      </InputContainer>

      <BetButtonContainer>
        <StyledButton text={translate(KEYWORDS.Bet)} onClick={handleRegister} />
      </BetButtonContainer>
    </StyledContainer>
  );
};

export default RegisterPanel;
