import React, { FC } from "react";
import styled from "styled-components";
import useTranslate from "../../config/language";

const StyledNavigationItem = styled.div<{
  active?: boolean;
  disabled?: boolean;
}>``;

const NavigationItem: FC<{
  text: string;
  index: number;
  active: boolean;
  onClick(index: number): void;
  className?: string;
  disabled?: boolean;
}> = ({ text, active, onClick, index, className, disabled }) => {
  const translate = useTranslate();

  return (
    <StyledNavigationItem
      className={className}
      active={active}
      onClick={() => !disabled && onClick(index)}
      disabled={disabled}
    >
      {translate(text)}
    </StyledNavigationItem>
  );
};

export default NavigationItem;
