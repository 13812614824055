import styled from "styled-components";
import { motion } from "framer-motion";
import { variantIcon } from "./InfoSnackbar";

export const SnackbarWrapper = styled(motion.div)<{
  open?: string;
  variant: keyof typeof variantIcon;
}>`
  width: fit-content;
  min-width: 112px;
  max-width: 550px;
  height: 44px;
  border-radius: 6px;
  box-sizing: border-box;
  background: ${(props) =>
    props.variant === "success"
      ? `linear-gradient(0deg, rgba(104, 138, 78, 0.632) -10.94%, rgba(131, 184, 90, 0.176) 100%)`
      : `linear-gradient(359.8deg, rgba(167, 63, 63, 0.61) -6.83%, rgba(65, 18, 18, 0.59) 99.82%)`};
  backdrop-filter: blur(2px);

  font-feature-settings: "case" on;

  font-size: 17px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  display: flex;
  align-items: center;
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  transition: visibility 1s linear;
  z-index: 2;
  padding: 13px;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }

  @media (min-width: ${1366}px) {
    height: 60px;
    font-size: 22px;
    line-height: 26px;
    padding: 24px;
    margin-top: 25px;

    background: ${(props) =>
      props.variant === "success"
        ? `linear-gradient(180deg, rgba(104, 138, 78, 0.79) 0%, rgba(131, 184, 90, 0.22) 100%)`
        : `linear-gradient(180deg, rgba(167, 63, 63, 0.61) 0%, rgba(65, 18, 18, 0.59) 100%)`};
    backdrop-filter: ${(props) =>
      props.variant === "success" ? `blur(2px)` : `blur(4px)`};
  }
`;

export const IconWrapper = styled.div<{ background: string }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.background};

  .success {
    height: 12.47px;
    width: 12.47px;
  }

  .error {
    height: 16.21px;
    width: 16.21px;
  }

  @media (min-width: ${1366}px) {
    width: 40px;
    height: 40px;

    .success {
      height: 15.85px;
      width: 15.24px;
    }
  }
`;

export const StyledMessageContainer = styled.div`
  font-weight: 700;
  font-feature-settings: "case" on;
  font-size: 14px;
  line-height: 16px;
  margin-left: 10px;
  color: #fff;

  @media (min-width: ${1366}px) {
    font-size: 22px;
    line-height: 26px;
    margin-left: 20px;
  }
`;
