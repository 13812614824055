import styled from "styled-components";

export const StyledGameBoardContainer = styled.div<{
  height: number;
  landscapeHeight?: number;
}>`
  height: ${(props) => props.height}px;
  width: calc(100% + 28px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  margin-top: 10px;
  background: linear-gradient(to top, #0d1424, #141f35);
  overflow: hidden;
  margin-left: -14px;

  img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  @media (max-width: ${2074}px) {
    height: calc(100dvh - 420px);
    margin-top: 0px;
  }

  @media (min-width: ${992}px) {
    margin-top: 26px;
    height: calc(100vh - 495px);
  }

  @media screen and (min-device-width: 320px) and (max-device-width: 992px) and (orientation: landscape) {
    height: ${(props) => `calc(${props.landscapeHeight}px - 454px)`};
  }
`;

// export const GameBorder = styled.div<{ width: number; height: number }>`
//   width: ${(props) => props.width}px;
//   height: ${(props) => props.height}px;
//   display: flex;
//   justify-content: center;
//   position: relative;
//   padding-top: 6px;
//   background: red;
// `;

export const PreRenderedExplosion = styled.div`
  visibility: hidden;
  overflow: hidden;
  position: absolute;

  img {
    position: absolute;
  }
`;

export const LoaderImg = styled.img`
  width: 55dvw;
  max-width: 230px;
  margin-top: 12vh;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;
