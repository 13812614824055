import { CURRENCY_SYMBOL } from "../../../constants/constants";
export const russian = {
  AccountBanned: "Ваш аккаунт заблокирован!",
  AllBets: "Все ставки:",
  AlreadyRegistered: "Вы уже сделали ставку",
  Animation: "Анимация",
  AutoBet: "АВТО-СТАВКА",
  AutoBetting: "Авто ставка",
  AutoCashout: "АВТО-КЭШАУТ",
  AutoCashouting: "Авто кэшаут",
  Balance: "БАЛАНС",
  BalanceShort: "Сум",
  Banned: "Вы заблокированы",
  BaseBet: "НАЧАЛЬНАЯ СТАВКА",
  Bet: "Ставка",
  BetMaximum: "Ставка должна быть максимум {}GEL",
  BetMustBe: "Ставка должна быть минимум",
  Bets: "Ставки",
  Stakes: "Ставки",
  BuyIn: "Ставка",
  Cashout: "КЭШАУТ",
  CashoutFromGame: "Кэшаут",
  CashoutMaxMustBe: "Кэшаут должен быть максимум",
  CashoutMustBe: "Кэшаут должен быть минимум 1.01",
  ChangeAvatar: "Изменить аватар",
  CheckFair: "1. Что такое проверка шансов?",
  CheckHash: "3. Как проверить достоверность кода?",
  CheckResult: "Проверить результат",
  Circulation: "#",
  Clear: "Очистить",
  Crashed: "Коэфф.",
  CurrentBets: "СТАВКИ",
  Day: "24 часов",
  DearUsers: "Извините за неудобства",
  en: "EN",
  Error: "Ошибка",
  ExampleForFair:
    "Номер игры - 9088 Коэффициенты - 1,06 Время генерации - 12/03/2019_16: 09 Уникальный игровой номер - Fvv + 5TVHK2621A == В результате ключ к игре будет: 9088_1.06_Rockit_12/03/2019_16:09_Fvv+5TVHK2621A== И код достоверности, сгенерированный алгоритмом SHA256: 4b38fc175d17471ea757556444af951303d94198b16c7f666505dec6f251612d",
  Examples: "Пример:",
  FairGame: "Честная игра",
  Fill: "Пополнение",
  FreeBet: "Фрибет",
  GameIsStopped: "Игра остановлена",
  GameKey: "2. Что означает игровой ключ и код достоверности?",
  ServerConnectionTemp: "Подключение к серверу.",
  GameRules: "Правила игры",
  GameStart: "Игра начинается",
  GameStarted: "Игра началась, удачи",
  GameStatusIsNotRegistering: "Ставки больше не принимаются",
  GameWillResume: "Время до возобновления",
  GotoHome: "HOME",
  Hash: "Hash",
  History: "ИСТОРИЯ",
  HowToCheckFair:
    "Этот метод позволяет удостовериться, что шансы на игру (число, на котором игра окончена) заранее определены, до того, как игроки зарегистрируют свои ставки. В любой момент времени заранее определяются 5 раундов. Ниже приведен хэш-код 5 прошедших и 5 предстоящих раундов.",
  HowToCheckGameKey:
    "Шансы на игру (число, на котором игра закончится) заранее определены, за 5 раундов. На игровом сервере генерируется игровой ключ, который состоит из следующих параметров: [номер игры] _ [коэффициенты] _ [Rockit] _ [время генерации] _ [уникальный номер игры]",
  HowToCheckHash:
    "После окончания игры будет доступен игровой ключ, который можно зашифровать с помощью любого онлайн-калькулятора SHA256, и потом сравнить его с кодом достоверности, указанным для следующих 5 раундов.",
  IncreaseBy: "УВЕЛИЧИТЬ В",
  Jackpot: "ДЖЕКПОT",
  ka: "KA",
  ua: "UA",
  Key: "Ключ",
  LanguageChangeAcception: "Вы правда хотите изменить язык?",
  LanguageChangeWarning: "Обновление страницы закроет текущую игру",
  LastGames: "ПОСЛЕДНИЕ ИГРЫ",
  LastHand: "Предыдущая игра",
  LastTopSpins: "ТОП КОЭФФИЦИЕНТЫ ЗА 24 ЧАСА",
  LastTopWins: "ТОП ВЫИГРЫШИ ЗА 24 ЧАСА",
  LeaveMessage: "Оставьте комментарий",
  Login: "Войти",
  LoginFailed: "При входе произошла ошибка",
  MainPage: "Главная страница",
  ManualBet: "СТАВКА",
  MinFreebetCashout: "Мин. коэффициент 1.5",
  Month: "Месяца",
  MultiplierCashout: "Мульт. Кэшаут",
  Music: "Музыка",
  My: "Мои",
  No: "Нет",
  NotEnoughMoney: "Недостаточно денег",
  OnLoss: "НА ПРОИГРЫШ",
  OnWin: "НА ВЫИГРЫШ",
  OpenHere: "Открыть здесь",
  PlaceYourBet: "Делайте ставки",
  Player: "Игрок",
  Rating: "ТОП",
  Register: "СДЕЛАТЬ СТАВКУ",
  Registration: "ПРИНИМАЮТСЯ СТАВКИ",
  Reload: "Обновить",
  Result: "Результат",
  ResultsHash: "Хеш-код результата",
  ReturnToBaseBet: "ВЕРНУТСЯ НА НАЧАЛЬНУЮ СТАВКУ",
  ReturnToCash: "Вернутся на реальные деньги",
  Round: "Раунд",
  ru: "RU",
  GameRules1:
    "В игре игроки имеют возможность сделать ставки до начала раунда.",
  GameRules2:
    "Коэффициент начинает увеличиваться с началом игры, пока не сгорит.",
  GameRules3: "Точка сгорания генерируется по принципу случайности.",
  GameRules4: "Игрок должен успеть обналичить свой выигрыш  до сгорания.",
  GameRules5:
    "Ставки, не обналиченные в момент сгорания считаются проигранными.",
  GameRules6:
    "Ставка игрока будет умножена на коэффициент, по которому ставка была обналичена при выплате до выгорания.",
  GameRules7: `Рост коэффициента начинается с 1.0. В случае сгорания на 1.0, все ставки считаются проигранными. Например, игрок сделал ставку 5${CURRENCY_SYMBOL} и на коэффициенте 2.0. обналичил сумму,  ему будут начислены 2*5=10${CURRENCY_SYMBOL}. Если коэффициент сгорит раньше 2.0, например на 1.75, в таком случае игрок проиграет весь бет -5${CURRENCY_SYMBOL}.`,
  GameRules8:
    "В случае покидания  игры (прекращения связи), произойдет автоматический кэшаут согласно произведению  ставки и существующего на тот момент коэффициента высоты. Покиданием игры будет считаться  прекращение сессии игры по любой причине.",
  GameRules9:
    "Игрок может одновременно сделать ставки и от произвести их кэшаут по-отдельности.",
  GameRules10:
    "Игрок может сделать ставку по мере желаемых условий, сделав эту ставку автоматически. В существующем окне есть возможности уточнить сумму ставки, коэффициент обналичивания, максимальную сумму обналичивания. Также предварительное ​определение условий следующей ставки в случае выигрыша или проигрыша.",
  GameRules11: "Выигрыш автоматически отразится на балансе игрока.",
  GameRules12:
    "Игрок может ознакомится с историей своей игры, нажав на кнопку «Мои ставки».",
  GameRules13:
    "В случае выявления подтвержденного пробела в игре, организатор игры оставляет за собой право удержать или откорректировать предполагаемый выигрыш пользователя.",
  GameRules16: "Лимиты игры:",
  GameRules17: `Минимальная ставка - 0.1${CURRENCY_SYMBOL}.`,
  GameRules18: `Максимальная ставка - 80${CURRENCY_SYMBOL}.`,
  GameRules19: `Максимальный выигрыш игрока (ставка *коэффициент) – 10,000${CURRENCY_SYMBOL}, когда коэффициент ставки * достигнет 10,000${CURRENCY_SYMBOL}, სСистема автоматически выплатит активную ставку.`,
  GameRules20: `Когда ставки, сделанные всеми игроками, умноженные на коэффициенты, достигнут 30,000${CURRENCY_SYMBOL}, в одном раунде, система автоматически выплатит все активные ставки.`,
  GameRules21: "Минимальный мультиплеер в игре 1.",
  GameRules22: "Максимальный мультиплеер в игре 1,102,088.",
  GameRules23: "RTP игры составляет 96.04-97.4%.",
  GameRules24:
    "«Минимальный автоматический кэшаут составляет x1,1, а максимальный автоматический кэшаут составляет x100000»",
  GameRules25:
    "«Минимальный кэшаут составляет x1,01, а максимальный кэшаут составляет x100000».",
  GameRules26:
    "Игрок может ознакомится с последними коэффициентами игры в верхней секции игрового окна.",
  GameRules27:
    "Игрок может ознакомится с информацией о рейтинге, нажав на кнопку 'Рейтинг'.",
  GameRules28:
    "«Если коэффициент кэшаута и коэффициент краша совпадают, игрок выигрывает»",
  Save: "Сохранить",
  ServerConnection: "Подключение к серверу",
  InternetConnectionLost:
    "Возникла проблема с подключением к Интернету, обновите страницу.",
  ServerError: "Ошибка при подключении к серверу",
  SessionHasExpired: "Сессия истекла",
  SitesUpdate: "Ведутся планированные технические работы!",
  Sound: "Звук",
  Statistics: "Статистика",
  StopIf: "СТОП, ЕСЛИ СТАВКА >",
  Submit: "Подтвердить",
  SuccessfulRegistration: "Ставка принята",
  TechnicalWork: "Непрерывная работа игры будет скоро восстановлена.",
  TicketCashoutMustBe: "Кэшаут должен быть минимум 1.5",
  Time: "время",
  TopSpins: "TOPS",
  TopWins: "Выигрыши",

  UnknowError: "Ошибка при подключении к серверу",
  Unregister: "Отменить ставку",
  UnregisterFreeBet: "Отменить Фрибет",
  Unregistered: "Ставка отменена",
  User: "Игрок",
  UserName: "Игрок",
  UserShort: "Игрок",
  WaitingForNextRound: "Ожидаем новый раунд",
  Win: "Выигрыш",
  WinAmount: "Выигрыш",
  WinMultiplier: "Коэфф. выигрыша",
  Winners: "Победители",
  WrongAmount: "Недостаточно денег",
  WrongConfiguration: "Недостаточно денег",
  Year: "Год",
  Yes: "Да",
  YouAreNotRegistered: "Вы не зарегистрированы в этой игре",
  EnterText: "Введите текст",
  PlayerNotFound: "Player Not Found",
  Jan: "янв.",
  Feb: "фев.",
  Mar: "мар.",
  Apr: "апр.",
  May: "май.",
  June: "июн.",
  July: "июл.",
  Aug: "авг.",
  Sept: "сен.",
  Oct: "окт.",
  Nov: "ноя.",
  Dec: "дек.",
  BlockedUser: "Ваш аккаунт заблокирован",
  LoadingProblem: "Не удалось загрузить игру, перезагрузите страницу.",
  January: "Январь",
  February: "Февраль",
  March: "Март",
  April: "Апрель",
  MayLong: "май",
  JuneLong: "июнь",
  JulyLong: "июль",
  August: "Август",
  September: "Сентябрь",
  Octomber: "Октябрь",
  November: "Ноябрь",
  December: "декабрь",
  Auto: "авто",
  Game: "игра",
  BetHistory: "История ставок",
  ViewTable: "Переглянути таблицю",
  TotalProfit: "Просмотр таблицы",
  WeakInternetAlert: "Слабое интернет-соединение",
  PageWillReload: "Страница перезагрузится",
  InSeconds: "Секунд",
};
