import React, { FC, memo } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Ship from "./components/Ship";
import Box1 from "../../assets/images/box1.webp";
import Box2 from "../../assets/images/box2.webp";
import Box3 from "../../assets/images/box3.png";
import Box4 from "../../assets/images/box4.png";
import Box5 from "../../assets/images/box5.webp";

const ShipContainer = styled(motion.div)`
    width: 100%;
    height: 100%;
    position; relative;
    z-index: 2;
    overflow: hidden;
`;

const ShipBox = styled(motion.div)`
  position: absolute;
  left: 60%;

  img {
    width: 120px;
  }

  &.second {
    left: 33px;

    img {
      width: 85px;
    }
  }

  &.third {
    left: 22%;

    img {
      width: 65px;
    }
  }

  &.four {
    left: 20px;

    img {
      width: 60px;
    }
  }

  &.five {
    left: 90%;

    img {
      width: 50px;
    }
  }
`;

const ShipContainerLoader = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
`;

export const Flying: FC<{ shipWidth: number; crushed?: boolean }> = memo(
  ({ shipWidth, crushed }) => {
    return (
      <ShipContainer>
        {!crushed && (
          <ShipContainerLoader>
            <ShipBox
              className="second"
              initial={{ y: -200 }}
              animate={{ y: 1000 }}
              transition={{
                repeat: Infinity,
                repeatType: "loop",
                duration: 4,
                ease: "linear",
              }}
            >
              <img alt="box" src={Box1} />
            </ShipBox>
            <ShipBox
              initial={{ y: -10 }}
              animate={{ y: 1500 }}
              transition={{
                repeat: Infinity,
                repeatType: "loop",
                duration: 5,
                ease: "linear",
              }}
            >
              <img alt="box" src={Box2} />
            </ShipBox>
            <ShipBox
              className="third"
              initial={{ y: -300 }}
              animate={{ y: 800 }}
              transition={{
                repeat: Infinity,
                repeatType: "loop",
                duration: 4,
                ease: "linear",
              }}
            >
              <img alt="box" src={Box3} />{" "}
            </ShipBox>
            <ShipBox
              className="four"
              initial={{ y: -200 }}
              animate={{ y: 1000 }}
              transition={{
                repeat: Infinity,
                repeatType: "loop",
                duration: 8,
                ease: "linear",
              }}
            >
              <img alt="box" src={Box4} />{" "}
            </ShipBox>
            <ShipBox
              className="five"
              initial={{ y: -50 }}
              animate={{ y: 1200 }}
              transition={{
                repeat: Infinity,
                repeatType: "loop",
                duration: 4,
                ease: "linear",
              }}
            >
              <img alt="box" src={Box5} />{" "}
            </ShipBox>
          </ShipContainerLoader>
        )}

        <Ship crushed={!!crushed} animateFire={true} width={shipWidth} />
      </ShipContainer>
    );
  }
);
