import styled from "styled-components";

export const StyledTopBoardActions = styled.div`
  height: 40px;
  display: flex;
  z-index: 1;

  margin-top: 16px;

  @media (min-width: ${1366}px) {
    margin-top: 22px;
  }
`;

export const DropDownWrapper = styled.div`
  width: 50%;
  z-index: 1;
`;

export const Divider = styled.div`
  width: 8px;
  flex-shrink: 0;
`;
