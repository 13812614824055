import React, { FC } from "react";
import styled from "styled-components";

const StyledContent = styled.div`
  flex: 80;

  @media (min-width: ${2800}px) {
    display: flex;
    flex-direction: column;
    position: relative;
    order: 2;
    margin-left: 24px;
  }
`;

const Content: FC = ({ children }) => {
  return <StyledContent>{children}</StyledContent>;
};

export default Content;
