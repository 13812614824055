import React, { memo, useState } from "react";
import Table from "../../../components/Table/Table";
import { TableHead } from "../../../components/Table/TableHead";
import { TableHeaderRow } from "../../../components/Table/TableHeaderRow";
import CurrentList from "./CurrentList";
import BetsInfoPanel from "./components/BetsInfoPanel";
import {
  useCurrentBets,
  usePreviousBets,
} from "../../../config/store/state/app.state";
import useTranslate from "../../../config/language";
import { KEYWORDS } from "../../../config/language/keywords";
import { useProfile } from "../../profile/configs/store/state";
import { ListUser } from "../../../constants/interfaces/List";
import { TableBody } from "../../../components/Table/TableBody";
import {
  StyledCurrentList,
  TableOverflow,
  TableWrap,
  TableHeaderCell,
  InnerCell,
} from "./bets.styles";

const BetsList = () => {
  const [showLastBets, setShowLastBets] = useState(false);
  const translate = useTranslate();
  const profile = useProfile().Player;
  const currentBets = useCurrentBets();
  const previousList = usePreviousBets();

  const yourCurrentBets: ListUser[] = [];
  let sortedCurrentBets = currentBets.sort((a, b) => (a.bet > b.bet ? -1 : 1));
  sortedCurrentBets = sortedCurrentBets.filter((bet) => {
    if (bet.id === profile.I) {
      yourCurrentBets.push(bet);
    }
    return bet.id !== profile.I;
  });
  sortedCurrentBets.unshift(...yourCurrentBets);

  const yourLastBets: ListUser[] = [];
  let sortedPreviousBets = previousList.sort((a, b) =>
    a.bet > b.bet ? -1 : 1
  );
  sortedPreviousBets = sortedPreviousBets.filter((bet) => {
    if (bet.id === profile.I) {
      yourLastBets.push(bet);
    }
    return bet.id !== profile.I;
  });
  sortedPreviousBets.unshift(...yourLastBets);

  return (
    <StyledCurrentList>
      <BetsInfoPanel
        showLastBets={showLastBets}
        setShowLastBets={setShowLastBets}
        totalBets={showLastBets ? previousList.length : currentBets.length}
      />

      <TableOverflow>
        <TableWrap>
          <Table>
            <TableHead>
              <TableHeaderRow>
                <TableHeaderCell className="bets-user">
                  <InnerCell className="first">
                    {translate(KEYWORDS.Player)}
                  </InnerCell>
                </TableHeaderCell>

                <TableHeaderCell className="bets-bet">
                  <InnerCell>{translate(KEYWORDS.Bet)}</InnerCell>
                </TableHeaderCell>

                <TableHeaderCell className="bets-coeffc">
                  <InnerCell>{translate(KEYWORDS.Crashed)}</InnerCell>
                </TableHeaderCell>

                <TableHeaderCell className="bets-profit">
                  <InnerCell className="last">
                    {translate(KEYWORDS.WinAmount)}
                  </InnerCell>
                </TableHeaderCell>
              </TableHeaderRow>
            </TableHead>

            <TableBody className="roboto-font">
              <CurrentList
                items={showLastBets ? sortedPreviousBets : sortedCurrentBets}
              />
            </TableBody>
          </Table>
        </TableWrap>
      </TableOverflow>
    </StyledCurrentList>
  );
};

export default memo(BetsList);
