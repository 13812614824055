import styled from "styled-components";

export const StyledOddsList = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  gap: 5px;
  height: 30px;
  background: #131f35;
`;

export const Items = styled.div`
  width: 100%;
  position: relative;
`;

export const Wrap = styled.div`
  height: 30px;
  overflow: hidden;
  font-size: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;

  &.coeffc-wrap-abs {
    margin: 0;
    border: 1px solid #110e22;
    border-radius: 11px 0px 11px 11px;
    position: absolute;
    top: -23px;
    background: #0a0f24;
    z-index: 6;
    height: auto;
    overflow: visible;
    width: 100%;
    text-align: justify;
    min-height: 39px;
    padding-top: 8px;
    padding-right: 8px;
    text-align-last: left;
    display: grid; 
    grid-template-columns: repeat(auto-fill, 70px);
    grid-gap: 0.4rem; 
    row-gap: 0;
    justify-content: space-evenly;

    @media (min-width: ${769}px) and (max-width: ${1366}px) {
      grid-gap: 0.5rem; 
      padding-right: 6px;
    }

    @media (min-width: ${1366}px) {
      grid-gap: 0.8rem; 
      padding-right: 10px;
    }
  }

  @media (min-width: ${1366}px) {
    height: 34px;

    &.coeffc-wrap-abs {
      height: auto;
      padding-top: 12px;
    }
  }
`;

export const IconsContainer = styled.button`
  border: 0;
  padding: 0;
  cursor: pointer;
  background: transparent;
  position: relative;
  top: 2px;

  img {
    height: 28px;
  }
`;
