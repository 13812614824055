import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import MessageItem from './MessageItem';
import { useMessengerState } from '../../store/state';
import { ChatMessage } from '../../../../constants/interfaces/ChatMessage';
import SystemMessageItem from './SystemMessageitem';

const MessagesWrapper = styled.div`
    height: calc(100vh - 273px);
    overflow: auto;
    min-height: 222px;
    scrollbar-color: #ffffff21 #373754;
    scrollbar-width: thin;
    @media (max-width: ${768}px) {
        height: calc(100% - 120px);
    }
`;

const MessagesContainer = styled.div`
    padding: 16px 8px 4px 24px;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
`;

const Messages: FC = () => {
    const messagesEndRef = useRef<any>(null);
    const messages = useMessengerState().messages;

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({
                block: 'nearest',
                inline: 'start',
            });
        }
    }, [messages]);

    return (
        <MessagesWrapper>
            <MessagesContainer>
                <div ref={messagesEndRef} />
                {messages.map((message: ChatMessage) => {
                    if (message.system) {
                        return (
                            <SystemMessageItem
                                key={message.MID}
                                message={message}
                            />
                        );
                    } else {
                        return (
                            <MessageItem key={message.MID} message={message} />
                        );
                    }
                })}
            </MessagesContainer>
        </MessagesWrapper>
    );
};

export default Messages;
