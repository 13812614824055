import React, { FC } from "react";
import { MyList } from "../../../../constants/interfaces/MyList";
import { CURRENCY_SYMBOL } from "../../../../constants/constants";
import RenderMyBetsTableRows from "./RenderMyBetsTableRows";
import Table from "../../../../components/Table/Table";
import { TableHead } from "../../../../components/Table/TableHead";
import { TableHeaderRow } from "../../../../components/Table/TableHeaderRow";
import { TableBody } from "../../../../components/Table/TableBody";
import { useFormatDate } from "../../../../helpers/functions/format-date";
import { roundNumber } from "../../../../helpers/functions/round-number";
import useTranslate from "../../../../config/language";
import { KEYWORDS } from "../../../../config/language/keywords";
import { mapMyBetsValues } from "../configs/helpers";
import {
  Container,
  Head,
  StyledDate,
  Summery,
  Currency,
  TableWrap,
  TableHeaderCell,
  InnerCell,
} from "./RenderMyBetsTable.styles";

const RenderMyBetsTable: FC<{ items: MyList[] }> = ({ items }): any => {
  const translate = useTranslate();
  const formatDate = useFormatDate();
  const myBets = mapMyBetsValues(items);

  return (
    myBets &&
    myBets.map((item, index) => {
      let sum = 0;

      item.games.forEach((game) => {
        const profite = game.profit ? +game.profit : 0;
        sum = sum + profite;
      });

      if (new Date(item.date).toDateString() === new Date().toDateString()) {
        return <React.Fragment key={index}></React.Fragment>;
      } else {
        return (
          <Container key={index} className="container">
            <Head>
              <StyledDate>{formatDate(item.date, true)}</StyledDate>
              <Summery>
                {!!roundNumber(sum) && (
                  <>
                    {translate(KEYWORDS.TotalProfit)}
                    <Currency>{`${roundNumber(
                      sum
                    )}${CURRENCY_SYMBOL}`}</Currency>
                  </>
                )}
              </Summery>
            </Head>

            <TableWrap>
              <Table className="table-bets">
                <TableHead>
                  <TableHeaderRow>
                    <TableHeaderCell className="bets-my-date">
                      <InnerCell className="first">
                        {translate(KEYWORDS.Time)}
                      </InnerCell>
                    </TableHeaderCell>

                    <TableHeaderCell className="bets-my-bet">
                      <InnerCell>{translate(KEYWORDS.BuyIn)}</InnerCell>
                    </TableHeaderCell>

                    <TableHeaderCell className="bets-my-coeffc">
                      <InnerCell>{translate(KEYWORDS.Crashed)}</InnerCell>
                    </TableHeaderCell>

                    <TableHeaderCell className="bets-my-profit">
                      <InnerCell className="last">
                        {translate(KEYWORDS.WinAmount)}
                      </InnerCell>
                    </TableHeaderCell>
                  </TableHeaderRow>
                </TableHead>

                <TableBody>
                  <RenderMyBetsTableRows items={item.games} />
                </TableBody>
              </Table>
            </TableWrap>
          </Container>
        );
      }
    })
  );
};

export default RenderMyBetsTable;
