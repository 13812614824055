import React, { useEffect } from "react";
import { useErrorMessagesState } from "./store/state";
import { InfoSnackbar } from "../../components/InfoSnackbar/InfoSnackbar";
import { useHideMessageService } from "./store/services";
import styled from "styled-components";

const SnackbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  position: absolute;
  width: 100%;
  height: 93%;

  @media (max-width: ${700}px) {
    justify-content: end;
  }
`;

const HIDE_DURATION = 1000;

const ErrorMessages = () => {
  const messages = useErrorMessagesState();
  const hideMessage = useHideMessageService();
  useEffect(() => {
    const timer = setTimeout(() => {
      hideMessage();
    }, HIDE_DURATION);
    return () => {
      clearTimeout(timer);
    };
  }, [hideMessage, messages]);

  return (
    <SnackbarContainer>
      {messages.map((message, index) => {
        return (
          <InfoSnackbar
            key={index}
            open={!!message.status}
            variant={message.status ? message.status : "error"}
            message={message.message}
            keyword={message.keyword}
            handleClose={() => hideMessage()}
          />
        );
      })}
    </SnackbarContainer>
  );
};

export default ErrorMessages;
