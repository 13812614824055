import React, { FC } from "react";
import styled from "styled-components";
import "./styles.css";

import { useGameConfigs } from "../../config/store/state/app.state";

const StyledStarsContainer = styled.div`
  position: absolute;
  top: -100%;
  bottom: 0;
  left: -100%;
  overflow: hidden;
  transform: rotate(30deg);
  width: 300%;
  height: 300%;
  z-index: 1;

  @media (min-width: ${2800}px) {
    top: 0;
    right: 15px;
    left: -30%;

    width: 200%;
  }
`;

const StarsContainer: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const animate = useGameConfigs().animate;

  return (
    <StyledStarsContainer>
      {!disabled && animate ? (
        <>
          <div className="stars-one animate" />
          <div className="stars-two animate" />
        </>
      ) : (
        <>
          <div className="stars-one" />
          <div className="stars-two" />
        </>
      )}
    </StyledStarsContainer>
  );
};

export default StarsContainer;
