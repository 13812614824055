import React, { FC, useState } from "react";
import styled from "styled-components";
import SelectDropDownItems from "./SelectDropDownItems";
import ClickAwayListener from "../../helpers/hooks/clickawayListener";
import ArrowDown from "../Icons/ArrowDown";
import ArrowUp from "../Icons/ArrowUp";
import useTranslate from "../../config/language";

const StyledContainer = styled.div<{ disabled?: boolean }>`
  height: 100%;
  width: 100%;
  padding: 9px 24px 9px 5px;
  border: none;
  color: ${(props) => props.theme.colors.white};s
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
`;

const TextContainer = styled.div`
  width: 100%;
  min-height: 19px;
  position: relative;
`;

const Text = styled.div`
  // position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 70%;
  text-align: center;
  font-size: 14px;
`;

const IconContainer = styled.div`
  position: absolute;
  width: 13px;
  height: 100%;
  color: ${(props) => props.theme.colors.white};
  top: 0;
  right: 8px;
`;

const SelectDropDown: FC<{
  items: { text: string; index: any }[];
  selected: any;
  selectItem(selected: number | string): void;
  className?: string;
  disabled?: boolean;
}> = ({ selected, items, selectItem, className, disabled }) => {
  const [open, setOpen] = useState(false);
  const translate = useTranslate();

  return (
    <StyledContainer
      onClick={() => !disabled && setOpen(!open)}
      className={className}
      disabled={disabled}
    >
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <TextContainer>
          <Text>
            {translate(
              items && items.filter((item) => item.index === selected)[0].text
            )}
          </Text>
        </TextContainer>
        {open && (
          <SelectDropDownItems
            items={items}
            selectItem={selectItem}
            handleClose={() => setOpen(false)}
          />
        )}
        <IconContainer>{open ? <ArrowUp /> : <ArrowDown />}</IconContainer>
      </ClickAwayListener>
    </StyledContainer>
  );
};

export default SelectDropDown;
