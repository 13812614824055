import styled from "styled-components";

export const StyledContainer = styled.div``;

export const ConfigurationItem = styled.div<{ clickable?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  box-sizing: border-box;

  &.cursor {
    cursor: pointer;
  }
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  color: #4b6093;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;

  img {
    margin-right: 24px;
    width: 30px;
  }
`;

export const LabelIcon = styled.div`
  margin-right: 9px;
  display: flex;
`;

export const Actions = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;


export const InvitationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  input {
    height: 48px;
    background: #233050;
    border: 0px;
    border-bottom: 1px solid #ffffff;
    border-radius: 0px;
    outline: none;
    min-width: 100%;
    padding: 0 16px;
    color: #fff;
    font-size: 18px;
  }

  button {
    color: #fff;
    height: 52px;
    font-size: 22px;
  }
`