import styled from "styled-components";
export const Container = styled.div`
  position: relative;
  top: auto;
  background: #320703;
  left: 0;
  padding: 0 15px;
  right: 0;
  border-radius: 18px;
  -webkit-border-radius: 18px;
  width: 314px;

  @media (min-width: ${2800}px) {
    width: 700px;
  }

  @media (min-width: ${1366}px) {
    width: 866px;
  }

  @media (min-width: ${1920}px) {
    width: 956px;
    padding: 0 32px;
  }
`;

export const Head = styled.div`
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  font-feature-settings: "case" on;
  padding: 15px 50px 15px;
  position: relative;

  .popup-head-ic {
    position: absolute;
    left: 2px;
    top: 17px;
    width: 20px;
    height: 20px;
    fill: #6d5cce;
  }

  &.round-head {
    font-size: 12px;
    font-feature-settings: "case" off;
    padding: 15px 20px 8px 15px;
    min-height: 24px;

    .popup-head-ic {
      fill: #ffffff;
      width: 24px;
      height: 24px;
      margin-right: 4px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  @media (min-width: ${1366}px) {
    font-size: 27px;
    line-height: 30px;
    padding: 50px 50px 0px;
    border-bottom: 0;
    margin-bottom: 10px;

    .popup-head-ic {
      position: static;
      width: 34px;
      height: 28px;
      margin-right: 3px;
      vertical-align: top;
      margin-top: 1px;
    }

    &.round-head {
      font-size: 22px;
      line-height: 26px;
      padding: 44px 20px 0 15px;

      .popup-head-ic {
        display: none;
      }
    }
  }

  @media (min-width: ${1920}px) {
    font-size: 42px;
    line-height: 50px;
    padding: 71px 50px 0px;
    border-bottom: 0;
    margin-bottom: 10px;

    .popup-head-ic {
      position: static;
      width: 43px;
      height: 43px;
      margin-right: -3px;
      vertical-align: top;
      margin-top: 3px;
    }
  }
`;

export const Title = styled.p`
  display: inline-block;
  text-transform: uppercase;
  font-feature-settings: "case" on;
  
  &.round-title {
    color: #52b05a; 
    font-size: 18px;
    font-weight: 700;

    @media (min-width: ${2800}px) {
      font-size: 24px;
    }
  }

  @media (max-width: ${1366}px) {
    margin-top: 24px;
  }
`;

export const CloseWrapper = styled.div`
  position: absolute;
  right: -4px;
  top: 10px;
  display: block;
  padding: 10px;

  .popup-close-ic {
    width: 12px;
    height: 12px;
    fill: #ffffff;
    vertical-align: top;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      fill: #ca0505;
    }
  }

  @media (min-width: ${1366}px) {
    right: 10px;
    top: 27px;

    .popup-close-ic {
      width: 24px;
      height: 24px;
    }
  }

  @media (min-width: ${1920}px) {
    right: -6px;
    top: 25px;

    .popup-close-ic {
      width: 24px;
      height: 24px;
    }
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  padding: 15px 0;

  @media (min-width: ${2800}px) {
    flex-direction: row;
  }

  @media (min-width: ${1366}px) {
    padding: 54px 0 74px 0;
  }
`;

export const AvatarsList = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 32px;
  // height: 330px;
  min-width: 164px;
  row-gap: 10px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: ${2800}px) {
    order: 1;
    flex: 5;
  }
`;

export const AvatarsListItem = styled.div`
  width: 33%;
  display: flex;
  justify-content: center;
`;

export const AvatarsListItemImage = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 6px;
  margin: 8px;
  cursor: pointer; 
  padding: 12px;
  background-color: #212235;

  &:active {
    opacity: 0.8;
  }

  @media (min-width: ${2800}px) {
    height: 104px;
    width: 104px;
  }
`;

export const ProfileAvatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  padding-bottom: 15px;
  margin-bottom: 15px;

  @media (min-width: ${2800}px) {
    order: 2;
    flex: 4;
    border-left: 1px solid #493e3f;
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

export const ProfileAvatarImage = styled.img`
  width: 88px;
  padding: 15px;
  height: 88px;
  border-radius: 12.1714px;
  margin-bottom: 36px;
  background-color: #212235;

  @media (min-width: ${2800}px) {
    width: 144px;
    height: 144px;
    margin-bottom: 14px;
    padding: 30px;
  }
`;

export const ProfileAvatarName = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  margin-bottom: 24px;

  @media (min-width: ${1920}px) {
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 38px;
  }
`;

export const ProfileAvatarButton = styled.div`
  width: 213px;
  height: 59px;
  font-size: 24px;

  &.button-wrapper {
    display: none;
  }

  button {
    font-feature-settings: "case" on;
    font-family: 'Noto Sans Georgian';
    padding: 8px 0;
  }

  @media (min-width: ${2800}px) {
    &.button-wrapper {
      display: block;
    }
  }
`;

export const MobileButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  @media (min-width: ${2800}px) {
    display: none;
  }
`;
