import styled from "styled-components";

export const TableRow = styled.div`
  display: flex;
  width: 100%;

  &.table-bets-tr-highlight {
    background: linear-gradient(
      90.03deg,
      rgba(22, 67, 34, 0.77) 0.03%,
      rgba(17, 14, 34, 0.66) 99.98%
    );
  }
`;

export const StyledTd = styled.div`
  border: 0;
  border-collapse: collapse;
  padding: 9px 15px 8px 15px;

  &:nth-child(1) {
    text-align: left;
  }

  &.bets-my-date {
    width: 25%;
  }

  &.bets-my-bet {
    width: 27%;
  }

  &.bets-my-coeffc {
    width: 24%;
  }

  &.bets-my-profit {
    width: 22%;
  }

  @media (min-width: ${2800}px) {
    padding: 4px 4px;
  }

  @media (min-width: ${1366}px) {
    padding: 10px 4px;
    padding-top: 18px;
    padding-bottom: 18px;

    &:nth-child(1) {
      padding-left: 28px;
    }

    &.bets-my-date {
      padding-left: 19px;
    }

    &.bets-my-profit {
      padding-right: 19px;
    }
  }
`;

export const Coeff = styled.span`
  color: #52b05a;
`;
