import React, { FC, useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import BetsModal from "../BetsModal";
import { GameHistory } from "../../constants/interfaces/GameHistory";
import { getMultiplierRGBColor } from "../../features/betColors";
import { roundNumber } from "../../helpers/functions/round-number";

const StyledResultBox = styled(motion.div) <{ color?: string }>`
  display: inline-block;
  position: relative;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  padding: 8px;
  font-size: 12px;
  line-height: 14px;
  min-height: 30px;
  margin: 0px 3px 0;
  width: 60px;
  text-align: center;
  cursor: pointer;

  &.btn-green-coeffc {
    background: linear-gradient(
      0deg,
      rgba(27, 72, 45, 0.72) -7.35%,
      rgba(16, 120, 58, 0.72) -7.35%,
      rgba(31, 85, 52, 0) 57.35%
    );
  }

  &.btn-yellow-coeffc {
    background: linear-gradient(
      180deg,
      rgba(248, 255, 19, 0) 42.65%,
      rgba(248, 255, 19, 0.284) 110.29%
    );
  }

  &.coeffc-btn-abs {
    margin-bottom: 8px;
  }

  @media (min-width: ${1366}px) {
    font-size: 14px;
    line-height: 16px;
    min-height: 34px;
    padding: 8px;
    margin: 0px 5.5px 0;
    width: 70px;
    text-align: center;

    &.coeffc-btn-abs {
      margin-bottom: 12px;
    }
  }
`;

const CustomResultBox: FC<{ game: GameHistory }> = ({ game }) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      {open && (
        <BetsModal
          closeModal={() => setOpen(false)}
          bustedAt={game?.BustedAt}
          gameId={game.ID}
          hashCode={game.CrashHash}
          hashKey={game.CrashSource}
        />
      )}
      <StyledResultBox
        color={getMultiplierRGBColor(game?.BustedAt)}
        onClick={() => setOpen(true)}
        className={game.BustedAt > 5 ? "btn-yellow-coeffc" : "btn-green-coeffc"}
      >
        {roundNumber(game?.BustedAt)}x
      </StyledResultBox>
    </div>
  );
};

export default CustomResultBox;
