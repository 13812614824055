import React from "react";
import WebProfileSection from "../../features/profile/WebProfileSection";
import {
  StyledHeader,
  Img,
  Text,
  Balance,
  StyledHeaderLeft,
} from "./Header.styles";
import logo from "../../assets/images/general/b-88-logo.png";
import { useProfile } from "../../features/profile/configs/store/state";
import AvatarIcon from "../../assets/images/avatar.svg";
import SolarX from "../../assets/images/general/SolarX.svg";

const Header = () => {
  const profile = useProfile();

  return (
    <StyledHeader>
      <StyledHeaderLeft>
        <img src={AvatarIcon} alt="avatar" />
        <Text>
          <Balance>{profile?.Player?.N}</Balance>

          <div>
            {profile.B && profile.B
              ? (profile.B.WalletBalance).toFixed(1)
              : 0}
            <img src={SolarX} alt="droid" />
          </div>
        </Text>
      </StyledHeaderLeft>

      <Img src={logo} alt="Droid logo" />

      <WebProfileSection />
    </StyledHeader>
  );
};

export default Header;
