import React, { FC } from "react";
import Modal from "../Modal";
import Hash from "../Icons/Hash";
import Lock from "../Icons/Lock";
// import Close from "../Icons/Close";
import useTranslate from "../../config/language";
import { KEYWORDS } from "../../config/language/keywords";
import {
  PopupWrap,
  Head,
  Title,
  // CloseWrapper,
  Content,
  Scroll,
  Item,
  Label,
  Code,
  InfoText,
  RoundLab,
  Row,
  Key,
} from "./styles";
import Security from "../Icons/Security";

const BetsModal: FC<{
  gameId?: number;
  hashCode?: string;
  hashKey?: string;
  bustedAt?: number;
  closeModal(): void;
}> = ({ closeModal, gameId, hashCode, hashKey, bustedAt }) => {
  const translate = useTranslate();

  return (
    <Modal closeModal={closeModal}>
      <PopupWrap>
        <Head>
          <Title>
            <Security />
            {/* {translate(KEYWORDS.Round)} {gameId} */}
            Provably Fair
          </Title>
        </Head>

        <Content>
          <Scroll>
            <div>
              <Item className="first">
                <Label>{translate(KEYWORDS.ResultsHash)}</Label>

                <Code>
                  <Hash className="info-icon" />
                  <InfoText>{hashCode}</InfoText>
                </Code>
              </Item>

              <Item>
                <Label>{translate(KEYWORDS.CheckResult)}</Label>

                <Code>
                  <Lock className="lock" />

                  <div>
                    <Row>
                      <RoundLab>{translate(KEYWORDS.Key)}</RoundLab>
                      <RoundLab>{translate(KEYWORDS.Result)}</RoundLab>
                    </Row>

                    <Row className="bordered">
                      <Key>{hashKey}</Key>
                      <Key className="coeff">{bustedAt}x</Key>
                    </Row>
                  </div>
                </Code>
              </Item>
            </div>
          </Scroll>
        </Content>
      </PopupWrap>
    </Modal>
  );
};

export default BetsModal;
