import styled from "styled-components";

export const StyledHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 16px 14px;
  z-index: 3;

  @media (min-width: ${2800}px) {
    padding: 34px 36px 22px 17px;
  }
`;

export const StyledHeaderLeft = styled.div`
  display: flex;
  gap: 12px;
`

export const Text = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #52b05a;
  margin-top: 8px;
  font-weight: 700;

  div {
    display: flex;
    gap: 8px;
    align-items: center;

    img {
      height: 17px;
      position: relative;
      top: 1px;
    }
  }

  @media (min-width: ${2800}px) {
    display: none;
  }
`;

export const Balance = styled.div`
  color: #fff;
  font-feature-settings: "case" on;
`;

export const Img = styled.img`
  height: 24px;
  display: none;

  @media (min-width: ${2800}px) {
    height: 42px;
  }
`;
