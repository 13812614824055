import React, { createContext, FC } from "react";
import { useGameStatusState } from "../../config/store/state/app.state";
import { GAME_STATE, GameStatus } from "../../constants/interfaces/Game";
import GameMultiplier from "./components/GameMultiplier";
import useWindowDimensions from "../../helpers/hooks/windowDimentions";
import { calculateGameBoardDimensions } from "./tools";
import GameRunning from "./state/Running";
import { useErrorMessagesState } from "../../features/errorMessages/store/state";
import ErrorMessages from "../../features/errorMessages/ErrorMessages";
import { LoaderImg, StyledGameBoardContainer } from "./style";
import LoaderGif from "../../assets/images/mainloader.gif"

export const GameContext = createContext({ gameWidth: 300, gameHeight: 400 });

const GameBoard: FC<{ game: GameStatus }> = ({ game }) => {
  const { width, height } = useWindowDimensions();
  const gameDimensions = calculateGameBoardDimensions(width, height);
  const messages = useErrorMessagesState();

  const renderGame = () => {
    switch (game.state) {
      case GAME_STATE.LAUNCHING:
      case GAME_STATE.RUNNING: {
        return <GameRunning />;
      }
      case GAME_STATE.COUNTDOWN: {
        return (
          <LoaderImg
            alt=""
            src={LoaderGif}
            style={{ width: "25dvw", maxWidth: "90px", marginTop: "20vh" }}
          />
        );
      }
      case GAME_STATE.CRUSHED: {
        return <GameRunning crushed />;
      }
      default:
        return <div />;
    }
  };

  return (
    <StyledGameBoardContainer
      height={gameDimensions.height}
      landscapeHeight={window.innerWidth}
    >
      <GameContext.Provider
        value={{
          gameWidth: gameDimensions.width,
          gameHeight: gameDimensions.height,
        }}
      >
        {renderGame()}
      </GameContext.Provider>
      {!!game.multiplier &&
        game.state &&
        game.state !== GAME_STATE.COUNTDOWN && (
          <GameMultiplier multiplier={game.multiplier} gameState={game.state} />
        )}

      {messages.length > 0 && <ErrorMessages />}
    </StyledGameBoardContainer>
  );
};

const GameBoardContainer = () => {
  const gameState = useGameStatusState();
  if (!gameState) return <div />;
  return <GameBoard game={gameState} />;
};
export default GameBoardContainer;
