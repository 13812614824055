import React, { FC, memo } from "react";
import ShipAnimation from "./ShipAnimation";

const Ship: FC<{
  crushed?: boolean;
  width?: number;
  animateFire?: boolean;
  animateShip?: boolean;
}> = ({ crushed }) => {
  return <ShipAnimation crushed={!!crushed} />;
};

export default memo(Ship);
