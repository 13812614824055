import React, { useEffect, useState } from "react";
import LoaderLogo from "../../assets/images/load.gif";
import StarsContainer from "../../components/Stars";
import { useLoadGameService } from "../../config/store/services";
import { useGameState } from "../../config/store/state/app.state";
import { GAME_STATE } from "../../constants/interfaces/Game";
import {
  Container,
  LoadingContainer,
  AppLogoContainer,
  ProgressBarContainer,
  AppLogo,
  ProgressBar,
} from "./index.styled";

const Loader = () => {
  const [loading, setLoading] = useState(0);
  const [loadingDuration, setLoadingDuration] = useState<number>(0);
  const handleLoadGame = useLoadGameService();
  const gameState = useGameState();

  useEffect(() => {
    const count = setInterval(() => {
      setLoadingDuration((prevState) => prevState + 0.1);
    }, 100);

    return () => {
      clearInterval(count);
    };
  }, [loadingDuration, setLoadingDuration]);

  useEffect(() => {
    if (loading >= 90) {
      return;
    }

    const countdown = setInterval(() => {
      setLoading((prevState) => prevState + 10);
    }, 200);

    return () => {
      clearInterval(countdown);
    };
  }, [gameState.status.state, loading, setLoading]);

  useEffect(() => {
    if (loading < 90) {
      return;
    }

    const countdown = setInterval(() => {
      if (gameState.status.state !== GAME_STATE.INITIAL) {
        setLoading(100);
      } else {
        if (loading < 99) {
          setLoading((prevState) => prevState + 0.5);
        }
      }
    }, 900);

    return () => {
      clearInterval(countdown);
    };
  }, [gameState.status.state, handleLoadGame, loading]);

  useEffect(() => {
    if (loading >= 100) {
      const timeout = setTimeout(() => {
        handleLoadGame(loadingDuration);
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleLoadGame, loading]);

  return (
    <Container landscapeHeight={window.innerHeight}>
      <StarsContainer disabled={true} />

      <LoadingContainer>
        <AppLogoContainer>
          <AppLogo src={LoaderLogo} alt="app image" />
        </AppLogoContainer>

        <ProgressBarContainer>
          <ProgressBar width={loading} />
        </ProgressBarContainer>
      </LoadingContainer>
    </Container>
  );
};

export default Loader;
