import React, { FC } from "react";
import styled from "styled-components";

const TBody = styled.div``;

export const TableBody: FC<{
  className?: string;
}> = ({ children, className }) => {
  return <TBody className={className}>{children}</TBody>;
};
