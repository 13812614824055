import React from "react";
import { AuthLayout, AuthSubmit, AuthTitle, RegistrationContainer } from "./styled";
import Button from "../../components/Button/Button";

const Verify = () => {
  return (
    <AuthLayout>
      <AuthTitle>Verify E-mail</AuthTitle>
      <RegistrationContainer>
        <div className="input">
            <label>E-mail</label>
            <input type="email" placeholder="Type your email" />
        </div>
      </RegistrationContainer>
      <AuthSubmit>
        <Button text="Verify"></Button>
      </AuthSubmit>
    </AuthLayout>
  );
};

export default Verify;
