import React, { FC, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import BetsModal from "../BetsModal";
import { GameHistory } from "../../constants/interfaces/GameHistory";
import { roundNumber } from "../../helpers/functions/round-number";

const StyledResultBox = styled(motion.div) <{ color?: string }>`
  display: inline-block;
  position: relative;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  padding: 4px 8px;
  border: 2px solid #6480cd;
  color: #6480cd;
  font-size: 14px;
  line-height: 14px;
  height: 30px;
  margin: 0px 3px 0;
  min-width: 52px;
  width: auto;
  font-weight: 700;
  text-align: center;
  cursor: pointer;

  &.btn-green-coeffc {
    // color: #70FF03;
    // border-color: #70FF03;
  }

  &.btn-yellow-coeffc {
    color: #70FF03;
    border-color: #70FF03;
  }

  &.coeffc-btn-abs {
    margin-bottom: 8px;
    display: flex;
  }

  @media (min-width: ${1366}px) {
    font-size: 14px;
    line-height: 16px;
    height: 32px;
    padding: 8px;
    margin: 0px 5.5px 1px;
    min-width: 70px;
    width: auto;
    text-align: center;

    &.coeffc-btn-abs {
      margin-bottom: 12px;
    }
  }
`;

const Text = styled.div`
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const ResultBox: FC<{ game: GameHistory; historyOpen: boolean }> = ({
  game,
  historyOpen,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && (
        <BetsModal
          closeModal={() => setOpen(false)}
          bustedAt={game.BustedAt}
          gameId={game.ID}
          hashCode={game.CrashHash}
          hashKey={game.CrashSource}
        />
      )}

      <StyledResultBox
        initial={{ y: -10 }}
        animate={{ x: 0, y: 0 }}
        onClick={() => setOpen(true)}
        className={`${game.BustedAt > 5 ? "btn-yellow-coeffc" : "btn-green-coeffc"
          } ${historyOpen ? "coeffc-btn-abs" : ""}`}
      >
        <Text>{roundNumber(game.BustedAt)}x</Text>
      </StyledResultBox>
    </>
  );
};

export default ResultBox;
