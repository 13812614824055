import React, { FC } from "react";
import { IconProps } from "./types";

export const Security: FC<IconProps> = ({ className }) => {
  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.8795 4.87287C15.8695 4.36187 15.8605 3.87887 15.8605 3.41287C15.8605 3.2373 15.7907 3.06891 15.6666 2.94476C15.5424 2.82062 15.374 2.75087 15.1985 2.75087C13.9521 2.80994 12.7069 2.613 11.5396 2.17218C10.3723 1.73137 9.30767 1.05604 8.41147 0.187869C8.28785 0.0674123 8.12207 0 7.94947 0C7.77687 0 7.61109 0.0674123 7.48747 0.187869C6.59137 1.05584 5.52691 1.73097 4.35978 2.17161C3.19265 2.61225 1.9476 2.80905 0.701467 2.74987C0.525893 2.74987 0.357512 2.81962 0.233363 2.94376C0.109214 3.06791 0.0394679 3.2363 0.0394679 3.41187C0.0394679 3.87887 0.0304669 4.36187 0.0204669 4.87287C-0.0705331 9.62687 -0.194532 16.1389 7.73347 18.8869C7.87402 18.9356 8.02691 18.9356 8.16747 18.8869C16.0945 16.1389 15.9705 9.62687 15.8795 4.87287Z"
        fill="#52b05a"
      />
      <path
        d="M10.2301 7.16086L7.03012 10.3609L5.67012 8.99286C5.54587 8.86861 5.37734 8.7988 5.20162 8.7988C5.0259 8.7988 4.85738 8.86861 4.73312 8.99286C4.60887 9.11711 4.53906 9.28564 4.53906 9.46136C4.53906 9.63708 4.60887 9.8056 4.73312 9.92986L6.56612 11.7629C6.62761 11.8244 6.70064 11.8733 6.78103 11.9066C6.86142 11.94 6.94759 11.9571 7.03462 11.9571C7.12165 11.9571 7.20783 11.94 7.28822 11.9066C7.36861 11.8733 7.44163 11.8244 7.50312 11.7629L11.1681 8.09785C11.2296 8.03633 11.2784 7.96329 11.3117 7.8829C11.345 7.80252 11.3622 7.71636 11.3622 7.62936C11.3622 7.54235 11.345 7.45619 11.3117 7.37581C11.2784 7.29542 11.2296 7.22238 11.1681 7.16086C11.1066 7.09933 11.0336 7.05053 10.9532 7.01723C10.8728 6.98394 10.7866 6.9668 10.6996 6.9668C10.6126 6.9668 10.5265 6.98394 10.4461 7.01723C10.3657 7.05053 10.2926 7.09933 10.2311 7.16086H10.2301Z"
        fill="white"
      />
    </svg>
  );
};

export default Security;
