import React, { ChangeEvent, FC, useEffect, useState } from "react";
import styled from "styled-components";
import { rgba } from "polished";
import ClickAwayListener from "../../../helpers/hooks/clickawayListener";
import { DebounceInput } from "react-debounce-input";
import { useGetTrendingGifs, useSearchGifs } from "./api";
import { SendMessageParams } from "../store/services";

const GifArea = styled.div`
  margin-right: 8px;
  padding: 2px 0;
  display: flex;
  align-items: center;
  position: relative;
`;

const GifIconContainer = styled.div`
  color: ${(props) => rgba(props.theme.colors.white, 0.2)};
  width: 20px;
  height: 20px;
  cursor: pointer;
  user-select: none;
  &:active {
    opacity: 0.7;
  }
`;

const GifIcon = styled.div`
  height: 16px;
  width: 24px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid ${(props) => rgba(props.theme.colors.white, 0.54)};
  color: ${(props) => rgba(props.theme.colors.white, 0.54)};
`;

const GifsContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  height: 365px;
  position: absolute;
  bottom: 34px;
  width: 270px;
  left: -40px;
  border-radius: 4px;
  background: #dadada14 0 0 no-repeat padding-box;
  backdrop-filter: blur(10px);
  padding: 10px;
  flex-wrap: wrap;
`;

const SearchContainer = styled.div`
  width: 100%;
`;

const SearchInput = styled(DebounceInput)`
  width: 100%;
  box-sizing: border-box;
  padding: 4px;
  background-color: #222539;
  border: 1px solid #3f4052;
  border-radius: 4px;
  color: #ffffffd6;
  margin-bottom: 8px;
`;

const GifContent = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

const StyledGifs = styled.img`
  margin: 2px;
  cursor: pointer;
  &:active {
    opacity: 0.8;
  }
`;

const ChatGifs: FC<{
  value: string;
  sendGif(message: Partial<SendMessageParams>): void;
}> = ({ sendGif }) => {
  const [openGifs, setOpenGifs] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [gifs, setGifs] = useState([]);

  const getTrendingGifs = useGetTrendingGifs();
  const searchGifs = useSearchGifs();

  useEffect(() => {
    if (searchValue.length > 0) {
      searchGifs(searchValue).then((data) => {
        setGifs(data);
      });
    } else {
      getTrendingGifs().then((data) => {
        setGifs(data);
      });
    }
  }, [getTrendingGifs, searchGifs, searchValue]);

  return (
    <GifArea>
      <GifIconContainer onMouseDown={() => setOpenGifs(!openGifs)}>
        <GifIcon>GIF</GifIcon>
      </GifIconContainer>
      {openGifs && (
        <ClickAwayListener onClickAway={() => setOpenGifs(false)}>
          <GifsContainer>
            <SearchContainer>
              <SearchInput
                debounceTimeout={300}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSearchValue(e.target.value)
                }
                placeholder={"Search..."}
              />
            </SearchContainer>
            <GifContent>
              {gifs.map((gif: { images: any }, index) => {
                return (
                  <StyledGifs
                    key={index}
                    src={`${gif?.images?.fixed_width_downsampled?.url}`}
                    alt="gif"
                    onClick={() => {
                      sendGif({
                        Text: gif?.images?.fixed_width_downsampled?.url,
                      });
                      setOpenGifs(false);
                    }}
                  />
                );
              })}
            </GifContent>
          </GifsContainer>
        </ClickAwayListener>
      )}
    </GifArea>
  );
};

export default ChatGifs;
