import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import {
    likeChatMessageAction,
    receiveChatMessageAction,
    receiveSystemChatMessageAction,
    sendChatMessageAction,
    toggleOpenChatAction,
} from './actions';
import { Player } from '../../../constants/interfaces/Player';
import { useMixpanelServices } from '../../../services/mixpanel/MixpanelServices';
import { EMixpanelEvent } from '../../../services/mixpanel/types';

export const useToggleOpenChatService = (): any => {
    const dispatch = useDispatch();
    const { track } = useMixpanelServices();
    return useCallback(
        (open: boolean) => {
            track(open ? EMixpanelEvent.openChat : EMixpanelEvent.closeChat);
            dispatch(toggleOpenChatAction(open));
        },
        [dispatch, track]
    );
};

export interface SendMessageParams {
    Message: 'SendMessage';
    Text: string;
}

export const useSendMessageService = (): any => {
    const dispatch = useDispatch();
    return useCallback(
        (data: SendMessageParams) => {
            dispatch(sendChatMessageAction(data));
        },
        [dispatch]
    );
};

export const useLikeChatMessageService = (): any => {
    const dispatch = useDispatch();
    return useCallback(
        (MID: number) => {
            dispatch(likeChatMessageAction(MID));
        },
        [dispatch]
    );
};

export interface ReceiveMessageParams {
    Text: string;
    Player: Player;
    MID: string;
    Timestamp: string;
    Liked: boolean;
    LikeCount: number;
}

export const useReceiveMessageService = (): any => {
    const dispatch = useDispatch();
    return useCallback(
        (data: ReceiveMessageParams) => {
            dispatch(receiveChatMessageAction(data));
        },
        [dispatch]
    );
};

export interface ReceiveSystemMessageParams {
    Text: string;
    Player: Player;
    MID: string;
    Timestamp: string;
    Liked: boolean;
    LikeCount: number;
    GameHash: string;
    Salt: string;
    Multiplier: number;
    CrashedAt: number;
    WonAmount: number;
    BuyIn: number;
}

export const useReceiveSystemMessageService = (): any => {
    const dispatch = useDispatch();
    return useCallback(
        (data: ReceiveSystemMessageParams) => {
            dispatch(receiveSystemChatMessageAction(data));
        },
        [dispatch]
    );
};

export interface UpdateMessageLikeParams {
    LikedMessageId: string;
    MID: string;
    Timestamp: string;
    Liked: boolean;
    LikeCount: number;
}
