import React, { FC } from "react";
import DropDown from "../../../../components/DropDown/DropDown";
import {
  TOP_LIST_FILTER_INDEXES,
  TOP_LIST_INDEXES,
  TopListFilters,
  TopListItems,
} from "../configs/tools";
// import useTranslate from "../../../../config/language";

import {
  StyledTopBoardActions,
  DropDownWrapper,
  Divider,
} from "./TopBestActions.styles";

const TopBetsActions: FC<{
  active: number;
  setActive(value: TOP_LIST_INDEXES): void;
  activeFilter: string;
  setActiveFilter(value: TOP_LIST_FILTER_INDEXES): void;
}> = ({ active, setActive, activeFilter, setActiveFilter }) => {
  // const translate = useTranslate();

  return (
    <>
      <StyledTopBoardActions>
        <DropDownWrapper>
          <DropDown
            items={TopListItems}
            selected={active}
            selectItem={setActive}
          />
        </DropDownWrapper>
        <Divider />
        <DropDownWrapper>
          <DropDown
            items={TopListFilters}
            selected={activeFilter}
            selectItem={setActiveFilter}
          />
        </DropDownWrapper>
      </StyledTopBoardActions>
    </>
  );
};

export default TopBetsActions;
