import React, { memo, useState, useRef, useCallback } from "react";
import { useGameHistory } from "../../config/store/state/app.state";
import ResultBox from "../../components/ResultBox/ResultBox";
import { useIsMobile } from "../../helpers/hooks/useIsMobile";
import { StyledOddsList, Items, Wrap, IconsContainer } from "./styles";
// import ArrowUp from "../../components/Icons/ChevronUp";
import useOnClickOutside from "../../helpers/hooks/on-click-outside";

const OddsList = () => {
  const nodeRef = useRef<any>();
  const gameHistory = useGameHistory();
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);

  const historyItemsToShow = gameHistory.slice(0, isMobile ? 20 : 40);

  const onClickOutside = useCallback(() => {
    if (open) {
      setOpen(false);
    }
  }, [open]);

  useOnClickOutside(nodeRef, onClickOutside);

  return (
    <StyledOddsList className={open ? "open-bar" : ""} ref={nodeRef}>
      <Items>
        <Wrap className={open ? "coeffc-wrap-abs" : ""}>
          {historyItemsToShow.map((game) => {
            return <ResultBox key={game.ID} game={game} historyOpen={open} />;
          })}
        </Wrap>
      </Items>

      <IconsContainer onClick={() => setOpen(!open)}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.2539 10C16.2539 14.1421 12.8952 17.5 8.75195 17.5C4.60874 17.5 1.25 14.1421 1.25 10C1.25 5.85786 4.60874 2.5 8.75195 2.5C11.5287 2.5 13.9531 4.00824 15.2503 6.25M14.1959 10.823L16.0714 8.94805L17.9469 10.823M11.5625 12.0451L8.75 11.1076V7.1875"
            stroke={open ? "#fff" : "#4E67A7"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </IconsContainer>
    </StyledOddsList>
  );
};

export default memo(OddsList);
