import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import ActionPanelNavigation from "./components/ActionPanelNavigation";
import { ACTION_PANEL_NAVIGATION } from "./tools";
// import ErrorMessages from "../errorMessages/ErrorMessages";
// import { useErrorMessagesState } from "../errorMessages/store/state";
import AutoBoardContainer from "./components/AutoBoard";
import {
  RegisterState,
  useBoards,
  useFreeBetsState,
} from "../../config/store/state/app.state";
import FreeBetBoardContainer from "./components/FreeBetBoard";
import ActionBoardContainer from "./components/ActionBoard";
import useTranslate from "../../config/language";
import { KEYWORDS } from "../../config/language/keywords";
import { useToggleEnableFreeBetsService } from "../../config/store/services";

const StyledActionPanel = styled.div`
  position: relative;
`;

// const ActionPanelTop = styled.div`
//   position: absolute;
//   top: 7px;
//   display: flex;
//   justify-content: space-between;
//   width: 100%;
//   left: 0;
//   padding: 0 4px;

//   span {
//     color: #456197;
//   }

//   p {
//     color: #24396;
//     display: inline;
//     padding-left: 8px;
//   }

//   img {
//     height: 20px;
//   }
// `

const ActionPanelBottom = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  font-size: 12px;
  color: #334466;
  align-items: center;

  span {
    display: flex;
  }
`

const StyledBody = styled.div`
  display: flex;
  justify-content: center;

  padding: 8px 0 0 0;

  box-sizing: border-box;
  height: 208px;

  @media (max-height: ${550}px) {
    height: 176px;
  }

  @media (min-width: ${2800}px) {
    padding: 32px 0 24px 0;
  }

  @media (max-width: ${2074}px) {
    height: auto;
    flex-direction: column;
  }
`;

const ReturnToCash = styled.div<{ disabled: boolean }>`
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  text-decoration: underline;
  letter-spacing: 0;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.gray : theme.colors.brightSkyBlue};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  font-weight: bold;
  font-size: 16px;
  margin-top: 8px;
`;

const ActionPanel = () => {
  const freeBetsEnabled = useFreeBetsState();
  const translate = useTranslate();

  const [active, setActive] = useState<ACTION_PANEL_NAVIGATION>(
    ACTION_PANEL_NAVIGATION.MANUAL
  );

  useEffect(() => {
    if (freeBetsEnabled) {
      setActive(ACTION_PANEL_NAVIGATION.MANUAL);
    }
  }, [freeBetsEnabled]);

  // const messages = useErrorMessagesState();
  const boards = useBoards();

  const disabled =
    boards.filter((board) => board.registerState !== RegisterState.UNREGISTERED)
      .length > 0;

  const handleToggleFreeBets = useToggleEnableFreeBetsService();

  return (
    <StyledActionPanel>
      {active === ACTION_PANEL_NAVIGATION.MANUAL && freeBetsEnabled && (
        <ReturnToCash
          disabled={disabled}
          onClick={() => {
            !disabled && handleToggleFreeBets(false);
          }}
        >
          {translate(KEYWORDS.ReturnToCash)}
        </ReturnToCash>
      )}

      <StyledBody>
        {active === ACTION_PANEL_NAVIGATION.MANUAL &&
          (freeBetsEnabled ? (
            <FreeBetBoardContainer />
          ) : (
            <ActionBoardContainer />
          ))}
        {active === ACTION_PANEL_NAVIGATION.AUTO && <AutoBoardContainer />}
      </StyledBody>
      
      <ActionPanelBottom>
        <span>809 <p>online</p></span>
        <span>Provably Fair Protected</span>
        <span>1,256 <p>bets</p></span>
      </ActionPanelBottom>

    </StyledActionPanel>
  );
};

export default ActionPanel;
