import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Plus from "../../../../components/Icons/Plus";
import Minus from "../../../../components/Icons/Minus";
import {
  useCashOutService,
  usePendingService,
  useRegisterService,
  useUnregisterLocalService,
  useUnregisterService,
} from "../../../../config/store/services";
import {
  RegisterState,
  useErrorsState,
  useGameConfigs,
  useGameStatusState,
  usePanelState,
} from "../../../../config/store/state/app.state";
import { GAME_STATE } from "../../../../constants/interfaces/Game";
import RegisterPanel from "./panels/RegisterPanel";
import UnregisterPanel from "./panels/UnregisterPanel";
import CashOutPanel from "./panels/CashOutPanel";
import AutoBetPanel from "./AutoBetPanel";
// import SelectButtonActions from "./components/SelectButtonActions";
import { useBalance } from "../../../profile/configs/store/state";
import { useShowMessage } from "../../../errorMessages/store/services";
import { KEYWORDS } from "../../../../config/language/keywords";
import {
  calculateMultiplierGrow,
  getFinalMultiplier,
  MULTIPLIER_DELAY,
} from "../../../game/components/GameMultiplier";
import { useMixpanelServices } from "../../../../services/mixpanel/MixpanelServices";
import { EMixpanelEvent } from "../../../../services/mixpanel/types";
import { usePlayButtonSoundService } from "../../../audioPlayer/store/services";

const StyledActionBoard = styled.div`
  flex: 1;
  box-sizing: border-box;
  margin-bottom: 8px;
  background: #192441;
  border-radius: 12px;
  padding: 8px;

  &:nth-child(1) {
    margin-bottom: 12px;
  }

  @media (min-width: ${2800}px) {
    width: 50%;

    &:nth-child(1) {
      padding-right: 40px;
      margin-bottom: 0;
    }

    &:nth-child(2) {
      padding-left: 40px;
      border-left: 2px solid rgba(255, 255, 255, 0.05);
    }
  }
`;

const SecondRow = styled.div`
  display: flex;
  margin-top: 10px;
  height: 58px;
`;

const ThirdRow = styled.div`
  display: flex;
  height: 54px;
  @media (max-height: ${550}px), (max-width: ${1100}px) {
    height: 45px;
  }
`;

// Board Configuration
const MIN_VALUE = 1;
const MAX_VALUE = 500;

const MIN_AUTO_CASH_OUT_VALUE = 1.1;
const MAX_AUTO_CASH_OUT_VALUE = 100;

const ActionBoard: FC<{
  activeBoards: number;
  boardIndex: number;
  addBoard(): void;
  removeBoard(): void;
  delay?: number;
}> = ({ activeBoards, boardIndex, addBoard, removeBoard, delay = 0 }) => {
  const configs = useGameConfigs();
  const displayAdd = activeBoards < 2;
  const state = usePanelState().filter(
    (board) => board.PanelIndex === boardIndex
  )[0];
  const gameStatus = useGameStatusState();
  const multiplier = gameStatus ? gameStatus.multiplier || 1 : 1;
  const registerService = useRegisterService();
  const pendingService = usePendingService();
  const unregisterService = useUnregisterService();
  const unregisterLocalService = useUnregisterLocalService();
  const cashOutService = useCashOutService();
  const registerState = state && state.registerState;

  const showMessage = useShowMessage();
  const balance = useBalance();

  const [amount, setAmount] = useState<number>(1);
  const [autoBet, setAutoBet] = useState(false);
  const [autoBetEnabled, setAutoBetEnabled] = useState(false);

  const [enableAutoCashOut, setEnableAutoCashOut] = useState(false);
  const [autoCashOut, setAutoCashOut] = useState(2);

  const sound = usePlayButtonSoundService();

  const handleRegister = useCallback(() => {
    showMessage(KEYWORDS.SuccessfulRegistration, "success");
    console.log(amount, 'amount')
    registerService({
      BuyIn: amount,
      AutoCashoutOn: enableAutoCashOut ? autoCashOut : null,
      IsAutoBet: autoBet,
      PanelIndex: boardIndex,
      BalanceType: 1,
    });
  }, [showMessage, amount, registerService, enableAutoCashOut, autoCashOut, autoBet, boardIndex]);

  const handlePending = useCallback(() => {
    showMessage(KEYWORDS.WaitingForNextRound, "success");
    console.log('autoBet', autoBet)
    pendingService({
      BuyIn: amount,
      AutoCashoutOn: enableAutoCashOut ? autoCashOut : null,
      IsAutoBet: autoBet,
      PanelIndex: boardIndex,
      BalanceType: 1,
    });
  }, [
    showMessage,
    pendingService,
    amount,
    enableAutoCashOut,
    autoCashOut,
    autoBet,
    boardIndex,
  ]);

  const handleInitRegister = useCallback(() => {
    if (amount > balance.WalletBalance) {
      showMessage(KEYWORDS.NotEnoughMoney, "error");
      if (autoBet) {
        setAutoBetEnabled(false);
      }
      return;
    }
    if (gameStatus && gameStatus.state !== GAME_STATE.COUNTDOWN) {
      handlePending();
    } else {
      handleRegister();
    }
  }, [
    gameStatus,
    handlePending,
    handleRegister,
    showMessage,
    amount,
    autoBet,
    balance.WalletBalance,
  ]);

  const handleClickRegister = useCallback(() => {
    if (autoBet) {
      setAutoBetEnabled(true);
    }

    if (configs.voiceEffects) {
      sound();
    }

    handleInitRegister();
  }, [autoBet, configs.voiceEffects, handleInitRegister, sound]);

  const handleClickUnregister = useCallback(() => {
    if (autoBetEnabled) {
      setAutoBetEnabled(false);
    }
    if (configs.voiceEffects) {
      sound();
    }
    if (registerState === RegisterState.PENDING) {
      unregisterLocalService({
        PanelIndex: boardIndex,
      });
    } else {
      unregisterService({
        PanelIndex: boardIndex,
      });
    }
  }, [
    autoBetEnabled,
    configs.voiceEffects,
    registerState,
    sound,
    unregisterLocalService,
    boardIndex,
    unregisterService,
  ]);

  const { track } = useMixpanelServices();

  const handleClickCashOut = useCallback(() => {
    // if (configs.voiceEffects) {
    //   winSound();
    // }

    track(EMixpanelEvent.cashout, {
      Board: "Main",
      Multiplier: multiplier,
      BetAmount: amount,
      Won: amount * multiplier,
    });
    cashOutService({
      PanelIndex: boardIndex,
      Won: amount * multiplier,
      Multiplier: multiplier,
    });
  }, [track, multiplier, amount, cashOutService, boardIndex]);

  const handleCheckBalance = useCallback(
    (value: number) => {
      if (value > balance.WalletBalance) {
        return balance.WalletBalance;
      } else {
        return value;
      }
    },
    [balance.WalletBalance]
  );

  const handleValueChange = useCallback(
    (value: number | null) => {
      if (!value) return;
      value = Math.round(value * 100) / 100;
      value = handleCheckBalance(value);

      if (value < MIN_VALUE) {
        setAmount(MIN_VALUE);
        return;
      }
      if (value > MAX_VALUE) {
        setAmount(MAX_VALUE);
        return;
      }
      setAmount(value);
    },
    [setAmount, handleCheckBalance]
  );

  const handleAutoCashOutValueChange = useCallback(
    (value: number | null) => {
      if (!value) return;
      if (value < MIN_AUTO_CASH_OUT_VALUE) {
        setAutoCashOut(MIN_AUTO_CASH_OUT_VALUE);
        return;
      }
      if (value > MAX_AUTO_CASH_OUT_VALUE) {
        setAutoCashOut(MAX_AUTO_CASH_OUT_VALUE);
        return;
      }
      setAutoCashOut(value);
    },
    [setAutoCashOut]
  );

  useEffect(() => {
    let timer: any;
    if (state.registerState && gameStatus.state) {
      if (
        state.registerState === RegisterState.PENDING &&
        gameStatus.state === GAME_STATE.COUNTDOWN
      ) {
        timer = setTimeout(() => {
          handleRegister();
        }, delay);
      }
      if (
        state.registerState === RegisterState.UNREGISTERED &&
        autoBetEnabled
      ) {
        handleInitRegister();
      }
    }

    return () => {
      clearTimeout(timer);
    };
  }, [
    handleInitRegister,
    state.registerState,
    gameStatus.state,
    handleRegister,
    autoBetEnabled,
    delay,
  ]);

  // BEGIN TEMP LOCAL CALCULATIONS
  const gameError = useErrorsState().isError;
  const gameState = useGameStatusState().state;
  const gameCrushed = gameState === GAME_STATE.CRUSHED;

  const [localMultiplier, setLocalMultiplier] = useState(1);

  useEffect(() => {
    setLocalMultiplier(multiplier || 1);
  }, [multiplier]);

  useEffect(() => {
    if (gameState === GAME_STATE.RUNNING) {
      const interval = setInterval(() => {
        setLocalMultiplier((prevValue) => calculateMultiplierGrow(prevValue));
      }, MULTIPLIER_DELAY);

      return () => {
        clearInterval(interval);
      };
    }
  }, [multiplier, gameState, setLocalMultiplier]);

  let finalMultiplayer = getFinalMultiplier(
    gameCrushed,
    gameError,
    localMultiplier,
    multiplier
  );
  // END TEMP LOCAL CALCULATIONS

  const handleRegisterStateView = () => {
    switch (registerState) {
      case RegisterState.UNREGISTERED: {
        return (
          <RegisterPanel
            amount={amount}
            setAmount={handleValueChange}
            toggleBoard={displayAdd ? addBoard : removeBoard}
            handleRegister={handleClickRegister}
            icon={displayAdd ? Plus : Minus}
            minValue={MIN_VALUE}
            maxValue={MAX_VALUE}
          />
        );
      }
      case RegisterState.REGISTER_INITIATED:
      case RegisterState.CASH_OUT_INITIATED:
      case RegisterState.REGISTERED: {
        if (gameStatus.state === GAME_STATE.COUNTDOWN) {
          return (
            <UnregisterPanel
              amount={amount}
              disabled={registerState === RegisterState.REGISTER_INITIATED}
              handleUnregister={handleClickUnregister}
            />
          );
        } else {
          return (
            <CashOutPanel
              amount={amount * finalMultiplayer}
              disabled={registerState === RegisterState.CASH_OUT_INITIATED}
              handleCashOut={handleClickCashOut}
              handleUnregister={handleClickUnregister}
            />
          );
        }
      }
      case RegisterState.UNREGISTER_INITIATED:
      case RegisterState.PENDING: {
        return (
          <UnregisterPanel
            amount={amount}
            disabled={registerState === RegisterState.UNREGISTER_INITIATED}
            handleUnregister={handleClickUnregister}
          />
        );
      }
      default: {
        return (
          <RegisterPanel
            amount={amount}
            setAmount={handleValueChange}
            toggleBoard={displayAdd ? addBoard : removeBoard}
            handleRegister={handleClickRegister}
            icon={displayAdd ? Plus : Minus}
          />
        );
      }
    }
  };

  const disableActions = registerState !== RegisterState.UNREGISTERED;

  return (
    <StyledActionBoard>
      <ThirdRow>
        <AutoBetPanel
          autoBet={autoBet}
          setAutoBet={setAutoBet}
          enableAutoCashOut={enableAutoCashOut}
          setEnableAutoCashOut={setEnableAutoCashOut}
          autoCashOut={autoCashOut}
          setAutoCashOut={handleAutoCashOutValueChange}
          minValue={MIN_AUTO_CASH_OUT_VALUE}
          maxValue={MAX_AUTO_CASH_OUT_VALUE}
          panelDisabled={disableActions}
        />
      </ThirdRow>

      <SecondRow>{handleRegisterStateView()}</SecondRow>
    </StyledActionBoard>
  );
};

export default ActionBoard;
