import { GameStatus } from "../../../constants/interfaces/Game";
import { NextGame } from "../../../constants/interfaces/NextGame";
import { useSelector, shallowEqual } from "react-redux";
import { ListUser } from "../../../constants/interfaces/List";
import { MyList } from "../../../constants/interfaces/MyList";
import { TopList } from "../../../constants/interfaces/TopList";
import { GameHistory } from "../../../constants/interfaces/GameHistory";
import { Profile } from "../../../constants/interfaces/Profile";
import {
  SelectBoardsState,
  SelectCurrentBetsState,
  SelectErrorsState,
  SelectFreeBetsState,
  SelectGameConfigsState,
  SelectGameHistoryState,
  SelectGameState,
  SelectGameStatusState,
  SelectNextGamesState,
  SelectPanelState,
  SelectPreviousBetsState,
} from "../selectors";
import { ErrorListNames } from "../../../components/Modals/ErrorList";
import { ITenant } from "../../../constants/interfaces/Tenant";
import { GameConfig } from "../../../features/actionPanel/configs/actions";

export interface GameConfigs {
  animate: boolean;
  backgroundMusic: boolean;
  voiceEffects: boolean;
}

export enum RegisterState {
  REGISTER_INITIATED = "REGISTER_INITIATED",
  REGISTERED = "REGISTERED",
  UNREGISTER_INITIATED = "UNREGISTER_INITIATED",
  UNREGISTERED = "UNREGISTERED",
  CASH_OUT_INITIATED = "CASH_OUT_INITIATED",
  PENDING = "PENDING",
}

export interface PanelState {
  PanelIndex: number;
  registerState: RegisterState;
  BuyIn?: number;
  AutoCashoutOn?: null | number;
  IsAutoBet?: boolean;
  BalanceType?: number;
  wonLastGame?: boolean;
}

export interface ErrorState {
  isError: boolean;
  type: ErrorListNames;
}

export interface GameState {
  minMaxValue: GameConfig;
  loading: boolean;
  status: GameStatus;
  nextGames?: NextGame[];
  currentBets: ListUser[];
  gameHistory: GameHistory[];
  lastGame?: GameHistory;
  lastBets?: ListUser[];
  myBets?: MyList[];
  top: TopList;
  boards: PanelState[];
  configs: GameConfigs;
  profile: Profile;
  freeBetsEnabled: boolean;
  errors: ErrorState;
  currentGame?: {
    GameID: number;
    Hash: string;
  };
  tenant: ITenant;
}

export function useGameState(): GameState {
  return useSelector(SelectGameState, shallowEqual);
}

export function useGameStatusState(): GameStatus {
  return useSelector(SelectGameStatusState);
}

export function useCurrentBets(): ListUser[] {
  return useSelector(SelectCurrentBetsState);
}

export function usePreviousBets(): ListUser[] {
  return useSelector(SelectPreviousBetsState);
}

export function useGameConfigs(): GameConfigs {
  return useSelector(SelectGameConfigsState, shallowEqual);
}

export function usePanelState(): PanelState[] {
  return useSelector(SelectPanelState);
}

export function useGameHistory(): GameHistory[] {
  return useSelector(SelectGameHistoryState, shallowEqual);
}

export function useNextGames(): NextGame[] {
  return useSelector(SelectNextGamesState, shallowEqual);
}

export function useBoards(): PanelState[] {
  return useSelector(SelectBoardsState, shallowEqual);
}

export function useFreeBetsState(): boolean {
  return useSelector(SelectFreeBetsState, shallowEqual);
}

export function useErrorsState(): ErrorState {
  return useSelector(SelectErrorsState);
}
