import React, { FC } from "react";
import styled from "styled-components";
import RenderMyBetsTable from "./components/RenderMyBetsTable";
import { MyList } from "../../../constants/interfaces/MyList";

const MyBetsContainer = styled.div`
  .container:nth-child(1) {
    margin-top: 14px;
  }

  @media (min-width: ${1366}px) {
    .container:nth-child(1) {
      margin-top: 32px;
    }
  }
`;

const List: FC<{ items: MyList[] }> = ({ items }) => {
  return (
    <MyBetsContainer>
      <RenderMyBetsTable items={items} />
    </MyBetsContainer>
  );
};

export default List;
