import styled from "styled-components";
import { motion } from "framer-motion";

export const MultiplierContainer = styled(motion.div)`
  position: absolute;
  left: 0px;
  top: 8%;
  width: 100%;

  @media (max-width: ${992}px) {
    top: 7%;
    min-width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledMultiplier = styled(motion.div)`
  position: relative;
  color: #ffffff;
  margin-top: 0px;

  .game-counter-mlsec-x {
    text-align: center;
    margin-top: 2px;
  }

  .game-counter-sec {
    color: #fff;
    font-weight: 900;
    font-size: 48px;
    width: 100%;
    text-align: center;
    min-width: 180px;

    @media (min-width: ${2800}px) {
      font-size: 75px;
    }

    span {
      min-width: 125px;
      display: inline-block;
      text-align: right;
    }

    img {
      width: 65px;
      margin-left: -7px;
    }

    &.gameCrushed {
      color: #C9374C;
    }
  }

  .game-counter-x {
    @media (min-width: ${2800}px) {
      width: 62px;
      height: 29px;
    }
  }
`;
