import React, { FC, memo, useContext, useEffect } from "react";
import { Flying } from "../../spaceship/spaceship";
import { GameContext } from "../index";
import { useGameConfigs } from "../../../config/store/state/app.state";
import { SoundPlayer } from "../../soundPlayer/soundPlayer";

interface IProps {
  crushed?: boolean
}

const GameRunning: FC<IProps> = ({ crushed }) => {
  const { gameWidth } = useContext(GameContext);
  const voiceEffects = useGameConfigs().voiceEffects;

  useEffect(() => {
    if (!voiceEffects) {
      return;
    }
    const audio = new SoundPlayer();
    audio.playFlyingSound();
    return () => {
      audio.stopFlyingSound();
    };
  }, [voiceEffects]);

  return <Flying crushed={!!crushed} shipWidth={gameWidth / 1.62} />;
};

export default memo(GameRunning);
