import React, { useState } from "react";
import TableNavigation from "./components/TableNavigation";
import { LIST_INDEXES, SidebarNavigationItems } from "./tools";
import styled from "styled-components";
import BetsList from "./bets";
import MyBetsList from "./myBets";
import TopBetsList from "./topBets";
import { useMixpanelServices } from "../../services/mixpanel/MixpanelServices";
import { EMixpanelEvent } from "../../services/mixpanel/types";

const SidebarListContainer = styled.div``;

const SidebarListBody = styled.div``;

const SidebarList = () => {
  const [active, setActive] = useState(LIST_INDEXES.BETS);
  const { track } = useMixpanelServices();
  const handleSetActive = (index: number) => {
    setActive(index);
    switch (index) {
      case LIST_INDEXES.BETS:
        track(EMixpanelEvent.switchToTheBetts);
        break;
      case LIST_INDEXES.MY_BETS:
        track(EMixpanelEvent.switchToMyBetts);
        break;
      case LIST_INDEXES.TOP:
        track(EMixpanelEvent.switchToTopBetts);
        break;
    }
  };

  return (
    <SidebarListContainer>
      <TableNavigation
        items={SidebarNavigationItems}
        activeIndex={active}
        setActive={handleSetActive}
      />

      <SidebarListBody>
        {active === LIST_INDEXES.BETS && <BetsList />}
        {active === LIST_INDEXES.MY_BETS && <MyBetsList />}
        {active === LIST_INDEXES.TOP && <TopBetsList />}
      </SidebarListBody>
    </SidebarListContainer>
  );
};

export default SidebarList;
