import React, { FC } from "react";
import styled from "styled-components";

const StyledTable = styled.div`
  width: 100%;
`;

export const Table: FC<{ className?: string }> = ({ children, className }) => {
  return <StyledTable className={className}>{children}</StyledTable>;
};

export default Table;
