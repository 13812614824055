import React, { memo, useEffect, useState } from "react";
import Title from "./features/title";
import styled from "styled-components";
import ErrorModal from "./components/Modals";
import { useErrorsState } from "./config/store/state/app.state";
import HeaderSection from "./layout/Header";
import { usePingService } from "./config/store/services";
import { useMixpanelServices } from "./services/mixpanel/MixpanelServices";
import { useProfile } from "./features/profile/configs/store/state";
import { ITenant } from "./constants/interfaces/Tenant";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { SelectTenantState } from "./config/store/selectors";
import { useSentryServices } from "./services/sentry/SentryServices";
import { gameErrorAction } from "./config/store/actions/app.actions";
import { ErrorListNames } from "./components/Modals/ErrorList";
import SidebarList from "./features/topBoards";
import Sidebar from "./layout/Sidebar";
import Content from "./layout/Content";
import OddsList from "./features/oddsList";
import GameContainer from "./features/game";
import ActionPanel from "./features/actionPanel";
import { AudioPlayer } from "./features/audioPlayer";
import useBackgroundsMusic from "./components/BackgroundMusic";
import RotationIcon from "./components/Icons/RotationIcon";
import ConnectionModal from "./components/ConnectionModal";
import useConnectionModal from "./components/ConnectionModal/useConnectionModal";
import Chat from "./features/chat";
import StarsContainer from "./components/Stars";
import { getQuery } from "./helpers/hooks/useQuery";
import Registration from "./features/auth/registration";
import Login from "./features/auth/login";
import Verify from "./features/auth/verify";
import Success from "./features/auth/success";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  color: white;
  background: #141f35;
  position: relative;
  z-index: 2;
  font-family: "Montserrat", sans-serif;

  @media (min-width: 992px) {
    max-width: 768px;
    margin: 0 auto;
  }
`;

const InnerWrapper = styled.div<{
  landscapeWidth?: number;
  landscapeHeight?: number;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;
  overflow: hidden;

  @media screen and (min-device-width: 320px) and (max-device-width: 992px) and (orientation: landscape) {
    width: ${(props) => `${props.landscapeWidth}px`};
    height: ${(props) => `${props.landscapeHeight}px`};
  }
`;

const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 8px 8px 8px;
  background: #131b2d;

  @media (min-width: ${2800}px) {
    flex-direction: row;
    padding: 0 36px 36px 36px;
  }
`;

const LandscapeIconWrapper = styled.div`
  display: none;
  position: absolute;
  background: rgba(255, 255, 255, 0.24);
  backdrop-filter: blur(28px);
  width: 100%;
  height: 100%;
  z-index: 99;

  svg {
    position: absolute;
    transform: rotate(90deg);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media screen and (min-device-width: 320px) and (max-device-width: 992px) and (orientation: landscape) {
    display: block;
  }
`;

function useTenant(): ITenant {
  return useSelector(SelectTenantState, shallowEqual);
}

const Game = () => {
  const errors = useErrorsState();
  const { Player } = useProfile();
  const { identify } = useMixpanelServices();
  const { init } = useSentryServices();
  const tenant = useTenant();
  useBackgroundsMusic();
  const pingService = usePingService();
  const dispatch = useDispatch();
  const [networkModal, setNetworkModal] = useState(true);
  const { isOnline } = useConnectionModal({ networkModal, setNetworkModal });
  const page = getQuery(window.location.search, "page");
  // const messages = useErrorMessagesState();
  // const isMobile = useIsMobile();

  useEffect(() => {
    const ping = setInterval(() => {
      pingService();
    }, 10000);

    return () => {
      clearInterval(ping);
    };
  }, [pingService]);

  useEffect(() => {
    identify(
      {
        name: Player.N,
        id: `${Player.I}`,
      },
      tenant
    );
  }, [Player.I, Player.N, identify, tenant]);

  useEffect(() => {
    init(tenant);
  }, [init, tenant]);

  useEffect(() => {
    if (Player.B) {
      dispatch(gameErrorAction(ErrorListNames.BlockedUser));
    }
  }, [Player.B, dispatch]);

  return (
    <>
      {errors.isError && <ErrorModal type={errors.type} />}
      {networkModal && !isOnline && (
        <ConnectionModal close={() => setNetworkModal(false)} />
      )}

      <Chat />

      {page === 'register' && <Registration />}
      {page === 'login' && <Login />}
      {page === 'verify' && <Verify />}
      {page === 'success' && <Success />}

      <Title />
      <AudioPlayer />
      <div id="stars">
        <StarsContainer disabled={true} />
      </div>

      <Wrapper>
        <InnerWrapper
          landscapeWidth={window.innerHeight}
          landscapeHeight={window.innerWidth}
        >
          <LandscapeIconWrapper>
            <RotationIcon />
          </LandscapeIconWrapper>

          <HeaderSection />

          <Body>
            <Content>
              <OddsList />
              <GameContainer />
              <ActionPanel />
            </Content>

            <Sidebar>
              <SidebarList />
            </Sidebar>
          </Body>
        </InnerWrapper>
      </Wrapper>
    </>
  );
};

export default memo(Game);
