import styled from "styled-components";

export const TableRow = styled.tr`
background: #101529;
margin-bottom: 2px;
display: flex;
`;

export const StyledTd = styled.td`
  padding: 18px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:nth-child(1) {
    text-align: left;
  }

  &:nth-child(3) {
    text-align: right;
  }

  &.rating-date {
    width: 20%;
  }

  &.rating-coeffc {
    color: #52b05a;
    width: 6%;
  }

  &.rating-pf {
    width: 19%;
  }

  @media (max-width: ${1366}px) {
    padding: 16px;

    &.rating-date {
      width: 95%;
    }
  }

  @media (min-width: ${1366}px) {
    width: 40%;
    
    &.rating-date {
      width: 80%;
    }

    // &.rating-coeffc {
    //   width: 25%;
    // }

    // &.rating-pf {
    //   width: 15%;
    // }
  }
`;


export const InnerSpan = styled.span`
  color: rgba(255, 255, 255, 0.84);
  font-size: 12px;

  &.green {
    color: ${({ theme }) => theme.colors.frogGreen};
  }

  &.last {
    margin-left: 12px;
  }

  @media (min-width: ${1366}px) {
    font-size: 16px;
  }
`;
