import React, { FC } from "react";
import useTranslate from "../../../../../config/language";
import { KEYWORDS } from "../../../../../config/language/keywords";
import { roundNumber } from "../../../../../helpers/functions/round-number";
import { CURRENCY_SYMBOL } from "../../../../../constants/constants";
import { ButtonInput } from "../../../styles";

const UnregisterPanel: FC<{
  amount: number;
  handleUnregister(): void;
  disabled?: boolean;
}> = ({ amount, handleUnregister, disabled }) => {
  const translate = useTranslate();

  return (
    <ButtonInput
      text={`${translate(KEYWORDS.UnregisterFreeBet)} -${roundNumber(
        amount
      )}${CURRENCY_SYMBOL}`}
      onClick={handleUnregister}
      disabled={disabled}
      className="btn-cancel"
    />
  );
};

export default UnregisterPanel;
