import React, { FC } from "react";
import {
  useGameHistory,
  useNextGames,
} from "../../../../config/store/state/app.state";
import { GameHistory } from "../../../../constants/interfaces/GameHistory";
import ProvablyFairTable from ".";
import Modal from "../../../../components/Modal";
import InfoIcon from '../../../../assets/images/general/information-circle-contained.svg'
import { Container, MainTitle } from "./PFDataModal.styles";

const ProvablyFairDataModal: FC<{ back: () => void; close: () => void }> = ({
  back,
  close,
}) => {
  let nextGames = useNextGames();
  const gameHistory: Partial<GameHistory>[] = useGameHistory();

  const reversedNextGames: Partial<GameHistory>[] = [...nextGames]
    .reverse()
    .map((game) => {
      return {
        ID: game.GameID,
        CrashHash: game.Hash,
      };
    });
  const mergedArrays = reversedNextGames.concat(gameHistory.slice(0, 5));

  return (
    <Modal closeModal={close}>
      <Container>
        <MainTitle>
          <img alt="droid" src={InfoIcon} />
          PROVABLY FAIR
        </MainTitle>

        <ProvablyFairTable items={mergedArrays} />
      </Container>
    </Modal>
  );
};

export default ProvablyFairDataModal;
