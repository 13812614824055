import React, { FC, useCallback, useEffect, useState } from "react";
import Plus from "../../../../components/Icons/Plus";
import Minus from "../../../../components/Icons/Minus";
import {
  useCashOutService,
  usePendingService,
  useRegisterService,
  useToggleEnableFreeBetsService,
  useUnregisterLocalService,
  useUnregisterService,
} from "../../../../config/store/services";
import {
  RegisterState,
  useBoards,
  useErrorsState,
  useFreeBetsState,
  useGameStatusState,
  usePanelState,
} from "../../../../config/store/state/app.state";
import { GAME_STATE } from "../../../../constants/interfaces/Game";
import RegisterPanel from "./panels/RegisterPanel";
import UnregisterPanel from "./panels/UnregisterPanel";
import CashOutPanel from "./panels/CashOutPanel";
// import SelectButtonActions from "./components/SelectButtonActions";
import { useBalance } from "../../../profile/configs/store/state";
import {
  calculateMultiplierGrow,
  getFinalMultiplier,
  MULTIPLIER_DELAY,
} from "../../../game/components/GameMultiplier";
import { KEYWORDS } from "../../../../config/language/keywords";
import useTranslate from "../../../../config/language";
import { useShowMessage } from "../../../errorMessages/store/services";
import { useMixpanelServices } from "../../../../services/mixpanel/MixpanelServices";
import { EMixpanelEvent } from "../../../../services/mixpanel/types";
import { GameBoard, GameTabsNav, Nav, NavItem, Tab } from "../../styles";
import styled from "styled-components";
import ToggleInput from "../../../../components/ToggleInput/ToggleInput";

const ItemContainer = styled.div`
  position: absolute;
  top: -16px;
  left: -1px;
`;

const SwitchLab = styled.div`
  font-size: 8px;
  line-height: 10px;
  color: #ffffff;
  font-feature-settings: "case" on;
  display: inline-block;
  margin-right: 9px;
  vertical-align: middle;
`;

const MIN_VALUE = 0.1;
const MAX_VALUE = 500;

// const MIN_AUTO_CASH_OUT_VALUE = 1.5;
// const MAX_AUTO_CASH_OUT_VALUE = 50000;

const ActionBoard: FC<{
  activeBoards: number;
  boardIndex: number;
  BalanceType: number;
  addBoard(): void;
  removeBoard(): void;
  delay?: number;
}> = ({
  activeBoards,
  boardIndex,
  BalanceType,
  addBoard,
  removeBoard,
  delay = 0,
}) => {
  const translate = useTranslate();
  const displayAdd = activeBoards < 2;
  const state = usePanelState().filter(
    (board) => board.PanelIndex === boardIndex
  )[0];
  const gameStatus = useGameStatusState();
  const multiplier = gameStatus ? gameStatus.multiplier || 1 : 1;
  const registerService = useRegisterService();
  const pendingService = usePendingService();
  const unregisterService = useUnregisterService();
  const unregisterLocalService = useUnregisterLocalService();
  const cashOutService = useCashOutService();
  const registerState = state && state.registerState;

  const [amount, setAmount] = useState<number>(1);
  const [autoBet, setAutoBet] = useState(false);
  const [autoBetEnabled, setAutoBetEnabled] = useState(false);

  const freeBetsEnabled = useFreeBetsState();
  const handleToggleFreeBets = useToggleEnableFreeBetsService();

  // const [enableAutoCashOut, setEnableAutoCashOut] = useState(false);
  // const [autoCashOut, setAutoCashOut] = useState(2);

  // const [active, setActive] = useState(true);

  const showMessage = useShowMessage();
  const balance = useBalance();

  const handleRegister = useCallback(() => {
    registerService({
      BuyIn: amount,
      // AutoCashoutOn: enableAutoCashOut ? autoCashOut : null,
      IsAutoBet: autoBet,
      PanelIndex: boardIndex,
      BalanceType: BalanceType,
    });
  }, [registerService, amount, autoBet, boardIndex, BalanceType]);

  const handlePending = useCallback(() => {
    pendingService({
      BuyIn: amount,
      // AutoCashoutOn: enableAutoCashOut ? autoCashOut : null,
      IsAutoBet: autoBet,
      PanelIndex: boardIndex,
      BalanceType: BalanceType,
    });
  }, [amount, autoBet, boardIndex, BalanceType, pendingService]);

  const handleInitRegister = useCallback(() => {
    if (!balance.FreeSpinBalance || balance.FreeSpinBalance.length < 1) {
      showMessage(KEYWORDS.Failed, "error");
      if (autoBet) {
        setAutoBetEnabled(false);
      }
      return;
    }
    if (gameStatus && gameStatus.state !== GAME_STATE.COUNTDOWN) {
      handlePending();
    } else {
      handleRegister();
    }
  }, [
    autoBet,
    balance.FreeSpinBalance,
    gameStatus,
    handlePending,
    handleRegister,
    showMessage,
  ]);

  const handleClickRegister = useCallback(() => {
    if (autoBet) {
      setAutoBetEnabled(true);
    }

    handleInitRegister();
  }, [autoBet, setAutoBetEnabled, handleInitRegister]);

  const handleClickUnregister = useCallback(() => {
    if (autoBetEnabled) {
      setAutoBetEnabled(false);
    }
    if (registerState === RegisterState.PENDING) {
      unregisterLocalService({
        PanelIndex: boardIndex,
      });
    } else {
      unregisterService({
        PanelIndex: boardIndex,
      });
    }
  }, [
    boardIndex,
    autoBetEnabled,
    setAutoBetEnabled,
    registerState,
    unregisterLocalService,
    unregisterService,
  ]);

  const { track } = useMixpanelServices();

  const handleClickCashOut = useCallback(() => {
    track(EMixpanelEvent.cashout, {
      Board: "FreeBet",
      Multiplier: multiplier,
      BetAmount: amount,
      Won: amount * multiplier,
    });
    cashOutService({
      PanelIndex: boardIndex,
      Won: amount * multiplier,
      Multiplier: multiplier,
    });
  }, [track, multiplier, amount, cashOutService, boardIndex]);

  const handleValueChange = useCallback(
    (value: number | null) => {
      if (!value) return;
      if (value < MIN_VALUE) {
        setAmount(MIN_VALUE);
        return;
      }
      if (value > MAX_VALUE) {
        setAmount(MAX_VALUE);
        return;
      }
      setAmount(value);
    },
    [setAmount]
  );

  // const handleAutoCashOutValueChange = useCallback(
  //   (value: number | null) => {
  //     if (!value) return;
  //     if (value < MIN_AUTO_CASH_OUT_VALUE) {
  //       setAutoCashOut(MIN_AUTO_CASH_OUT_VALUE);
  //       return;
  //     }
  //     if (value > MAX_AUTO_CASH_OUT_VALUE) {
  //       setAutoCashOut(MAX_AUTO_CASH_OUT_VALUE);
  //       return;
  //     }
  //     setAutoCashOut(value);
  //   },
  //   [setAutoCashOut]
  // );

  const boards = useBoards();

  // Reset amount to next possible value if current
  // amount of free bet balance is unavailable
  useEffect(() => {
    const boardRegisterState = boards.filter(
      (board) => board.PanelIndex === boardIndex
    )[0].registerState;
    if (boardRegisterState === RegisterState.UNREGISTERED) {
      if (
        balance.FreeSpinBalance &&
        balance.FreeSpinBalance.filter((freeBet) => freeBet.Amount === amount)
          .length < 1 &&
        balance.FreeSpinBalance.length > 0
      ) {
        setAmount(balance.FreeSpinBalance[0].Amount);
      }
    }
  }, [amount, balance.FreeSpinBalance, boardIndex, boards]);

  // Handle auto bet
  useEffect(() => {
    let timer: any;
    if (state.registerState && gameStatus.state) {
      if (
        state.registerState === RegisterState.PENDING &&
        gameStatus.state === GAME_STATE.COUNTDOWN
      ) {
        timer = setTimeout(() => {
          handleRegister();
        }, delay);
      }
      if (
        state.registerState === RegisterState.UNREGISTERED &&
        autoBetEnabled
      ) {
        if (
          balance.FreeSpinBalance &&
          balance.FreeSpinBalance.filter((freeBet) => freeBet.Amount === amount)
            .length < 1 &&
          balance.FreeSpinBalance.length > 0
        ) {
          setAmount(balance.FreeSpinBalance[0].Amount);
          setAutoBet(false);
          setAutoBetEnabled(false);
        } else {
          handleInitRegister();
        }
      }
    }

    return () => {
      clearTimeout(timer);
    };
  }, [
    handleInitRegister,
    state.registerState,
    gameStatus.state,
    handleRegister,
    autoBetEnabled,
    balance,
    amount,
    boardIndex,
    delay,
  ]);

  // BEGIN TEMP LOCAL CALCULATIONS
  const gameError = useErrorsState().isError;
  const gameState = useGameStatusState().state;
  const gameCrushed = gameState === GAME_STATE.CRUSHED;

  const [localMultiplier, setLocalMultiplier] = useState(1);

  useEffect(() => {
    setLocalMultiplier(multiplier || 1);
  }, [multiplier]);

  useEffect(() => {
    if (gameState === GAME_STATE.RUNNING) {
      const interval = setInterval(() => {
        setLocalMultiplier((prevValue) => calculateMultiplierGrow(prevValue));
      }, MULTIPLIER_DELAY);

      return () => {
        clearInterval(interval);
      };
    }
  }, [multiplier, gameState, setLocalMultiplier]);

  let finalMultiplayer = getFinalMultiplier(
    gameCrushed,
    gameError,
    localMultiplier,
    multiplier
  );
  // END TEMP LOCAL CALCULATIONS

  const handleRegisterStateView = () => {
    switch (registerState) {
      case RegisterState.UNREGISTERED: {
        return (
          <RegisterPanel
            amount={amount}
            setAmount={handleValueChange}
            toggleBoard={displayAdd ? addBoard : removeBoard}
            handleRegister={handleClickRegister}
            icon={displayAdd ? <Plus /> : <Minus />}
            minValue={MIN_VALUE}
            maxValue={MAX_VALUE}
          />
        );
      }
      case RegisterState.REGISTER_INITIATED:
      case RegisterState.CASH_OUT_INITIATED:
      case RegisterState.REGISTERED: {
        if (gameStatus.state === GAME_STATE.COUNTDOWN) {
          return (
            <UnregisterPanel
              amount={amount}
              disabled={registerState === RegisterState.REGISTER_INITIATED}
              handleUnregister={handleClickUnregister}
            />
          );
        } else {
          return (
            <CashOutPanel
              amount={amount * finalMultiplayer}
              multiplier={multiplier}
              disabled={registerState === RegisterState.CASH_OUT_INITIATED}
              handleCashOut={handleClickCashOut}
            />
          );
        }
      }
      case RegisterState.UNREGISTER_INITIATED:
      case RegisterState.PENDING: {
        return (
          <UnregisterPanel
            amount={amount}
            disabled={registerState === RegisterState.UNREGISTER_INITIATED}
            handleUnregister={handleClickUnregister}
          />
        );
      }
      default: {
        return (
          <RegisterPanel
            amount={amount}
            setAmount={handleValueChange}
            toggleBoard={displayAdd ? addBoard : removeBoard}
            handleRegister={handleClickRegister}
            icon={displayAdd ? <Plus /> : <Minus />}
          />
        );
      }
    }
  };

  // const disableActions = registerState !== RegisterState.UNREGISTERED;

  return (
    <GameBoard>
      <GameTabsNav>
        <Nav>
          <NavItem>{translate(KEYWORDS.Game)}</NavItem>
          <NavItem>{translate(KEYWORDS.Auto)}</NavItem>
        </Nav>
      </GameTabsNav>

      <Tab>
        <ItemContainer>
          <SwitchLab>{translate(KEYWORDS.FreeBet)}</SwitchLab>

          <ToggleInput
            active={freeBetsEnabled}
            onChange={() => handleToggleFreeBets(false)}
          />
        </ItemContainer>
{/* 
        <SelectButtonActions
          disabled={disableActions}
          amount={amount}
          onChange={handleValueChange}
        /> */}

        {handleRegisterStateView()}
      </Tab>
    </GameBoard>
  );
};

export default ActionBoard;
