// import { CURRENCY_SYMBOL } from '../../../constants/constants';
const CURRENCY_SYMBOL = ''

export const turkish = {
    AccountBanned: 'Hesabınız engellenmiştir!',
    AllBets: 'Auto Bahis:',
    AlreadyRegistered: 'Bahis zaten yapmıştınız',
    Animation: 'Animasyon',
    AutoBet: 'OTOMATIK BAHIS',
    AutoBetting: 'Auto Bahis',
    AutoCashout: 'OTOMATIK PARA ÇEKME',
    AutoCashouting: 'Auto Bahis Bozdurma',
    Balance: 'BAKIYE',
    BalanceShort: 'BAL',
    Banned: 'KULLANICI ENGELLENMIŞTIR!',
    BaseBet: 'İLK BAHIS',
    Bet: 'BAHIS',
    BetMaximum: 'Bahis maksimum olmalıdır {}GEL',
    BetMustBe: 'Bahis en az olmalı',
    Bets: 'Bahisler',
    Boom: 'BOOM!',
    BustedJackpot: 'Kazanılmış jackpot',
    BuyIn: 'Bahis',
    Cashout: 'CASHOUT',
    CashoutFromGame: 'Cashout',
    CashoutMaxMustBe: 'Nakit Ödeme azami olmalıdır ',
    CashoutMustBe: 'Nakit Ödeme minimum olmalı 1.01',
    ChangeAvatar: 'Avatarı değiştir',
    Chat: 'SOHBET',
    ChatBlocked: 'Sohbetiniz engellenmiştir',
    CheckFair: '1. Oran doğrulaması nedir?',
    CheckHash: '3. Güvenilirlik kodu nasıl doğrulanır?',
    CheckResult: 'Sonucu kontrol et',
    Circulation: '#',
    Clear: 'CLEAR',
    Crashed: 'Oran',
    CurrentBets: 'GÜNCEL BAHISLER',
    Day: '24 saat',
    DearUsers: 'Teknik çalışmalar yapılmaktadır!',
    Error: 'Hata',
    ExampleForFair:
        'Oyun numarası - 9088 Oran - 1.06 Üretim süresi - 12/03/2019_16:09 Benzersiz oyun numarası - Fvv + 5TVHK2621A == Sonuç  olarak, oyunun anahtarı şu olacak: 9088_1.06_Boom_12 / 03 / 2019_16: 09_Fvv + 5TVHK2621A == Ve SHA256 algoritması tarafından oluşturulan güvenilirlik kodu: 4b38fc175d17471ea757556444af951303d94198b16c7f666505dec6f251612d',
    Examples: 'Örnek:',
    FairGame: 'Adil oyun',
    Fill: 'Doldur',
    FreeBet: 'FreeBet',
    GameIsStopped: 'Oyun durduruldu',
    GameKey: '2. Oyun anahtarı ve güvenilirlik kodu ne anlama geliyor?',
    ServerConnectionTemp: 'Server hatası.',
    GameRules: 'Oyun kuralları',
    GameStart: 'Oyun başlamaktadır',
    GameStarted: 'Oyun başlamıştır! İyi şanslar!',
    GameStatusIsNotRegistering: 'Artık bahisler kabul edilmiyor',
    GameWillResume: 'Özgeçmişe kadar zaman',
    GotoHome: 'HOME',
    Hash: 'Hash',
    History: 'GEÇMIŞ',
    HowToCheckFair:
        "Bu yöntem, oyuncuların bahislerini kaydetmeden önce oyunun oranlarının (oyunun bittiği sayı) önceden belirlenmesini sağlamaktadır. Herhangi bir zamana önceden belirlenmiş 5 oyun vardır. Aşağıdakiler 5 geçmiş ve 5 gelecek turdan oluşan bir Hash Kod'dur.",
    HowToCheckGameKey:
        'Oyunun oranları (oyunun biteceği sayı)  5 oyun önceden belirlenmiştir. Oyun sunucusunda aşağıdaki parametrelerden oluşan bir oyun anahtarı oluşturulur: [Oyun Numarası] _ [Oranlar] _ [Boom] _ [Üretim Süresi] _ [Benzersiz Oyun Numarası]',
    HowToCheckHash:
        'Oyun bittikten sonra, aşağıda oyun anahtarı gösterilir ve onu herhangi bir online SHA256 hesap makinesinde şifreleyebilirsiniz ve daha sonra sonraki 5 oyun için verilen güvenilirlik koduyla karşılaştırabilirsiniz.',
    IncreaseBy: 'ARTTIRIN',
    Jackpot: 'JACKPOT',
    JackpotRuleBust:
        'Jackpot rastgele düşecektir ve tura katılan oyunculara eşit olarak bölünecektir:',
    JackpotRules: 'JACKPOT RULES',
    JackpotRulesBalance:
        'Kazançlar otomatik olarak oyuncunun bakiyesine yansıtılacaktır.',
    JackpotRulesBetCondition: `En az 1${CURRENCY_SYMBOL} bahis yapmış`,
    JackpotRulesCashoutCondition: '1.2’den aşağı orana bahis bozdurmamış',
    Key: 'Anahtar',
    LanguageChangeAcception: 'Geçekten dili değiştirmek istiyor musunuz',
    LanguageChangeWarning: 'Düğmesine tıklarsanız mevcut oyununuz bitecek',
    LastGames: 'Son oyunlar',
    LastHand: 'Son el',
    LastJackpot: 'Last Jackpot',
    LastTopSpins: 'TOP MULTIPLIERS IN 24 HOURS',
    LastTopWins: 'TOP WINS IN 24 HOURS',
    LeaveMessage: 'Yorum yapın',
    Login: 'GIRIŞ',
    LoginFailed: 'Giriş başarısız oldu',
    MainPage: 'Ana Sayfa',
    ManualBet: 'BAHIS',
    MinFreebetCashout: 'Min. odd 1.5',
    Month: 'aydaki',
    MultiplierCashout: 'Kazanma oranı',
    Music: 'Müzik',
    My: 'Benim',
    No: 'Hayır',
    NotEnoughMoney: 'Yetersiz bakiye',
    OnLoss: 'KAYIP EDILDIĞINDE',
    OnWin: 'KAZANILDIĞINDA',
    PlaceYourBet: 'BAHIS YAPIN',
    Player: 'oyuncu',
    Rating: 'TOP',
    Register: 'Bahis yapılması',
    Registration: 'BAHISLER KABUL EDILMEKTEDIR',
    Reload: 'Güncelleme',
    Result: 'Sonuç',
    ResultsHash: 'Sonuçlar karma kodu',
    ReturnToBaseBet: 'ILK BAHISSE GERI DÖNÜŞ YAPIN',
    ReturnToCash: 'Nakit paraya geri dönme',
    Round: 'Tur',
    Rules1: 'Katılımcılar oyun başlamadan önce bahis yapabilirler.',
    Rules2: 'Oyun başladığında oranlar,  tükenene kadar artmaya başlar.',
    Rules3: 'Tükenme noktası rastgele olarak oluşturulur.',
    Rules4: 'Oyuncu, tükenmeye kadar nakit çıkışı yapmalıdır.',
    Rules5: 'Tükendiğinde nakit çıkışı yapılmadıysa, bahis kaybetmiş sayılır.',
    Rules6: 'Tükenmeden önce nakit çıkışı yapıldıysa, kazanç, nakit çıkışı yapıldığı andaki bahis oranıyla çarpılacaktır.',
    Rules7: `Oran 1.0’dan artmaya başlar. Oran 1.0 tükenirse, tüm bahisler kaybetmiş sayılacaktır. Örneğin: Oyuncu 5${CURRENCY_SYMBOL}’lik bahis yapıp 2.0 oran ile nakit çıkışını yaptıysa, 2*5=10${CURRENCY_SYMBOL} kazanacaktır. Oran 2.0’a ulaşmadan önce tükenirse (örneğin, 1.75), oyuncu tüm bahsini (5${CURRENCY_SYMBOL}) kaybedecektir.`,
    Rules8: 'Oyundan çıkması (bağlantının sona ermesi) durumunda, Bahis * O Anki Bahis Oranı = Kazanç formülüne uygun olarak nakit çıkışı yapılır. Herhangi bir nedenle oturumun sonlandırması, oyundan çıkması olarak kabul edilir.',
    Rules9: 'Oyuncu aynı anda iki bahis yapıp ayrı olarak nakit çıkışını yapabilir.',
    Rules10:
        'Oyuncu, otomatik bahis imkanı kullanarak istediği şartlarla bahis yapabilir. Mevcut pencerede bahis miktarı, paraya çevrilme katsayısı, nakit paraya çevrilme limiti ile ilgili bilgi alınabilir. Ayrıca kazanma ya da kaybetme durumunda sonraki bahis şartları önceden belirtilebilir.',
    Rules11: 'Kazançlar otomatik olarak oyuncunun hesabına yatırılacaktır.',
    Rules12:
        'Oyuncu „Bahislerim“ tuşuna tıklayarak oynadığı oyun kayıtlarını görebilir.',
    Rules13:
        'Oyunda herhangi bir hatanın tespit edilmesi durumunda Organizatör, oyuncuya kazançları vermeme veya kazançları düzeltme hakkını saklı tutar.',
    Rules14: 'Oyun limitleri:',
    Rules14_1: `Minimum Bahis - 0.1${CURRENCY_SYMBOL}`,
    Rules14_2: `Maksimum Bahis - 500${CURRENCY_SYMBOL}`,
    Rules15: `Oyuncunun kazanabileceği maksimum miktar (bahis*oran) – 30,000 GEL; bahis*oran 30,000${CURRENCY_SYMBOL}’e ulaştığında, sistem otomatik olarak nakit çıkışını yapacaktır.`,
    Rules16: `Tüm oyuncuların tur başına toplam maksimum kazancı - 100.000${CURRENCY_SYMBOL}. Bir turda tüm oyuncuların yaptığı bahis*oran 100.000${CURRENCY_SYMBOL}’e ulaştığında, sistem otomatik olarak nakit çıkışını yapacaktır.`,
    Rules17: 'Oyunun maksimum çok oyunculu sayısı 5500.',
    Rules18: `Oyun RTP'si %95.05'dir`,
    Rules19:
        'Oyuncu oyun penceresinin üst bölümünde oyunun son katsayılarını görebilir.',
    Rules20:
        'Oyuncu „Reyting“ tuşuna tıklayarak reyting bilgilerini görebilir.',
    Save: 'Save',
    ServerConnection: 'Server hatası',
    InternetConnectionLost:
        'İnternet bağlantınızla ilgili bir sorun oluştu, lütfen sayfayı yenileyin.',
    ServerError: 'Sunucu bağlantı hatası',
    SessionHasExpired: 'Oturum süresi doldu',
    SitesUpdate: 'Teknik çalışmalar yapılmaktadır!',
    Sound: 'Ses',
    Statistics: 'İstatistik',
    StopIf: 'DUR, EĞER BAHIS >',
    Submit: 'Submit',
    SuccessfulRegistration: 'Bahis kabul edilmiştir',
    TechnicalWork:
        'oyun yakında kesintisiz bir şekilde işlemeye devam edecektir.',
    TicketCashoutMustBe: 'Nakit Ödeme minimum olmalı 1.5',
    Time: 'Zaman',
    TopSpins: 'Oran',
    TopWins: 'Top Kazançlar',
    UnknowError: 'Server hatası',
    Unregister: 'Bahis iptali',
    UnregisterFreeBet: 'FreeBet iptali',
    Unregistered: 'Bahis iptal olmuştur',
    User: 'İsim',
    UserName: 'İsim',
    UserShort: 'İsim',
    WaitingForNextRound: 'You Are Waiting For Next Round',
    Win: 'KAZANMA',
    WinAmount: 'Kazanma',
    WinMultiplier: 'Win. Mult',
    Winners: 'Winners',
    WrongAmount: 'Yetersiz bakiye',
    WrongConfiguration: 'Yanlış yapılandırma',
    Year: 'Yıldaki',
    Yes: 'Evet',
    YouAreNotRegistered: 'Kayıtlı değilsin',
    EnterText: 'Metin Girin',
    PlayerNotFound: 'Player Not Found',
    Jan: 'Jan.',
    Feb: 'Feb.',
    Mar: 'Mar.',
    Apr: 'Apr.',
    May: 'May',
    June: 'June',
    July: 'July',
    Aug: 'Aug.',
    Sept: 'Sept.',
    Oct: 'Oct.',
    Nov: 'Nov.',
    Dec: 'Dec.',
    BlockedUser: 'Hesabınız engellendi',
    LoadingProblem: 'Oyun yüklenemedi, lütfen sayfayı yeniden yükleyin.',
};
