import React, { FC } from "react";
import { ListUser } from "../../../constants/interfaces/List";
import { StyledTr, StyledTd, Coeff, UserName } from "./CurrentList.styles";
import { ListStatus } from "../../../constants/interfaces/List";
import { CURRENCY_SYMBOL } from "../../../constants/constants";
import Avatar from "./components/Avatar";
import { useProfile } from "../../profile/configs/store/state";
import { formatUsername } from "../../../helpers/functions/format-username";

const CurrentList: FC<{ items: ListUser[] }> = ({ items }) => {
  const { Player: currentPlayer } = useProfile();

  return (
    <React.Fragment>
      {items.map((item: ListUser, index: number) => {
        const userName =
          currentPlayer.I === item.id
            ? currentPlayer.N
            : formatUsername(item.username);

        return (
          <StyledTr
            key={`item-${item.id}-${index}`}
            className={`${
              item.status === ListStatus.WON ? "won-highlight" : ""
            } ${item.status === ListStatus.LOST ? "lost-highlight" : ""}`}
          >
            <StyledTd className="bets-user">
              <div>
                <Avatar avatarId={item.avatar} />
              </div>
              <UserName>{userName}</UserName>
            </StyledTd>

            <StyledTd className="bets-bet">
              {item.bet}
              {CURRENCY_SYMBOL}
            </StyledTd>
            <StyledTd className="bets-coeffc">
              {item.odds ? <Coeff>{item.odds}X</Coeff> : <Coeff>-</Coeff>}
            </StyledTd>
            {item.profit ? (
              <StyledTd className="bets-profit">
                {item.profit}
                {CURRENCY_SYMBOL}
              </StyledTd>
            ) : (
              <StyledTd className="bets-profit">-</StyledTd>
            )}
          </StyledTr>
        );
      })}
    </React.Fragment>
  );
};

export default CurrentList;
