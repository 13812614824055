import { english as en } from "./dictionary/english";
import { turkish as tr } from "./dictionary/turkish";
import { russian as ru } from "./dictionary/russian";
import { ukrainian as uk } from "./dictionary/ukrainian";
import { useLocation } from "react-router-dom";
import { useCallback } from "react";

export enum LANGUAGES {
  en = "en",
  tr = "tr",
  ru = "ru",
  uk = "uk",
}

export const dictionaryList: any = { en, tr, ru, uk };

export const useGetLanguage = () => {
  const pathname = useLocation().pathname;

  return useCallback((): LANGUAGES => {
    const splitUrl = pathname.split("/");
    return splitUrl[1] as LANGUAGES;
  }, [pathname]);
};
