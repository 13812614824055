import styled from "styled-components";

export const StyledCurrentList = styled.div``;

export const StyledScrollableTable = styled.div`
  max-height: calc(100vh - 184px);
  overflow-y: auto;
  position: relative;
  overflow-y: overlay;

  &::-webkit-scrollbar {
    width: 2.79px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: #3b3c41;

    &:hover {
      background-color: #3b3c41;
    }
  }

  @media (min-width: ${1920}px) {
    height: calc(100vh - 193px);
  }
`;
