import React from "react";
import { AuthLayout, AuthSubmit, AuthTitle } from "./styled";
import Button from "../../components/Button/Button";
import StaticRockit from "../../assets/images/general/car.svg";

const Success = () => {
  return (
    <AuthLayout className="success">
        <img alt="Droid" src={StaticRockit} />
      <AuthTitle>YOU ARE VERIFIED!</AuthTitle>
      <AuthSubmit>
        <Button text="Play now"></Button>
      </AuthSubmit>
    </AuthLayout>
  );
};

export default Success;
