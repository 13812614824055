import React, { FC } from "react";
import styled from "styled-components";
import { rgba } from "polished";
import UserAvatar from "../../../../components/UserAvatar";
import { SystemChatMessage } from "../../../../constants/interfaces/ChatMessage";
import Like from "../../../../components/Icons/Like";
import { useLikeChatMessageService } from "../../store/services";
import { formatUsername } from "../../../../helpers/functions/format-username";
import PlainResultBox from "../../../../components/ResultBox/PlainResultBox";
import { useProfile } from "../../../profile/configs/store/state";
import { useParseDisplayMessage } from "../../hooks";

const MessageItemContainer = styled.div`
  margin-bottom: 24px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  background: ${(props) => props.theme.colors.paleOlive};
`;

const LikeMessage = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div<{ liked?: boolean }>`
  height: 11.26px;
  width: 10px;
  display: flex;
  color: ${(props) =>
    props.liked
      ? props.theme.colors.paleRed
      : rgba(props.theme.colors.white, 0.54)};
  cursor: pointer;
  &:active {
    opacity: 0.7;
  }
`;

const Counter = styled.div`
  font-size: 12px;
  margin-right: 4px;
  width: 5px;
  color: ${(props) => rgba(props.theme.colors.white, 0.54)};
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const BotAvatar = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.redPink};
  color: ${(props) => props.theme.colors.redPink};
  font-weight: bold;
  font-size: 14px;
`;

const UserName = styled.div`
  margin-left: 8px;
  font-size: 13px;
  font-weight: bold;
  color: ${(props) => rgba(props.theme.colors.redPink, 0.75)};
`;

const Name = styled.div`
  margin-left: 8px;
  font-size: 13px;
  font-weight: bold;
  color: ${(props) => rgba(props.theme.colors.redPink, 0.75)};
  display: inline;
`;

const Text = styled.div`
  margin-left: 8px;
  color: ${(props) => rgba(props.theme.colors.redPink, 0.75)};
  font-size: 13px;
  font-weight: normal;
  word-break: break-word;
  & > .chat-emoji {
    height: 25px;
    width: 25px;
  }
  & > .chat-giff {
    height: inherit;
    max-width: 275px;
    max-height: 250px;
    padding: 2px;
    border-radius: 4px;
    border: 1px solid #4b4b61;
  }
`;

const Body = styled.div`
  padding: 8px;
  background: ${(props) => rgba(props.theme.colors.redPink, 0.5)};
`;

const BodyHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`;

const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const BodyItemRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 8px;
`;

const BodyItem = styled.div``;

const BodyItemTitle = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
`;

const BodyItemValue = styled.div`
  font-size: 13px;
`;

const SystemMessageItem: FC<{ message: SystemChatMessage }> = ({ message }) => {
  const likeChatMessage = useLikeChatMessageService();
  const profileId = useProfile().Player?.I;
  const parseChatMessage = useParseDisplayMessage(true);

  const handleMessageLike = () => {
    if (!message.MID) return;
    likeChatMessage(message.MID);
  };

  return (
    <MessageItemContainer>
      <Header>
        <UserInfo>
          <Avatar>
            <BotAvatar>B</BotAvatar>
          </Avatar>
          <UserName>Bot:</UserName>
          <Text>
            <span
              dangerouslySetInnerHTML={{
                __html: parseChatMessage(message.Text),
              }}
            />
            <Name>
              @
              {profileId === message.Player.I
                ? message.Player.N
                : formatUsername(message.Player.N)}
            </Name>
          </Text>
        </UserInfo>
        <LikeMessage>
          <Counter>{message.LikeCount > 0 && message.LikeCount}</Counter>
          <IconWrapper onClick={handleMessageLike} liked={message.Liked}>
            <Like />
          </IconWrapper>
        </LikeMessage>
      </Header>
      <Body>
        <BodyHeader>
          <Avatar>
            <UserAvatar size={18} avatarId={message.Player.A} />
          </Avatar>
          <Name>{formatUsername(message.Player.N)}</Name>
        </BodyHeader>
        <BodyContent>
          <BodyItemRow>
            <BodyItem>
              <BodyItemTitle>განაღდება:</BodyItemTitle>
              <BodyItemValue>
                <PlainResultBox value={message.Multiplier} />
              </BodyItemValue>
            </BodyItem>
            <BodyItem>
              <BodyItemTitle>მოგება:</BodyItemTitle>
              <BodyItemValue>{message.WonAmount}</BodyItemValue>
            </BodyItem>
          </BodyItemRow>
          <BodyItemRow>
            <BodyItem>
              <BodyItemTitle>რაუნდი:</BodyItemTitle>
              <BodyItemValue>{message.CrashedAt}</BodyItemValue>
            </BodyItem>
            <BodyItem>
              <BodyItemTitle>ფსონი:</BodyItemTitle>
              <BodyItemValue>{message.BuyIn}</BodyItemValue>
            </BodyItem>
          </BodyItemRow>
        </BodyContent>
      </Body>
    </MessageItemContainer>
  );
};

export default SystemMessageItem;
