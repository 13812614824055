import React, { FC } from "react";
import styled from "styled-components";

const THead = styled.div`
  width: 100%;
  display: flex;
`;

export const TableHead: FC<{ className?: string }> = ({
  className,
  children,
}) => <THead className={className}>{children}</THead>;
