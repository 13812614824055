import React, { FC } from "react";
import { IconProps } from "./types";

export const Close: FC<IconProps> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g opacity="0.6">
        <path
          d="M14.8848 1.2002L1.34985 14.7351"
          stroke="white"
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.34985 1.2002L14.8848 14.7351"
          stroke="white"
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default Close;
