import React, { useEffect, useState } from "react";
import {
  AuthLayout,
  AuthSubmit,
  AuthTitle,
  RegistrationContainer,
} from "./styled";
import Button from "../../components/Button/Button";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const Registration = () => {
  const [user, setUser] = useState({
    nickiname: "",
    password: "",
    passwordRepeat: "",
  });

  useEffect(() => {
    fetch("https://api-by.devcom.ge/api/players/GetLeaderBoard", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        startDate: "2021-08-01",
        endDate: "2024-09-30",
        minimalBet: 1,
        minimalMultiplier: 1.1,
        size: 100,
      }),
      credentials: "omit",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const setData = (key: keyof typeof user, value: string) => {
    const copyOfUser = { ...user };
    copyOfUser[key] = value;
    setUser(copyOfUser);
  };

  const register = () => {
    if (!user.nickiname || !user.password || !user.passwordRepeat) {
      toast.error("Please fill all the fields");
      return false;
    }

    if (user.password !== user.passwordRepeat) {
      toast.error("Passwords do not much");
      return false;
    }

    axios
      .post("https://api-by.devcom.ge/api/user/register", {
        username: user.nickiname,
        password: user.password,
      })
      .then((res) => {
        console.log("res", res);
        toast.success("Successfully registered");
        setUser({
          nickiname: "",
          password: "",
          passwordRepeat: "",
        });
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Something went wrong");
      });
  };

  return (
    <AuthLayout>
      <AuthTitle>Registration</AuthTitle>
      <Toaster />
      <RegistrationContainer>
        <div className="input">
          <label>E-mail</label>
          <input
            value={user.nickiname}
            onChange={(e) => setData("nickiname", e.target.value)}
            type="email"
            placeholder="Type your E-mail"
          />
        </div>
        <div className="input">
          <label>Password</label>
          <input
            value={user.password}
            onChange={(e) => setData("password", e.target.value)}
            type="password"
            placeholder="Type your password"
          />
        </div>
        <div className="input">
          <label>Repeat Password</label>
          <input
            value={user.passwordRepeat}
            onChange={(e) => setData("passwordRepeat", e.target.value)}
            type="password"
            placeholder="Repeat your password"
          />
        </div>
      </RegistrationContainer>
      <AuthSubmit>
        <Button onClick={() => register()} text="Register">
          Register
        </Button>
      </AuthSubmit>
    </AuthLayout>
  );
};

export default Registration;
