import React, { FC } from "react";
import styled from "styled-components";

const StyledSidebar = styled.div`
  flex: 24;
  min-width: 250px;

  @media (min-width: ${2800}px) {
    order: 1;
  }

  @media screen and (min-device-width: 320px) and (max-device-width: 992px) and (orientation: landscape) {
    display: none;
  }

  @media (max-width: ${1000}px) {
    position: fixed;
    display: none;
  }
`;

const Sidebar: FC = ({ children }) => {
  return <StyledSidebar>{children}</StyledSidebar>;
};

export default Sidebar;
