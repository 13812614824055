import React, { FC } from "react";
import Modal from "../../../../components/Modal";
import useTranslate from "../../../../config/language";
import { KEYWORDS } from "../../../../config/language/keywords";
import { Container, Terms, List, Item, Title } from "./GameRulesModal.styles";
import InfoIcon from '../../../../assets/images/general/information-circle-contained.svg'

const GameRulesModal: FC<{
  gameId?: number;
  hashCode?: string;
  hashKey?: string;
  bustedAt?: number;
  close(): void;
}> = ({ close }) => {
  const translate = useTranslate();

  return (
    <Modal closeModal={close}>
      <Container>
        <Title>
          <img src={InfoIcon}  alt=""/>
          {translate(KEYWORDS.GameRules)}
          </Title>
        <Terms height={window.innerHeight}>
          <List>
            <Item className="popup-list-item-first">
              {translate(KEYWORDS.GameRules1)}
            </Item>
            <Item>{translate(KEYWORDS.GameRules2)}</Item>
            <Item>{translate(KEYWORDS.GameRules3)}</Item>
            <Item>{translate(KEYWORDS.GameRules4)}</Item>
            <Item>{translate(KEYWORDS.GameRules5)}</Item>
            <Item>{translate(KEYWORDS.GameRules6)}</Item>
            <Item>{translate(KEYWORDS.GameRules7)}</Item>
            <Item>{translate(KEYWORDS.GameRules8)}</Item>
            <Item>{translate(KEYWORDS.GameRules9)}</Item>
            <Item>{translate(KEYWORDS.GameRules10)}</Item>
            <Item>{translate(KEYWORDS.GameRules11)}</Item>
            <Item>{translate(KEYWORDS.GameRules12)}</Item>
            <Item>{translate(KEYWORDS.GameRules13)}</Item>
            <Item>{translate(KEYWORDS.GameRules14)}</Item>
            <Item>{translate(KEYWORDS.GameRules16)}</Item>
            <Item className="inner">
              <List>
                <Item>{translate(KEYWORDS.GameRules17)}</Item>
                <Item>{translate(KEYWORDS.GameRules18)}</Item>
              </List>
            </Item>
            <Item>{translate(KEYWORDS.GameRules19)}</Item>
            <Item>{translate(KEYWORDS.GameRules20)}</Item>
            <Item>{translate(KEYWORDS.GameRules21)}</Item>
            <Item>{translate(KEYWORDS.GameRules22)}</Item>
            <Item>{translate(KEYWORDS.GameRules23)}</Item>
            <Item>{translate(KEYWORDS.GameRules24)}</Item>
            <Item>{translate(KEYWORDS.GameRules25)}</Item>
            <Item>{translate(KEYWORDS.GameRules26)}</Item>
            <Item>{translate(KEYWORDS.GameRules27)}</Item>
            <Item>{translate(KEYWORDS.GameRules28)}</Item>
          </List>
        </Terms>
      </Container>
    </Modal>
  );
};

export default GameRulesModal;

// import React, { FC } from "react";
// // import Rule from "../../../../components/Icons/Rule";
// import Modal from "../../../../components/Modal";
// // import PagePopup from "../../../../components/PagePopup/PagePopup";
// import useTranslate from "../../../../config/language";
// import { KEYWORDS } from "../../../../config/language/keywords";
// import { Terms, List, Item } from "./GameRulesModal.styles";

// const GameRulesModal: FC<{ close: () => void }> = ({ close }) => {
//   const translate = useTranslate();

//   return (
//     // <PagePopup
//     //   title={translate(KEYWORDS.GameRules)}
//     //   icon={<Rule className="popup-head-ic" />}
//     //   close={close}
//     // >
//     <Modal closeModal={close}>
//       <Terms>
//         <List>
//           <Item className="popup-list-item-first">
//             {translate(KEYWORDS.GameRules1)}
//           </Item>
//           <Item>{translate(KEYWORDS.GameRules2)}</Item>
//           <Item>{translate(KEYWORDS.GameRules3)}</Item>
//           <Item>{translate(KEYWORDS.GameRules4)}</Item>
//           <Item>{translate(KEYWORDS.GameRules5)}</Item>
//           <Item>{translate(KEYWORDS.GameRules6)}</Item>
//           <Item>{translate(KEYWORDS.GameRules7)}</Item>
//           <Item>{translate(KEYWORDS.GameRules8)}</Item>
//           <Item>{translate(KEYWORDS.GameRules9)}</Item>
//         </List>
//       </Terms>
//     </Modal>
//     // </PagePopup>
//   );
// };

// export default GameRulesModal;
