import React, { FC } from "react";
import { TopMultipliers } from "../../../../constants/interfaces/TopMultipliers";
import Table from "../../../../components/Table/Table";
// import { TableHead } from "../../../../components/Table/TableHead";
// import { TableHeaderRow } from "../../../../components/Table/TableHeaderRow";
import RenderOddsTableRows from "../components/RenderOddsTableRows";
import { TableBody } from "../../../../components/Table/TableBody";
import { TOP_LIST_FILTER_INDEXES } from "../configs/tools";
// import { KEYWORDS } from "../../../../config/language/keywords";
// import useTranslate from "../../../../config/language";
import { Container, TableOverflow } from "./Odds.list.styles";

const OddsList: FC<{
  items?: TopMultipliers;
  selectedFilter: TOP_LIST_FILTER_INDEXES;
}> = ({ items, selectedFilter }) => {
  // const translate = useTranslate();

  return (
    <Container>
      <TableOverflow>
        <Table className="table-bets">
          {/* <TableHead>
            <TableHeaderRow>
              <TableHeaderCell className="rating-date">
                {translate(KEYWORDS.Time)}
              </TableHeaderCell>

              <TableHeaderCell className="coeffc-grren">
                {translate(KEYWORDS.Crashed)}
              </TableHeaderCell>

              <TableHeaderCell className="rating-pf">PF</TableHeaderCell>
            </TableHeaderRow>
          </TableHead> */}

          <TableBody className="table-body">
            {items && (
              <RenderOddsTableRows items={items} time={selectedFilter} />
            )}
          </TableBody>
        </Table>
      </TableOverflow>
    </Container>
  );
};

export default OddsList;
