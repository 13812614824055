import styled from "styled-components";
// import avatar from "../../../assets/images/general/header-avatar.png";

export const StyledTr = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  &.won-highlight {
    background: linear-gradient(
      90deg,
      rgba(95, 208, 102, 0.33) 0%,
      rgba(27, 72, 45, 0) 100%
    );
  }

  &.lost-highlight {
    background: rgba(255, 255, 255, 0.04);
  }
`;

export const StyledTd = styled.div`
  border: 0;
  border-collapse: collapse;
  padding: 4px 15px;
  width: 25%;

  &.bets-user {
    display: flex;
    align-items: center;
  }

  @media (min-width: ${2800}px) {
    padding: 4px 4px;
  }

  @media (min-width: ${1366}px) {
    padding: 10px 4px;
    font-size: 14px;
    line-height: 17px;
  }
`;

// export const Avatar = styled.span<{ avatar: string }>`
//   display: inline-block;
//   width: 24px;
//   height: 24px;
//   margin-right: 6px;
//   vertical-align: middle;
//   background: url(${(props) => props.avatar}) no-repeat center center;
//   background-size: cover;
//   border-radius: 50%;

//   @media (min-width: ${2800}px) {
//     width: 16px;
//     height: 16px;
//     margin-right: 4px;
//   }

//   @media (min-width: ${1366}px) {
//     width: 38px;
//     height: 38px;
//     margin-right: 12px;
//   }
// `;

export const Coeff = styled.span`
  color: #52b05a;
`;

export const UserName = styled.div``;
