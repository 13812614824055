import React, { FC } from "react";
import { IconProps } from "./types";

export const ArrowDown: FC<IconProps> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 8.828 5.414"
      className={className}
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        d="M6 9l3 3 3-3"
        transform="translate(-4.586 -7.586)"
      />
    </svg>
  );
};

export default ArrowDown;
