import styled from "styled-components";

export const StyledContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
    props.disabled &&
    `
        opacity: 0.42;
        pointer-events: none;
    `}
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 8px;
  flex: 1;
  min-width: 120px;

  img {
    width: 24px;
  }

  &.active div {
    color: #70ff03;
  }
`;

export const TextSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  flex: 2;
`;

export const SwitchSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 2;
  position: relative;
`;

export const Title = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: ${(props) => props.theme.colors.whiteGrayish};
  font-weight: bold;
  font-size: 16px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  @media (max-height: ${750}px), (max-width: ${1100}px) {
    font-size: 13px;
  }
`;

export const InputSection = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 70px;
  flex: 1;

  &.bet-disabled {
    max-width: 110px;
    max-height: 34px;
  }

  input {
    padding-bottom: 3px !important;
    font-family: "Montserrat",sans-serif;
  }

  div, input {
    border-radius: 0;
    padding: 0;
  }
`;

export const SwitchShadow = styled.img`
  position: absolute;
  left: -18%;
  z-index: -1;
  opacity: 0.72;
  filter: blur(20px);

  @media (max-width: ${600}px) {
    left: -59%;
  }

  @media (min-width: ${600}px) and (max-width: ${992}px) {
    left: 0;
  }
`;
