import React, { memo } from "react";
import styled from "styled-components";
import ChatBody from "./ChatBody/ChatBody";
import ChatHeader from "./ChatHeader/ChatHeader";
import { useMessengerState } from "./store/state";
import { useToggleOpenChatService } from "./store/services";
import useTranslate from "../../config/language";
import { KEYWORDS } from "../../config/language/keywords";
import ArrowLeft from "../../components/Icons/ArrowLeft";
import { useIsMobile } from "../../helpers/hooks/useIsMobile";
import ChatIcon from "../../components/Icons/ChatIcon";

const Container = styled.div`
  z-index: 99;
  margin-left: 8px;
  width: 288px;
`;

const ClosedContainer = styled.div`
  position: relative;
`;

const OpenChat = styled.div`
  position: absolute;
  right: 0;
  background: ${(props) => props.theme.colors.whiteTransparent};
  height: 40px;
  width: 75px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
`;

const OpenChatMobile = styled.div`
  position: absolute;
  top: -54px;
  right: 0;
  background: ${(props) => props.theme.colors.whiteTransparent};
  height: 40px;
  width: 75px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;

const ChatContainer = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  @media (max-width: ${768}px) {
    position: fixed;
    background: ${(props) => props.theme.colors.charcoalGreyTwo};
  }
`;

const IconWrapper = styled.div`
  width: 8px;
`;

const MobileIconWrapper = styled.div`
  width: 20px;
  height: 20px;
`;

const IconContainer = styled.div`
  display: flex;
  width: 20px;
  justify-content: center;
`;

const Chat = () => {
  const messenger = useMessengerState();
  const translate = useTranslate();
  const handleOpen = useToggleOpenChatService();
  const isMobile = useIsMobile();

  if (!messenger.open && isMobile) {
    return (
      <ClosedContainer
        onClick={() => {
          handleOpen(true);
        }}
      >
        <OpenChatMobile>
          <IconContainer>
            <MobileIconWrapper>
              <ChatIcon />
            </MobileIconWrapper>
          </IconContainer>
          {translate(KEYWORDS.Chat)}
        </OpenChatMobile>
      </ClosedContainer>
    );
  }

  if (!messenger.open) {
    return (
      <ClosedContainer
        onClick={() => {
          handleOpen(true);
        }}
      >
        <OpenChat>
          <IconContainer>
            <IconWrapper>
              <ArrowLeft />
            </IconWrapper>
          </IconContainer>
          {translate(KEYWORDS.Chat)}
        </OpenChat>
      </ClosedContainer>
    );
  }

  return (
    <Container>
      <ChatContainer>
        <ChatHeader handleOpen={handleOpen} />
        <ChatBody />
      </ChatContainer>
    </Container>
  );
};

export default memo(Chat);
