import { applyMiddleware, createStore, Reducer } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import setupSocket from "../sockets";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();

const createAppStore = (rootReducer: Reducer): any => {
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(sagaMiddleware))
  );

  const socket = setupSocket(store.dispatch);
  sagaMiddleware.run(rootSaga, { socket: socket });
  return store;
};

export default createAppStore;
