/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Game from "./Game";
import { useGameState } from "./config/store/state/app.state";
import BackgroundMusic from "./assets/sounds/music.mp3";
import Loader from "./features/Loader";
import styled from "styled-components";

const StyledBackground = styled.div<{ visible: boolean }>`
  height: 100%;
  overflow: auto;
  display: ${({ visible }) => (visible ? "block" : "none")};
`;

function App() {
  const gameLoading = useGameState().loading;
  const [loaded, setLoaded] = useState<boolean>(false);
  useEffect(() => {
    if (!gameLoading && BackgroundMusic) {
      setLoaded(true);
    }
  }, [BackgroundMusic, gameLoading]);

  return (
    <>
      {!loaded && <Loader />}
      <StyledBackground visible={loaded}>
        <Game />
      </StyledBackground>
    </>
  );
}

export default App;
