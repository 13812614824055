import styled from "styled-components";

export const AvatarContainer = styled.div<{ src: string; size?: number }>`
  height: ${(props) => (props.size ? `${props.size}px` : "35px")};
  width: ${(props) => (props.size ? `${props.size}px` : "35px")};
  border-radius: 100%;
  -webkit-border-radius: 100%;
  background: url(${(props) => props.src}) no-repeat center center;
  background-size: cover;
  padding: 10px;
`;
