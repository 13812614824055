import { useEffect } from "react";
import { useGameConfigs } from "../../config/store/state/app.state";
import { SoundPlayer } from "../../features/soundPlayer/soundPlayer";

const useBackgroundsMusic = () => {
  const configs = useGameConfigs();

  useEffect(() => {
    const audio = new SoundPlayer();

    if (configs.backgroundMusic) {
      audio.playBackgroundMusic();
    }

    return () => {
      audio.stopBackgroundMusic();
    };
  }, [configs.backgroundMusic]);
};

export default useBackgroundsMusic;
