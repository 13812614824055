import React, { FC } from "react";
import styled from "styled-components";
import { TOP_LIST_FILTER_INDEXES } from "../configs/tools";
import { TopWinnings } from "../../../../constants/interfaces/TopWinnings";
import WinningTableItem from "../components/WinningTableItem";

const Container = styled.div`
  padding: 18px 0px 15px;
  padding-top: 10px;
`;

const TableOverflow = styled.div``;

const Table = styled.div`
  padding: 0 10px;
`;

const MultiplierCashOutList: FC<{
  items?: TopWinnings;
  selectedFilter: TOP_LIST_FILTER_INDEXES;
}> = ({ items, selectedFilter }) => {
  return (
    <Container>
      <TableOverflow>
        <Table>
          {items &&
            items[selectedFilter].map((game, index) => {
              return <WinningTableItem key={index} game={game} />;
            })}
        </Table>
      </TableOverflow>
    </Container>
  );
};

export default MultiplierCashOutList;
