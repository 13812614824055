import React, { FC, memo } from "react";
import NumberInput from "../../../../components/NumberInput/NumberInput";
import useTranslate from "../../../../config/language";
import { KEYWORDS } from "../../../../config/language/keywords";
import Shadow from "../../../../assets/images/general/bullet-shadow.svg";
import Power from "../../../../assets/images/Power.svg";
import PowerOn from "../../../../assets/images/PowerOn.svg";
import {
  StyledContainer,
  ToggleContainer,
  SwitchShadow,
  InputSection,
  TextSection,
  Title,
} from "./Styled";

const AutoBetPanel: FC<{
  autoBet: boolean;
  enableAutoCashOut: boolean;
  autoCashOut: number;
  setAutoBet(autoBet: boolean): void;
  setEnableAutoCashOut(enableAutoCashOut: boolean): void;
  setAutoCashOut: (autoCashOut: number) => void;
  panelDisabled: boolean;
  minValue?: number;
  maxValue?: number;
}> = ({
  autoBet,
  setAutoBet,
  enableAutoCashOut,
  setEnableAutoCashOut,
  autoCashOut,
  setAutoCashOut,
  panelDisabled,
  minValue,
  maxValue,
}) => {
  const translate = useTranslate();

  return (
    <StyledContainer
      disabled={panelDisabled}
    >
      <ToggleContainer onClick={() => setAutoBet(!autoBet)} className={autoBet ? "active" : ""}>
        <img alt="" src={autoBet ? PowerOn : Power} />
        <SwitchShadow
          style={{ display: autoBet ? "block" : "none" }}
          src={Shadow}
          alt=""
        />
        <TextSection>
          <Title>{translate(KEYWORDS.AutoBetting)}</Title>
        </TextSection>
      </ToggleContainer>
      <ToggleContainer
        className={enableAutoCashOut ? "active" : ""}
        onClick={() => setEnableAutoCashOut(!enableAutoCashOut)}
      >
        <img alt="" src={enableAutoCashOut ? PowerOn : Power} />
        <TextSection>
          <Title>{translate(KEYWORDS.AutoCashouting)}</Title>
        </TextSection>
      </ToggleContainer>
      <InputSection className="bet-disabled">
        <NumberInput
          hideArrow
          suffix=" x"
          value={autoCashOut}
          minValue={minValue}
          maxValue={maxValue}
          onChange={setAutoCashOut}
          disabled={!enableAutoCashOut || panelDisabled}
          fontSize={21}
        />
      </InputSection>
    </StyledContainer>
  );
};

export default memo(AutoBetPanel);
