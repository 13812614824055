import styled from "styled-components";

export const Container = styled.div`
  background: #141c2e;
  border-radius: 12px;
  box-shadow: none;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: ${2800}px) {
    background: #320703;
    border-radius: 18px;
    max-width: 956px;
  }
`;

export const Title = styled.div`
  display: flex;
  color: #fff;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  padding: 30px 12px;
  text-transform: uppercase;
  align-items: center;
  gap: 12px;

  img {
    height: 38px;
    position: relative;
    top: 2px;
  }

  @media (min-width: ${2800}px) {
    font-size: 24px;
    line-height: 33px;
    padding: 44px 0 41px;
  }
`;

export const Terms = styled.div<{ height: number }>`
  max-height: calc(${(props) => props.height}px - 94px);
  overflow-y: auto;
  padding-bottom: 30px;
  padding-right: 20px;
  margin-left: 15px;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: #3b3c41;
    border-radius: 18px;

    &:hover {
      background-color: #3b3c41;
    }
  }

  @media (min-width: ${1366}px) {
    max-height: calc(${(props) => props.height}px - 315px);
  }

  @media (min-width: ${1920}px) {
    max-height: calc(${(props) => props.height}px - 365px);
  }
`;

export const List = styled.ul`
  font-size: 10px;
  line-height: 12px;
  font-feature-settings: "case" off;
  font-weight: 500;

  @media (min-width: ${1366}px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const Item = styled.li`
  padding-left: 17px;
  margin-bottom: 8px;
  font-size: 15px;
  line-height: 18px;
  color: #fff;
  position: relative;

  &.popup-list-item-first {
    margin-top: 0px;
  }

  &::before {
    display: block;
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    position: absolute;
    top: 5px;
    left: 0;
    background: #fff;
  }

  &.inner {
    &::before {
      display: none;
    }

    ul {
      list-style-type: none;
    }
  }

  @media (min-width: ${2800}px) {
    padding-left: 25px;
  }

  @media (min-width: ${1366}px) {
    margin-bottom: 25px;

    &:before {
      top: 6px;
    }
  }
`;
