import React, { FC } from "react";
import Button from "../../../../../components/Button/Button";
import { ButtonNumericLabel } from "../../../../../components/Button/Button.styles";
import styled from "styled-components";
import { motion } from "framer-motion";
import useTranslate from "../../../../../config/language";
import { KEYWORDS } from "../../../../../config/language/keywords";
import { roundNumber } from "../../../../../helpers/functions/round-number";
import { useGameStatusState } from "../../../../../config/store/state/app.state";
import { GAME_STATE } from "../../../../../constants/interfaces/Game";

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const BetButtonContainer = styled(motion.div)`
  display: flex;
  flex: 48;
  margin-right: 6px;
`;

const StyledButton = styled(Button)`
  background: ${(props) => props.theme.colors.frogGreen};
  &:disabled {
    background: ${(props) => props.theme.colors.frogGreen};
    color: unset;
  }
`;

const StyledUnregisterButton = styled(Button)`
  white-space: nowrap;
  &:disabled {
    background: unset;
    color: unset;
  }
`;

const CashOutPanel: FC<{
  amount: number;
  handleCashOut(): void;
  handleUnregister(): void;
  disabled?: boolean;
}> = ({ amount, handleCashOut, handleUnregister, disabled }) => {
  const translate = useTranslate();
  const gameState = useGameStatusState().state;

  return (
    <StyledContainer>
      <BetButtonContainer
        initial={{ maxWidth: "50%" }}
        animate={{ maxWidth: "100%" }}
        transition={{ duration: 0.2 }}
      >
        {gameState === GAME_STATE.RUNNING ? (
          <StyledButton
            text={
              <>
                {translate(KEYWORDS.Cashout)}{" "}
                <ButtonNumericLabel>({roundNumber(amount)}</ButtonNumericLabel>
                )
              </>
            }
            className="green"
            disabled={disabled}
            onClick={handleCashOut}
          />
        ) : (
          <StyledUnregisterButton
            text={
              <>
                {translate(KEYWORDS.Unregister)}{" "}
                <ButtonNumericLabel>({roundNumber(amount)}</ButtonNumericLabel>
                )
              </>
            }
            className="red"
            onClick={handleUnregister}
            disabled={disabled}
          />
        )}
      </BetButtonContainer>
    </StyledContainer>
  );
};

export default CashOutPanel;
