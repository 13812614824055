import React, { FC } from "react";
import Button from "../../../../../components/Button/Button";
import { ButtonNumericLabel } from "../../../../../components/Button/Button.styles";
import styled from "styled-components";
import useTranslate from "../../../../../config/language";
import { KEYWORDS } from "../../../../../config/language/keywords";
import { roundNumber } from "../../../../../helpers/functions/round-number";
import { GAME_STATE } from "../../../../../constants/interfaces/Game";
import { useGameStatusState } from "../../../../../config/store/state/app.state";
import { CURRENCY_SYMBOL } from "../../../../../constants/constants";

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  @media (max-height: ${550}px), (max-width: ${600}px) {
    font-size: 13px;
  }
`;

const CashOutPanel: FC<{
  amount: number;
  handleCashOut(): void;
  handleUnregister(): void;
  disabled?: boolean;
}> = ({ amount, handleCashOut, handleUnregister, disabled }) => {
  const translate = useTranslate();
  const gameState = useGameStatusState().state;

  return (
    <StyledContainer>
      {gameState === GAME_STATE.RUNNING ? (
        <StyledButton
          text={
            <>
              {translate(KEYWORDS.Cashout)} (
              <ButtonNumericLabel>{roundNumber(amount)}</ButtonNumericLabel>
              {CURRENCY_SYMBOL})
            </>
          }
          className="green"
          disabled={disabled}
          onClick={handleCashOut}
        />
      ) : (
        <StyledButton
          text={
            <>
              {translate(KEYWORDS.Unregister)} (
              <ButtonNumericLabel>{roundNumber(amount)}</ButtonNumericLabel>
              {CURRENCY_SYMBOL})
            </>
          }
          className="red"
          onClick={handleUnregister}
          disabled={disabled}
        />
      )}
    </StyledContainer>
  );
};

export default CashOutPanel;
