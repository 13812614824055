import styled from "styled-components";

export const Container = styled.div`
  background: #24233d;
  border-radius: 12px;
  box-shadow: none;
  width: 650px;
  max-width: 100dvw;
  box-sizing: border-box;
  overflow: hidden;

  @media (min-width: ${2800}px) {
    background: #320703;
    border-radius: 18px;
    width: 956px;
  }
`;

export const MainTitle = styled.div`
  display: flex;
  color: #fff;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  padding: 30px 12px;
  text-transform: uppercase;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 12px;
  text-transform: uppercase;

  img {
    height: 38px;
    position: relative;
    top: 2px;
  }

  svg {
    height: 22px;
    width: 18px;
    margin-right: 10px;
  }

  @media (min-width: ${2800}px) {
    font-size: 24px;
    line-height: 28px;
    padding: 41px 0 20px;

    svg {
      height: 36px;
      width: 31px;
      margin-right: 21px;
    }
  }
`;
