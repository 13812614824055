import styled from "styled-components";

export const RegistrationContainer = styled.div`
    div {
        margin-bottom: 20px;
        text-align: left;

        label {
            color: #B9C2E1;
            display: block;
            font-size: 18px;
            margin-bottom: 8px;
            font-weight: 700;
        }

        input {
            height: 50px;
            width: 100%;
            padding-left: 12px;
            padding-right: 12px;
            border-radius: 12px;
            font-size: 16px;
        }
    }
`;

export const AuthTitle = styled.h2`
    color: #B9C2E1;
    text-transform: uppercase;
    font-family: "FiraGo";
    font-size: 28px;
    margin-bottom: 15%;
`;

export const AuthLayout = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100dvw;
  height: 100dvh;
  background: #141b2d;
  z-index: 20;
  display: flex;
  flex-direction: column;
  padding: 15% 8%;
  text-align: center;

  &.success {
    justify-content: center;

    & > img {
        width: 170px;
        margin: 0 auto 13% auto;
    }

    & > h2 {
        margin: 0;
    }

    & > div {
        margin-top: 7%;
    }
  }
`;

export const AuthSubmit = styled.div`
    margin-top: auto;
    
    button {
        height: 50px;
        background: #97fc4e;
        text-transform: uppercase;
    }
`;
