import { all } from "redux-saga/effects";
import {
  handleMessageSend,
  handleMessageLike,
  handlePlayerRegister,
  handlePlayerRegisterSuccess,
  handlePlayerUnregister,
  handlePlayerUnregisterSuccess,
  handlePlayerCashOut,
  handlePlayerCashOutSuccess,
  handleSaveSettings,
  handlePing,
} from "./app.sagas";

export default function* rootSaga(socket: any) {
  yield all([
    handleMessageSend(socket),
    handleMessageLike(socket),
    handlePlayerRegister(socket),
    handlePlayerRegisterSuccess(),
    handlePlayerUnregister(socket),
    handlePlayerUnregisterSuccess(),
    handlePlayerCashOut(socket),
    handlePlayerCashOutSuccess(),
    handleSaveSettings(socket),
    handlePing(socket),
  ]);
}
