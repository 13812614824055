import styled from "styled-components";

export const StyledInfoPanel = styled.div`
  display: flex;
  justify-content: space-between;
  font-feature-settings: "case" off;
  display: flex;
  align-items: center;
  height: 35px;
  margin-bottom: 12px;

  @media (min-width: ${2800}px) {
    margin-bottom: 15px;
    margin-top: 26px;
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;

  .bets-refresh-ic {
    width: 18px;
    height: 15.99px;
    margin-right: 8px;
  }

  @media (min-width: ${2800}px) {
    .bets-refresh-ic {
      width: 20.96px;
      height: 18.28px;
      margin-right: 7px;
    }
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  // position: absolute;
  // top: -16px;
  // left: -1px;

  // &.bets-switch {
  //   position: relative;
  //   top: auto;
  //   left: 0;
  // }

  // @media (min-width: ${2800}px) {
  //   &.bets-switch {
  //     display: inline-block;
  //     vertical-align: text-bottom;
  //   }
  // }
`;

export const SwitchLabel = styled.div`
  font-size: 8px;
  line-height: 10px;
  color: #ffffff;
  font-feature-settings: "case" on;
  display: inline-block;
  margin-right: 9px;
  vertical-align: middle;

  &.bets-switch-lab {
    font-size: 12px;
    line-height: 14px;
    font-feature-settings: "case" off;
  }

  @media (min-width: ${2800}px) {
    &.bets-switch-lab {
      font-size: 8px;
      line-height: 10px;
    }
  }

  @media (min-width: ${1366}px) {
    &.bets-switch-lab {
      font-size: 16px;
      line-height: 19px;
    }
  }
`;

export const Text = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.84);
  white-space: nowrap;

  @media (min-width: ${1366}px) {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }
`;

export const Count = styled.span`
  color: #52b05a;
`;
