import React, { FC } from "react";
import useTranslate from "../../../../../config/language";
import { KEYWORDS } from "../../../../../config/language/keywords";
import { roundNumber } from "../../../../../helpers/functions/round-number";
import { CURRENCY_SYMBOL } from "../../../../../constants/constants";
import { ButtonInput } from "../../../styles";

const RegisterPanel: FC<{
  amount: number;
  setAmount(amount: number | null): void;
  handleRegister(): void;
  toggleBoard(): void;
  icon: any;
  minValue?: number;
  maxValue?: number;
}> = ({ amount, handleRegister, toggleBoard, icon }) => {
  const translate = useTranslate();

  return (
    <ButtonInput
      text={`${translate(KEYWORDS.FreeBet)} -${roundNumber(
        amount
      )}${CURRENCY_SYMBOL}`}
      onClick={handleRegister}
    />
  );
};

export default RegisterPanel;
