import React, { FC } from "react";
import {
  StyledNavigation,
  StyledNavigationItem,
} from "./TableNavigation.styles";

const TableNavigation: FC<{
  items: { text: string; index: number }[];
  activeIndex: number;
  setActive(index: number): void;
}> = ({ items, activeIndex, setActive }) => {
  return (
    <StyledNavigation className="clearfix">
      {items.map((item) => {
        return (
          <StyledNavigationItem
            key={item.index}
            text={item.text}
            index={item.index}
            active={item.index === activeIndex}
            onClick={setActive}
          />
        );
      })}
    </StyledNavigation>
  );
};

export default TableNavigation;
