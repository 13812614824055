import React, { FC, memo, useEffect, useState } from "react";
import { GAME_STATE } from "../../../constants/interfaces/Game";
import { useErrorsState } from "../../../config/store/state/app.state";
import { MultiplierContainer, StyledMultiplier } from "./GameMultiplier.styles";
import coeff from "../../../assets/images/general/x-coefficient.svg"

export const MULTIPLIER_STEP = 100;
export const MULTIPLIER_DELAY = 125;

export const calculateMultiplierGrow = (currentMultiplier: number) => {
  return Number(
    (currentMultiplier + currentMultiplier / MULTIPLIER_STEP / 2).toFixed(2)
  );
};

export const getFinalMultiplier = (
  gameCrushed: boolean,
  gameError: boolean,
  localMultiplier: number,
  multiplier: number
) => {
  return gameCrushed ||
    gameError ||
    localMultiplier > calculateMultiplierGrow(multiplier + multiplier * 0.5)
    ? multiplier
    : localMultiplier;
};

const GameMultiplier: FC<{
  multiplier: number;
  gameState?: GAME_STATE;
}> = ({ multiplier = 1, gameState }) => {
  const animate = gameState === GAME_STATE.LAUNCHING;
  const gameCrushed = gameState === GAME_STATE.CRUSHED;
  const gameError = useErrorsState().isError;

  // BEGIN TEMP LOCAL CALCULATIONS
  const [localMultiplier, setLocalMultiplier] = useState(1);

  useEffect(() => {
    setLocalMultiplier(multiplier || 1);
  }, [multiplier]);

  useEffect(() => {
    if (gameState === GAME_STATE.RUNNING) {
      const interval = setInterval(() => {
        setLocalMultiplier((prevValue) => calculateMultiplierGrow(prevValue));
      }, MULTIPLIER_DELAY);

      return () => {
        clearInterval(interval);
      };
    }
  }, [multiplier, gameState, setLocalMultiplier]);

  // This is only because we have to count locally and can't display multiplier directly
  // from BE due to BE server lags, overall counting multiplier in FE is bad idea so this
  // should be rewritten.
  let finalMultiplayer = getFinalMultiplier(
    gameCrushed,
    gameError,
    localMultiplier,
    multiplier
  );
  // END TEMP LOCAL CALCULATIONS
  const decimalNumber = (finalMultiplayer.toFixed(2) + "").split(".")[1],
    fullNumber = Math.floor(finalMultiplayer);

  return (
    <MultiplierContainer
      initial={animate ? { x: 200 } : undefined}
      animate={animate ? { x: 0 } : undefined}
      transition={{ duration: 0.3 }}
    >
      <StyledMultiplier>
        <div className={`game-counter-sec ${gameCrushed ? 'gameCrushed' : '' }`}>
          <span>{fullNumber}.{decimalNumber || "00"}</span> <img className="game-counter-x" src={coeff} alt="" />
        </div>
      </StyledMultiplier>
    </MultiplierContainer>
  );
};

export default memo(GameMultiplier);
