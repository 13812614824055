export interface GameConfig {
  MaxBet: number;
  MinBet: number;
  MinFreeBetCashoutMultiplier: number;
}

export enum GAME_CONFIG {
  INITIALIZE_GAME_CONFIG = "INITIALIZE_GAME_CONFIG",
}

export const initializeMinMaxValue = (minMaxValue: GameConfig) => {
  return {
    type: GAME_CONFIG.INITIALIZE_GAME_CONFIG as GAME_CONFIG.INITIALIZE_GAME_CONFIG,
    minMaxValue,
  };
};
