import React, { FC, useState } from "react";
import Modal from "../../../../components/Modal";
import useTranslate from "../../../../config/language";
import { KEYWORDS } from "../../../../config/language/keywords";
// import { avatarsList } from "./avatarsList";
import Button from "../../../../components/Button/Button";
import { useProfile } from "../../configs/store/state";
import { useGameConfigs } from "../../../../config/store/state/app.state";
import { useSaveSettingsService } from "../../../../config/store/services";
// import Close from "../../../../components/Icons/Close";
import Avatar01 from "../../../../assets/images/avatars/avatar-1.png";
import Avatar02 from "../../../../assets/images/avatars/avatar-2.png";
import Avatar03 from "../../../../assets/images/avatars/avatar-3.png";
import Avatar04 from "../../../../assets/images/avatars/avatar-4.png";
import Avatar05 from "../../../../assets/images/avatars/avatar-5.png";
import Avatar06 from "../../../../assets/images/avatars/avatar-6.png";
import Avatar07 from "../../../../assets/images/avatars/avatar-7.png";
import Avatar08 from "../../../../assets/images/avatars/avatar-8.png";
import Avatar09 from "../../../../assets/images/avatars/avatar-9.png";
import {
  AvatarsList,
  AvatarsListItem,
  AvatarsListItemImage,
  // CloseWrapper,
  Container,
  Head,
  MobileButton,
  ModalBody,
  ProfileAvatar,
  ProfileAvatarButton,
  ProfileAvatarImage,
  ProfileAvatarName,
  Title,
} from "./AvatarsModal.styles";

const avatarsList = [
  { path: Avatar01, id: 0 },
  { path: Avatar02, id: 1 },
  { path: Avatar03, id: 2 },
  { path: Avatar04, id: 3 },
  { path: Avatar05, id: 4 },
  { path: Avatar06, id: 5 },
  { path: Avatar07, id: 6 },
  { path: Avatar08, id: 7 },
  { path: Avatar09, id: 8 },
];

const AvatarsModal: FC<{
  closeModal(): void;
}> = ({ closeModal }) => {
  const translate = useTranslate();
  const profile = useProfile().Player;
  const avatarId = profile?.A || 0;

  const [selectedAvatar, setSelectedAvatar] = useState(avatarId);
  const selectedAvatarPath =
    avatarsList.filter((avatar) => avatar.id === Number(selectedAvatar))[0]
      ?.path || avatarsList[0].path;

  const configs = useGameConfigs();
  const saveSettings = useSaveSettingsService();

  const handleSaveSelectedAvatar = () => {
    saveSettings({
      Avatar: selectedAvatar,
      Music: configs.backgroundMusic,
      Sound: configs.voiceEffects,
      Animate: configs.animate,
    });
    closeModal();
  };

  return (
    <Modal closeModal={closeModal}>
      <Container>
        {/* <ModalHeader>{translate(KEYWORDS.ChangeAvatar)}</ModalHeader> */}
        <Head className="round-head">
          <Title className="round-title">
            {translate(KEYWORDS.ChangeAvatar)}
          </Title>

          {/* <CloseWrapper onClick={closeModal}>
            <Close className="popup-close-ic" />
          </CloseWrapper> */}
        </Head>

        <ModalBody>
          <ProfileAvatar>
            <ProfileAvatarImage src={selectedAvatarPath} />
            <ProfileAvatarName>{profile.N}</ProfileAvatarName>
            <ProfileAvatarButton
              onClick={handleSaveSelectedAvatar}
              className="button-wrapper"
            >
              {/* <StyledButton text={translate(KEYWORDS.Save)} /> */}
              <Button text={translate(KEYWORDS.Save)} />
            </ProfileAvatarButton>
          </ProfileAvatar>

          <AvatarsList>
            {avatarsList.map((avatar) => {
              return (
                <AvatarsListItem
                  key={avatar?.id}
                  onClick={() => setSelectedAvatar(avatar.id)}
                >
                  <AvatarsListItemImage src={avatar?.path} />
                </AvatarsListItem>
              );
            })}
          </AvatarsList>

          <MobileButton>
            <ProfileAvatarButton onClick={handleSaveSelectedAvatar}>
              <Button text={translate(KEYWORDS.Save)} />
            </ProfileAvatarButton>
          </MobileButton>
        </ModalBody>
      </Container>
    </Modal>
  );
};

export default AvatarsModal;

//  background = "#17ae43";
