import React, { FC } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import CloseIcon from "../Icons/ModalClose";

const Blacker = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: rgba(36, 30, 43, 0.72);
  z-index: 999;
`;

const Close = styled.div`
  position: absolute;
  top: 29px;
  right: 20px;
  user-select: none;
  z-index: 1;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background: #e65152;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 17px;
    width: 17px;

    g {
      opacity: 1;
    }
  }

  &:hover {
    svg  {
      height: 18px;
      width: 18px;

      g {
        opacity: 0.8;
      }
    }
  }

  @media (min-width: ${2800}px) {
    top: 30px;
    right: 30px;

    svg {
      height: 16px;
      width: 16px;
    }
  }
`;

const Container = styled(motion.div)`
  max-width: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const Modal: FC<{
  closeModal?: () => void;
  className?: string;
}> = ({ closeModal, className, children }) => {
  return (
    // <AnimatePresence>
    <Blacker onClick={closeModal} className="modal-open">
      <Container
        className={className}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 0.2 }}
      >
        {closeModal && (
          <Close onClick={closeModal}>
            <CloseIcon />
          </Close>
        )}
        {children}
      </Container>
    </Blacker>
    // </AnimatePresence>
  );
};

export default Modal;
