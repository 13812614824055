import styled from "styled-components";

export const StyledCurrentList = styled.div`
  font-feature-settings: "case" on;
`;

export const StyledScrollableTable = styled.div`
  height: calc(100vh - 252px);
  width: 100%;
  overflow: auto;
  margin-top: 12px;

  &::-webkit-scrollbar {
    width: 2.79px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: #3b3c41;

    &:hover {
      background-color: #3b3c41;
    }
  }

  @media (min-width: ${1366}px) {
    height: calc(100vh - 271px);
    margin-top: 25px;
  }

  @media (min-width: ${1920}px) {
    height: calc(100vh - 280px);
  }
`;
