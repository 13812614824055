import React, { FC } from "react";
import { IconProps } from "./types";

export const Minus: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 14 14"
      width="14"
      height="14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 6.99951H10.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Minus;
