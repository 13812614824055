export const variables = {
  colors: {
    redPink: "#C9374C",
    warmPink: "#ef4d65",
    darkOrange: "#cb3802",
    vermillion: "#e74e0f",
    vermillionTwo: "#e64d0e",
    dustyOrange: "#f87b2d",
    lightOrange: "#faaa41",
    lighterOrange: "#e6b40e",
    mustard: "#cbb402",
    uglyYellow: "#c3cb02",
    brightLimeGreen: "#73ff34",
    slimeGreen: "#95cb02",
    leafyGreen: "#58bc34",
    frogGreen: "#52b05a",
    frogGreen2: "#3bcd02",
    grassGreen: "#52b05a",
    turtleGreen: "#75b75d",
    lightNeonGreen: "rgba(114, 254, 52, 0)",
    brightSkyBlue: "#12c8ff",
    white: "#ffffff",
    whiteTransparent: "rgba(255,255,255, 0.08)",
    whiteText: "rgba(255,255,255, 0.08)",
    whiteGrayish: "#ffffffd6",
    gray: "#707070",
    charcoalGrey: "#413e45",
    charcoalGreyTwo: "#302c34",
    charcoalGreyTree: "#343038",
    charcoalGreyFour: "#383f49",
    weirdGray: "#363642",
    dark: "#241e2b",
    darkBlueGray: "#25214e",
    darkBlueGrayBackground: "rgba(37, 33, 78, 0.08)",
    transparent: "#ffffff0a",
    transparent2: "#ffffff1f",
    transparent3: "#ffffff21",
    transparent4: "#ffffffd5",
    transparent5: "#ffffff05",
    transparentModal: "#00000050",
    transparentButton: "#0201061f",
  },
  fonts: {
    mediatorNarrow: `Mediator Narrow`,
    mediatorNarrowCaps: `Mediator Narrow CAPS`,
    mediatorNarrowBook: `Mediator Narrow Book`,
  },
  breaks: {
    mobile: 700,
  },
};

export const texts = {
  gameTitle: "Droid",
};
